import { useCallback, useContext, useEffect, useState } from "react";

import { services } from "src/utils/api/base/api-instances";

import { RoleContext } from "src/providers";

import { Period, UserSettingsDto } from "src/utils/api/refunds/types";

export const useGetFormData = () => {
  const { isRoot } = useContext(RoleContext);
  const initialState: UserSettingsDto = {
    email: "",
    periodType: Period.NONE,
  };

  const [postDefaultValue, setPostDefaultValue] =
    useState<UserSettingsDto>(initialState);

  const getFormInitialData = useCallback(
    async function () {
      if (isRoot) {
        const { data } = await services
          .makeRootControllerRequest()
          .getSettings();

        const transformData = { ...data, periodType: Period[data.periodType!] };

        setPostDefaultValue(transformData);
      } else {
        const { data } = await services
          .makeAdminControllerRequest()
          .getSettings1();

        const transformData = { ...data, periodType: Period[data.periodType!] };

        setPostDefaultValue(transformData);
      }
    },
    [isRoot]
  );

  useEffect(() => {
    getFormInitialData();
  }, [getFormInitialData]);

  return [postDefaultValue, setPostDefaultValue];
};
