import { FC, ReactElement, createElement, MouseEvent, useContext } from "react";
import {
  Menu as LocalMenu,
  MenuItemLink,
  MenuProps,
  useResourceDefinitions,
  useSidebarState,
  useUnselectAll,
} from "react-admin";
import {
  SettingsOutlined,
  StorefrontOutlined,
  ViewList as DefaultIcon,
} from "@mui/icons-material";

import { RoleContext } from "src/providers/RoleProvider";
import { ResourceName } from "src/utils/api";

interface Props extends MenuProps {
  onMenuClick?: (e: MouseEvent) => void;
}

const Menu: FC<Props> = (props): ReactElement => {
  const { isRoot } = useContext(RoleContext);
  const [open] = useSidebarState();
  const unselect = useUnselectAll(ResourceName.BUCKET_ITEMS);
  const resources = useResourceDefinitions();

  const excludedMenuItems = [ResourceName.SHOPS, ResourceName.BUCKET_ITEMS];

  const resourcesNames = Object.keys(resources) as ResourceName[];

  const menuItemsName = resourcesNames.filter(
    (name) => !excludedMenuItems.includes(name)
  );

  const onRedirect = () => {
    unselect();
  };

  return (
    <LocalMenu {...props}>
      {isRoot && (
        <MenuItemLink
          to={`/shops`}
          primaryText={"custom.menu.shops_list"}
          onClick={props.onMenuClick}
          leftIcon={<StorefrontOutlined />}
          sidebarIsOpen={open}
        />
      )}

      {menuItemsName.map((key) => (
        <MenuItemLink
          key={key}
          to={`/${key}`}
          primaryText={resources[key]?.options?.label || key}
          leftIcon={createElement(resources[key]?.icon) || <DefaultIcon />}
          onClick={onRedirect}
          sidebarIsOpen={open}
        />
      ))}
      {isRoot && (
        <MenuItemLink
          to={`/settings`}
          primaryText="custom.menu.settings_scan_and_go"
          onClick={props.onMenuClick}
          leftIcon={<SettingsOutlined />}
          sidebarIsOpen={open}
        />
      )}
    </LocalMenu>
  );
};

export default Menu;
