import { useContext, useEffect, useState } from "react";
import { Datagrid, TextField, useRecordContext } from "react-admin";

import { RoleContext } from "src/providers";

import { CustomUrlField } from "src/components/shared";

import { getItemsByRefund } from "../../lib";

import { RefundItemsDtoTranslated } from "./types";

const RefundPanel = () => {
  const { isRoot } = useContext(RoleContext);
  const record = useRecordContext();

  const [refunds, setRefunds] = useState<RefundItemsDtoTranslated[]>([]);

  const element = (data: string) => (
    <img title="photo" src={data} width="50vmax" alt="pic" />
  );

  useEffect(() => {
    getItemsByRefund(record, setRefunds, isRoot);
  }, [isRoot, record]);

  return (
    <Datagrid bulkActionButtons={false} data={refunds}>
      <TextField source="productName" label="custom.labels.name_product" />
      <TextField source="unit" label="custom.labels.gauge" />
      <TextField source="productId" label="custom.labels.product_id" />
      <TextField source="weight" label="custom.labels.weight_w" />
      <TextField
        source="refundedAmountDiscounted"
        label="custom.labels.refund_amount"
      />
      <CustomUrlField
        label="custom.labels.photo"
        source="photoLink"
        element={element}
      />
    </Datagrid>
  );
};

export default RefundPanel;
