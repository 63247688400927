import * as XLSX from "xlsx";

export const exporter = <T>(data: T[]) => {
  const xlsData = XLSX.utils.json_to_sheet(data);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, xlsData, "xlsData");

  XLSX.writeFile(workbook, "xlsData.xlsx");
};
