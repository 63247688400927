import { GetListParams, GetOneParams, GetOneResult } from "react-admin";
import { GetListResult } from "ra-core";
import format from "date-fns/format";

import { services } from "../base/api-instances";

import { Refund } from "./types";

import { getListWithoutApi } from "../helpers/staticDataList";

import { RefundWithInvoiceDto } from "../../../api";

import { isCorrectDateRange } from "../../lib";

let list: Refund[] = [];

const transformDate = (data: string) => {
  const dateUTC = new Date(data);
  return format(dateUTC, "yyyy-MM-dd HH:mm");
};

export const cleanRefundsCash = () => {
  list = [];
};

export async function getRootList(
  params: GetListParams
): Promise<GetListResult<Refund>> {
  const { from, to, shopId } = params.filter;
  const fromTransformed = (from || "") && transformDate(from);
  const toTransformed = (to || "") && transformDate(to);

  if (!list.length || from || to) {
    const { data } = await services
      .makeRootControllerRequest()
      .getAllRefundsWithParameters({
        shopId,
        timeStart: fromTransformed,
        timeEnd: toTransformed,
      });

    const transformData = data.map((refund: RefundWithInvoiceDto) => ({
      ...refund,
      id: refund.refundId,
      refundDate: transformDate(refund.refundDate!),
    }));

    list = transformData as Refund[];
  }

  return {
    data: getListWithoutApi(list, params),
    total: list.length,
  };
}

export async function getAdminList(
  params: GetListParams
): Promise<GetListResult<Refund>> {
  const { from, to } = params.filter;
  const fromTransformed = (from || "") && transformDate(from);
  const toTransformed = (to || "") && transformDate(to);

  if (!list.length || isCorrectDateRange(from, to)) {
    const { data } = await services
      .makeAdminControllerRequest()
      .getAllRefundsWithParameters1({
        timeStart: fromTransformed,
        timeEnd: toTransformed,
      });

    const transformData = data.map((refund: RefundWithInvoiceDto) => ({
      ...refund,
      id: refund.refundId,
      refundDate: transformDate(refund.refundDate!),
    }));

    list = transformData as Refund[];
  }

  return {
    data: getListWithoutApi(list, params),
    total: list.length,
  };
}

export async function getRootOne(
  params: GetOneParams
): Promise<GetOneResult<Refund>> {
  const { data } = await services
    .makeRootControllerRequest()
    .getAllRefundsWithParameters({
      fiscalRefund: params.id,
    });

  return {
    data: data[0] as Refund,
  };
}
