import * as React from "react";
import { useRecordContext } from "react-admin";
import PaymentIcon from "@mui/icons-material/Payment";

const CardField = ({ source }: { source: string; label: string }) => {
  const record = useRecordContext();
  const data = record[source] && record[source].slice(-4);

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
      }}
    >
      <PaymentIcon color="action" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {record[source] && `**${data}`}
      </div>
    </div>
  );
};

export default CardField;
