import { GetListParams, GetOneParams, GetOneResult } from "react-admin";
import { GetListResult } from "ra-core";

import { services } from "../base/api-instances";

import { Bucket } from "./types";

import { getListWithoutApi } from "../helpers/staticDataList";

import { checkAdapter } from "../helpers/checkAdapter";
import { isCorrectDateRange, transformDate } from "../../lib";
import { BucketWithInvoiceDTO } from "./types";
import { BucketWithInvoiceDto } from "../../../api";

let list: BucketWithInvoiceDTO[] = [];

export const cleanBucketsCash = () => {
  list = [];
};

export async function getRootList(
  params: GetListParams
): Promise<GetListResult<BucketWithInvoiceDTO>> {
  const { from, to, shopId } = params.filter;

  const fromTransformed = from ? transformDate(from) : undefined;
  const toTransformed = to ? transformDate(to) : undefined;

  if (!list.length || isCorrectDateRange(from, to)) {
    try {
      const { data } = await services
        .makeRootControllerRequest()
        .getAllBucketsWithParameters({
          shopId,
          timeStart: fromTransformed,
          timeEnd: toTransformed,
        });

      const purchasesAdapter = (data: BucketWithInvoiceDto) => {
        return {
          ...data,
          id: data.bucketId!,
          completeTime: transformDate(data.completeTime!),
        };
      };

      list = data.map(
        (bucket): BucketWithInvoiceDTO => purchasesAdapter(bucket)
      );
    } catch (e) {
      return { data: [], total: 0 };
    }
  }

  return {
    data: getListWithoutApi(list, params),
    total: list.length,
  };
}

export async function getAdminList(
  params: GetListParams
): Promise<GetListResult<BucketWithInvoiceDTO>> {
  const { from, to } = params.filter;

  const fromTransformed = from ? transformDate(from) : undefined;
  const toTransformed = to ? transformDate(to) : undefined;

  if (!list.length || isCorrectDateRange(from, to)) {
    try {
      const { data } = await services
        .makeAdminControllerRequest()
        .getAllBucketsWithParameters1({
          timeStart: fromTransformed,
          timeEnd: toTransformed,
        });

      const purchasesAdapter = (data: BucketWithInvoiceDto) => {
        return {
          ...data,
          id: data.bucketId!,
          completeTime: transformDate(data.completeTime!),
        };
      };

      list = data.map(
        (bucket): BucketWithInvoiceDTO => purchasesAdapter(bucket)
      );
    } catch (e) {
      return { data: [], total: 0 };
    }
  }

  return {
    data: getListWithoutApi(list, params),
    total: list.length,
  };
}

export async function getRootOne(
  params: GetOneParams
): Promise<GetOneResult<Bucket>> {
  const { data } = await services
    .makeRootControllerRequest()
    .getBucketFullInformation({
      bucketId: params.id,
    });

  const transformData = checkAdapter(data);

  return {
    data: transformData as Bucket,
  };
}

export async function getAdminOne(
  params: GetOneParams
): Promise<GetOneResult<Bucket>> {
  const { data } = await services
    .makeAdminControllerRequest()
    .getBucketFullInformation1({
      bucketId: params.id,
    });

  const transformData = checkAdapter(data);

  return {
    data: transformData as Bucket,
  };
}
