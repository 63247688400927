import React, { createContext, ReactElement, useState } from "react";

export interface User {
  role: UserRole;
  username?: string;
}

export enum UserRole {
  ROOT = "root",
  ADMIN = "admin",
}

interface RoleProviderContext {
  user: User;
  setUser: (user: User) => void;
  isRoot: boolean;
  isAdmin: boolean;
}

const defaultUser: User = {
  role: UserRole.ADMIN,
  username: "Admin",
};

export const RoleContext = createContext<RoleProviderContext>({
  user: defaultUser,
  setUser: () => {},
  isRoot: true,
  isAdmin: false,
});

export default function RoleProvider({ children }: { children: ReactElement }) {
  const [user, setUser] = useState(defaultUser);

  const isRoot = user.role === UserRole.ROOT;
  const isAdmin = user.role === UserRole.ADMIN;

  return (
    <RoleContext.Provider value={{ user, setUser, isRoot, isAdmin }}>
      {children}
    </RoleContext.Provider>
  );
}
