import { ReactElement } from "react";
import {
  Datagrid,
  TextField,
  WithRecord,
  List,
  DateField,
  useTranslate,
} from "react-admin";

import { Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { BucketFullDto, RefundFullDto } from "src/api";
import { transformDate } from "src/utils/lib";
import { CustomSimpleList } from "src/components/shared";

const Logs = (): ReactElement | null => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const translate = useTranslate();

  return (
    <WithRecord
      render={(record) => {
        const { refunds } = record as BucketFullDto;

        return !!refunds?.length! ? (
          !isSmall ? (
            <List>
              <Datagrid data={refunds} bulkActionButtons={false}>
                <TextField
                  source="fiscalRefund"
                  label="root.columns.return_receipt_number"
                />
                <TextField source="refund.status" label="root.columns.status" />
                <TextField
                  source="refund.employeeLogin"
                  label="root.columns.login_operator"
                />
                <DateField
                  source="refund.createTime"
                  label="root.columns.create_time"
                />
                <TextField
                  source="refund.comment"
                  label="custom.labels.comment"
                />
              </Datagrid>
            </List>
          ) : (
            <CustomSimpleList
              externalData={refunds}
              secondaryText={(record) => {
                const { fiscalRefund, refund } = record as RefundFullDto;

                return (
                  <>
                    <ul style={{ listStyle: "none" }}>
                      <Typography>
                        <li>{`${translate(
                          "root.columns.return_receipt_number"
                        )}: ${fiscalRefund}`}</li>
                        <li>{`${translate("root.columns.status")}: ${
                          refund?.status
                        }`}</li>
                        <li>{`${translate("root.columns.login_operator")}: ${
                          refund?.employeeLogin
                        }`}</li>
                        <li>{`${translate(
                          "root.columns.create_time"
                        )}: ${transformDate(refund?.createTime!)}`}</li>
                        <li>{`${translate("custom.labels.comment")}: ${
                          refund?.comment
                        }`}</li>
                      </Typography>
                    </ul>
                    <hr />
                  </>
                );
              }}
            />
          )
        ) : (
          <Typography>{translate("root.columns.empty_list")}</Typography>
        );
      }}
    />
  );
};

export default Logs;
