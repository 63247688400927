import {
  Datagrid,
  NumberField,
  TextField,
  useListContext,
  useTranslate,
  WithRecord,
} from "react-admin";
import React, { useCallback, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { BucketItem } from "src/utils/api/bucket-items";
import { formatMoney } from "src/utils/lib";

import { CustomSimpleList } from "src/components/shared";

import { getCurrentRefundSum } from "./../../lib";
import { ISetStateHandlers } from "./types";

import {
  Actions,
  ChooseAllCheckbox,
  ChooseItemCheckbox,
  PhotoField,
  WeightInputField,
} from "./components";

const BucketItemsDataGrid = ({
  setSum,
  setRefundSum,
  currentRefundSum,
  setCurrentRefundSum,
  comment,
  handleOpen,
  setPartialRefundsWeight,
  partialRefundsWeight,
  setSelectedIds,
  photoData,
  setPhotoData,
}: ISetStateHandlers) => {
  const theme = useTheme();
  const translate = useTranslate();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, selectedIds } = useListContext<BucketItem>();
  const [isRefundButtonDisable, setIsRefundButtonDisable] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const [isAllCheckboxChecked, setIsAllCheckboxChecked] = useState(false);
  const getCurrRefundSum = useCallback(getCurrentRefundSum, [
    selectedIds,
    data,
  ]);
  const [isErrorRefund, setIsErrorRefund] = useState(false);

  const postRowStyle = (record: BucketItem) => {
    const hasInIds = !!selectedIds.find((id) => id === record.id);

    return {
      opacity: record.isRefunded && !hasInIds ? "0.2" : "1",
    };
  };

  useEffect(() => {
    setSelectedIds(selectedIds);
    const currentRefundSum = isSmall
      ? getCurrRefundSum(data, ids, partialRefundsWeight)
      : getCurrRefundSum(data, selectedIds, partialRefundsWeight);

    setCurrentRefundSum(currentRefundSum);
  }, [
    selectedIds,
    setCurrentRefundSum,
    data,
    getCurrRefundSum,
    partialRefundsWeight,
    ids,
    isSmall,
    setSelectedIds,
    isErrorRefund,
  ]);

  useEffect(() => {
    if (data) {
      setSum(formatMoney(data[0].checkSum));
      data[0].refundSum && setRefundSum(`${data[0].refundSum}`);
    }
  }, [setSum, setRefundSum, data]);



  useEffect(() => {
    if ((!!ids.length || !!selectedIds.length) && !isErrorRefund) {
      setIsRefundButtonDisable(false);
    } else {
      setIsRefundButtonDisable(true);
    }
  }, [ids, isErrorRefund, selectedIds, setCurrentRefundSum]);

  return (
    <>
      {isSmall ? (
        <>
          <div>
            <Actions
              ids={ids}
              data={data}
              comment={comment}
              isRefundButtonDisable={isRefundButtonDisable}
              setPartialRefundsWeight={setPartialRefundsWeight}
              currentRefundSum={currentRefundSum}
              partialRefundsWeight={partialRefundsWeight}
              photoData={photoData}
            />
            <Typography>
              <ChooseAllCheckbox
                data={data}
                setIds={setIds}
                isAllCheckboxChecked={isAllCheckboxChecked}
                setIsAllCheckboxChecked={setIsAllCheckboxChecked}
              />
            </Typography>
          </div>
          <CustomSimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => (
              <ul style={{ listStyle: "none" }}>
                <Typography>
                  <li key={nanoid()}>{`${translate("root.columns.code1c")}: ${
                    record.productId
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.gauge")}: ${
                    record.unit
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.quantity")}: ${
                    record.quantity
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.price")}: ${
                    record.price
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.amount")}: ${
                    record.sum
                  }`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.sum_with_sale"
                  )}: ${record.sumDiscounted}`}</li>
                  <li key={nanoid()}>
                    <label htmlFor="photoId"> Фото: </label>
                    <PhotoField
                      record={record}
                      label="custom.labels.photo"
                      handleOpen={handleOpen}
                      photoData={photoData}
                      setPhotoData={setPhotoData}
                    />
                  </li>
                </Typography>
              </ul>
            )}
            checkbox={(record: Record<string, any>) => (
              <>
                <ChooseItemCheckbox
                  setIds={setIds}
                  id={record.id}
                  ids={ids}
                  isAllCheckboxChecked={isAllCheckboxChecked}
                  setIsAllCheckboxChecked={setIsAllCheckboxChecked}
                />
                <WeightInputField
                  setWeight={setPartialRefundsWeight}
                  ids={ids}
                  record={record}
                  weights={partialRefundsWeight}
                  setIsErrorRefund={setIsErrorRefund}
                  isErrorRefund={isErrorRefund}
                  setIsRefundButtonDisable={setIsRefundButtonDisable}
                />
              </>
            )}
          />
        </>
      ) : (
        <Datagrid
          bulkActionButtons={
            <Actions
              ids={selectedIds}
              data={data}
              comment={comment}
              partialRefundsWeight={partialRefundsWeight}
              currentRefundSum={currentRefundSum}
              setPartialRefundsWeight={setPartialRefundsWeight}
              isRefundButtonDisable={isRefundButtonDisable}
              photoData={photoData}
            />
          }
          rowStyle={postRowStyle}
        >
          <TextField source="productId" label="root.columns.code1c" />
          <TextField source="name" label="root.columns.name" />
          <TextField source="unit" label="root.columns.unit_of_measurement" />
          <NumberField source="quantity" label="root.columns.quantity" />
          <WithRecord
            label="root.columns.return_kg"
            render={(record: BucketItem) => (
              <WeightInputField
                setWeight={setPartialRefundsWeight}
                ids={selectedIds}
                record={record}
                weights={partialRefundsWeight}
                setIsErrorRefund={setIsErrorRefund}
                isErrorRefund={isErrorRefund}
                setIsRefundButtonDisable={setIsRefundButtonDisable}
              />
            )}
          />
          <NumberField source="price" label="root.columns.price_sum" />
          <NumberField source="sum" label="root.columns.amount" />
          <NumberField
            source="sumDiscounted"
            label="root.columns.sum_with_sale"
          />
          <PhotoField
            label="custom.labels.photo"
            handleOpen={handleOpen}
            photoData={photoData}
            setPhotoData={setPhotoData}
          />
        </Datagrid>
      )}
    </>
  );
};

export default BucketItemsDataGrid;
