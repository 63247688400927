import { services } from "src/utils/api/base/api-instances";

export const getBucketIdByFiscalOrder = (
  isRoot: boolean,
  fiscalNumber: string
) => {
  return isRoot
    ? services
        .makeRootControllerRequest()
        .getBucketFullInformationByFiscalNumber({
          fiscalNumber,
        })
        .then(({ data }) => data.bucket?.id)
    : services
        .makeAdminControllerRequest()
        .getBucketFullInformationByFiscalNumber1({
          fiscalNumber,
        })
        .then(({ data }) => data.bucket?.id);
};
