import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  useRecordContext,
  useRedirect,
  Confirm,
  useTranslate,
} from "react-admin";
import { Button } from "@mui/material";
import React, { useState } from "react";

const PostEditToolbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(true);
  const handleDialogClose = () => setIsOpen(false);

  const redirect = useRedirect();

  const onCancel = () => {
    redirect("/shops");
  };

  const handleConfirm = () => {
    onCancel();
    setIsOpen(false);
  };

  return (
    <Toolbar>
      <SaveButton />
      <Button
        onClick={handleClick}
        size="medium"
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "10px" }}
      >
        <>Отмена</>
      </Button>
      <Confirm
        isOpen={isOpen}
        title="custom.modal.title.exit_without_confirm"
        content="custom.modal.confirm.input_data_dont_save"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="ra.boolean.true"
        cancel="ra.boolean.false"
      />
    </Toolbar>
  );
};

const EditShopTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;

  return (
    <span>
      {translate("custom.labels.edit_shop")} №{record.id}{" "}
    </span>
  );
};

const ShopEdit = () => {
  return (
    <Edit title={<EditShopTitle />}>
      <SimpleForm toolbar={<PostEditToolbar />}>
        <TextInput source="address" label="root.columns.show_name" />
        <TextInput source="merchant" label="root.columns.merchant" />
        <TextInput
          source="cashBoxNumber"
          label="root.columns.checkout_number"
        />
        <BooleanInput source="enabled" label="root.columns.on_sg" />
        <BooleanInput source="checkerEnabled" label="root.columns.off_pch" />
      </SimpleForm>
    </Edit>
  );
};

export default ShopEdit;
