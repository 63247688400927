import { SortPayload } from "ra-core";

import { OrderDirection, sortArrayOfObjects } from "./sortArrayOfObjects";

export function sortDataList<T>(list: T[], sort?: SortPayload): T[] {
  if (!sort) {
    return list;
  }

  const field = sort.field as keyof T;
  const order = sort.order as OrderDirection;

  return sortArrayOfObjects<T>(list, field, order);
}
