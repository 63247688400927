import { GetListParams } from "react-admin";
import { sortDataList } from "./sortDataList";
import { paginateDataList } from "./paginateDataList";
import { filterDataList } from "./filterDataList";

export function getListWithoutApi<T>(list: T[], params: GetListParams): T[] {
  let newList = [...list];
  newList = Array.from(new Set(filterDataList(newList, params.filter)));
  newList = sortDataList<T>(newList, params.sort);
  newList = paginateDataList<T>(newList, params.pagination);

  return newList;
}
