import { transformDate } from "./transformDate";

export const transformData = <T>(
  data: T[],
  namesMap: Record<string, string>
) => {
  return data.map((el: T) => {
    return Object.entries(namesMap).reduce((acc, [key, value]) => {
      if (value === "Время создания") {
        return {
          ...acc,
          [value]: transformDate(String(el[key as keyof T] || '')),
        };
      }

      return {
        ...acc,
        [value]: el[key as keyof T],
      };
    }, {});
  });
};
