import { useRecordContext, useTranslate } from "react-admin";

const FinalAmountPaidField = ({ label }: { label: string }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (record.status === "WAIT") {
    return <span>{translate("custom.labels.returns_are_processed")}</span>;
  }

  return <span>{record.finalAmountPaid}</span>;
};

export default FinalAmountPaidField;
