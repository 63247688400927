import { useGetOne, useTranslate, useUpdate } from "react-admin";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Checkbox, Button } from "@mui/material";

import { Shop } from "src/utils/api/shops/types";

import { FieldData, Params } from "./types";

const ScanGoAdaptiveField = ({
  label,
  record,
}: FieldData): ReactElement | null => {
  const [update] = useUpdate();
  const translate = useTranslate();
  const { data } = useGetOne("shops", { id: record.id });

  const {
    id,
    address,
    name,
    enabled,
    merchant,
    cashBoxNumber,
    checkerEnabled,
  } = data as Shop;

  const [check, setCheck] = useState(enabled);
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  const queryParams: Params = {
    shopId: Number(id),
    address,
    name: name!,
    enabled: check,
    merchant,
    cashBoxNumber,
    checkerEnabled,
  };

  const onScanGoHandler = () => {
    record.id &&
      update("shops", {
        id: record.id,
        data: queryParams,
        previousData: {},
      }).then(() => {
        setIsButtonDisable(true);
      });
  };

  const onHandlerCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(() => {
      setIsButtonDisable(!isButtonDisable);
      return e.target.checked;
    });
  };

  useEffect(() => {
    setCheck(enabled);
  }, [enabled]);

  if (record.id) {
    return (
      <span>
        <label htmlFor="sg">{label}</label>
        <Checkbox id="sg" onChange={onHandlerCheck} checked={check} />
        &nbsp;
        <Button
          onClick={onScanGoHandler}
          disabled={isButtonDisable}
          variant="text"
          size="large"
        >
          {translate("ra.action.save")}
        </Button>
      </span>
    );
  }

  return null;
};

export default ScanGoAdaptiveField;
