import React, { useState } from "react";
import { useTranslate } from "react-admin";

import { Box, Modal } from "@mui/material";

const CustomUrlFieldAdaptive = ({
  source,
  element,
}: {
  source: string;
  element: (data: string) => JSX.Element;
}) => {
  const translate = useTranslate();

  const [isFrameOn, setIsFrameOn] = useState(false);

  const frameOpen = () => {
    setIsFrameOn(true);
  };

  const frameClose = () => setIsFrameOn(false);

  return (
    <>
      <span
        onClick={() => {
          frameOpen();
        }}
        style={{ color: "#43a6f5", padding: "5px" }}
      >
        {source && translate("custom.buttons.show")}
      </span>
      <Modal
        open={isFrameOn}
        onClose={frameClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Box>{element(source)}</Box>
      </Modal>
    </>
  );
};

export default CustomUrlFieldAdaptive;
