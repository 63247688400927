import { DataProvider } from "react-admin";

import { Api, ResourceName } from "src/utils/api";
import { useContext } from "react";
import { RoleContext, UserRole } from "./RoleProvider";

const record = [
  {
    id: 1,
    title: "",
  },
];

export interface methodsType {
  [name: string]: {
    [name: string]: any;
  };
}

const methods: methodsType = {
  [UserRole.ROOT]: {
    [ResourceName.SHOPS]: {
      getList: Api.Shops.getRootList,
      getOne: Api.Shops.getRootOne,
      update: Api.Shops.update,
    },
    [ResourceName.PURCHASES]: {
      getList: Api.Purchases.getRootList,
      getOne: Api.Purchases.getRootOne,
    },
    [ResourceName.REFUNDS]: {
      getList: Api.Refunds.getRootList,
    },
  },
  [UserRole.ADMIN]: {
    [ResourceName.SHOPS]: {
      getOne: Api.Shops.getAdminOne,
    },
    [ResourceName.PURCHASES]: {
      getList: Api.Purchases.getAdminList,
      getOne: Api.Purchases.getAdminOne,
    },
    [ResourceName.REFUNDS]: {
      getList: Api.Refunds.getAdminList,
    },
    [ResourceName.BUCKET_ITEMS]: {
      getList: Api.BucketItems.getAdminList,
      create: Api.BucketItems.create,
    },
  },
};

export const useApiProvider: () => DataProvider = () => {
  const {
    user: { role },
  } = useContext(RoleContext);

  return {
    getList: (resource: ResourceName, params) => {
      return methods[role][resource].getList(params);
    },

    getOne: (resource: ResourceName, params) => {
      return methods[role][resource].getOne(params);
    },

    getMany: (resource: ResourceName, params) => {
      return Promise.resolve({ data: record });
    },

    getManyReference: (resource: ResourceName, params) => {
      return Promise.resolve({ data: record, total: 4 });
    },

    update: (resource: ResourceName, params) => {
      return methods[role][resource].update(params);
    },

    updateMany: (resource: ResourceName) => {
      return Promise.resolve({ data: [123, 124, 125] });
    },

    create: (resource: ResourceName, params) => {
      return methods[role][resource].create(params);
    },

    delete: (resource: ResourceName, params) => {
      return Promise.resolve({ data: { id: 1, title: "" } });
    },

    deleteMany: (resource: ResourceName, params) => {
      return Promise.resolve({ data: [123, 124, 125] });
    },
  } as DataProvider;
};
