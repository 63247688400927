import * as React from "react";
import { DateTimeInput, SearchInput, TextInput } from "react-admin";

export const postFilters = [
  <TextInput
    name="shopId"
    type="number"
    label="root.columns.shop_number"
    source="shopId"
  />,
  <TextInput
    name="id"
    type="number"
    label="root.columns.number_check"
    source="id"
  />,
  <TextInput
    name="amountPaid"
    type="number"
    label="root.columns.amount"
    source="amountPaid"
  />,
  <TextInput
    name="status"
    type="string"
    label="root.columns.status"
    source="status"
  />,
  <DateTimeInput name="from" label="root.columns.from" source="1" />,
  <DateTimeInput name="to" label="root.columns.to" source="2" />,
  <SearchInput name="q" source="q" alwaysOn />,
];

export const namesMap = {
  fiscalRefund: "root.columns.return_receipt_number",
  referenceNumber: "root.columns.bank_transaction",
  cardNumber: "root.columns.card_number",
  refundDate: "root.columns.create_time",
  merchant: "root.columns.merchant",
  shopAddress: "root.columns.address_shop",
  fiscalOrder: "root.columns.sales_receipt",
  amountPaid: "root.columns.sum_receipt",
  amountRefunded: "custom.labels.refund_amount",
  finalAmountPaid: "custom.labels.amount_after_return",
  comment: "custom.labels.comment",
  status: "root.columns.status",
};
