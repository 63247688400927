import * as React from "react";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@material-ui/core";

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ShowButton,
  useNotify,
  useRefresh,
  useRedirect,
  useGetList,
  useTranslate,
} from "react-admin";

import { ResourceName } from "src/utils/api";
import { exporter, transformData } from "src/utils/lib";
import { RoleContext } from "src/providers";

import { CardField, CustomSimpleList, Empty } from "src/components/shared";

import { getBucketIdByFiscalOrder } from "./lib/gitBucketIdByFiscalOrder";

import { PurchasesScanner } from "./components";

import { postFilters } from "./constants";

import "./styles/index.scss";

const PurchasesPage: FC = (props): ReactElement => {
  const { isRoot } = useContext(RoleContext);
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const namesMap  = {
    fiscalOrder: translate("root.columns.number_check"),
    amountPaid: translate("root.columns.sum_with_sale"),
    provisionalAmount: translate("root.columns.sum_without_sale"),
    cardNumber: translate("root.columns.card_number"),
    shopId: translate("root.columns.shop_number"),
    shopAddress: translate("root.columns.address"),
    completeTime: translate("root.columns.create_time"),
    status: translate("root.columns.status"),
    payMethod: translate("root.columns.payment_method")
  };


  const [isScanOn, setIsSCanOn] = useState(false);
  const [scanData, setScanData] = useState<string>(
    translate("root.columns.no_result")
  );
  const [screenOrientation, setScreenOrientation] = useState(0);
  const [isEmptyPage, setIsEmptyPage] = useState(false);

  const handleOpen = () => setIsSCanOn(true);

  const handleClose = () => setIsSCanOn(false);

  const data = useGetList(ResourceName.PURCHASES);

  useEffect(() => {
    if (!scanData) {
      notify("custom.notify.no_valid_qr", { type: "error" });

      return;
    }

    if (scanData !== "root.columns.no_result") {
      const fiscalOrder = scanData
        .split("&")
        .find((el) => el.includes("i="))
        ?.slice(2);

      if (fiscalOrder) {
        getBucketIdByFiscalOrder(isRoot, fiscalOrder)
          .then((data: number | undefined) => {
            redirect(`/purchases/${data}/show`);
          })
          .catch(() => {
            redirect(`/purchases`);
            notify("custom.notify.check_not_found", { type: "error" });
          });
      }
    }
  }, [scanData, redirect, isRoot, notify, refresh]);

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setScreenOrientation(window.screen.orientation.angle);
    });

    return () =>
      window.removeEventListener("orientationchange", () => {
        setScreenOrientation(window.screen.orientation.angle);
      });
  }, []);

  return (
    <>
      {!isEmptyPage && !!data?.data?.length && (
        <div>
          <Button onClick={handleOpen}>
            <p>{translate("root.columns.scan_check")}</p>
          </Button>
          <Modal
            open={isScanOn}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
                flexDirection: !screenOrientation ? "column" : "row",
              }}
            >
              <PurchasesScanner
                setData={setScanData}
                setIsScanOn={setIsSCanOn}
              />
            </Box>
          </Modal>
        </div>
      )}
      <List
        {...props}
        sx={{ minHeight: "100%" }}
        sort={{ field: "completeTime", order: "DESC" }}
        filters={!!data?.data?.length ? postFilters : undefined}
        exporter={(data) => exporter(transformData(data, namesMap))}
        empty={<Empty setIsEmptyPage={setIsEmptyPage} />}
      >
        {isSmall ? (
          <CustomSimpleList
            primaryText={(record) => record.shopAddress}
            secondaryText={(record) => (
              <ul style={{ listStyle: "none" }}>
                <Typography>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.number_check"
                  )}: ${record.fiscalOrder || ""}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.sum_with_sale"
                  )}: ${record.amountPaid}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.sum_without_sale"
                  )}: ${record.provisionalAmount}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.card_number"
                  )}: ${
                    (record.cardNumber && `**${record.cardNumber.slice(-4)}`) ||
                    ""
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.shop_n")}: ${
                    record.shopId
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.status")}: ${
                    record.status
                  }`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.payment_method"
                  )}: ${record.payMethod}`}</li>
                </Typography>
              </ul>
            )}
            tertiaryText={(record) => record.completeTime}
            showButton={(record) => (
              <Button
                variant="text"
                href={`/purchases/${record.id}/show`}
                size="large"
              >
                {translate("root.columns.show_check")}
              </Button>
            )}
          />
        ) : (
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="fiscalOrder" label="root.columns.number_check" />
            <NumberField
              source="amountPaid"
              label="root.columns.sum_with_sale"
            />
            <NumberField
              source="provisionalAmount"
              label="root.columns.sum_without_sale"
            />
            <CardField source="cardNumber" label="root.columns.card_number" />
            <TextField source="shopId" label="root.columns.shop_number" />
            <TextField source="shopAddress" label="root.columns.address" />
            <TextField source="completeTime" label="root.columns.create_time" />
            <TextField source="status" label="root.columns.status" />
            <TextField source="payMethod" label="root.columns.payment_method" />
            <ShowButton label="root.columns.show_check" />
          </Datagrid>
        )}
      </List>
    </>
  );
};

export default PurchasesPage;
