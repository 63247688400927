import { useRedirect, useUnselectAll } from "react-admin";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@mui/material/IconButton";

import { ResourceName } from "src/utils/api";

const ReturnButton = ({
  path,
  selectedIds,
}: {
  path: string;
  selectedIds?: number[];
}) => {
  const unselect = useUnselectAll(ResourceName.BUCKET_ITEMS);
  const redirect = useRedirect();

  const onRedirect = () => {
    unselect();
    redirect(path);
  };

  return (
    <div style={{ padding: "10px" }}>
      <IconButton onClick={onRedirect} size="medium" edge="start">
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
};

export default ReturnButton;
