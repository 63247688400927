import { Identifier } from "ra-core";
import {
  dinero,
  add,
  multiply,
  toFormat,
  transformScale,
  Dinero,
} from "dinero.js";
import { RUB } from "@dinero.js/currencies";

import { ItemForRefundDto } from "src/api";
import { BucketItem } from "src/utils/api/bucket-items";

const transformer = ({ amount }: { amount: number }) => `${amount} ₽`;

const rub = (value: number) =>
  dinero({
    currency: RUB,
    amount: Math.round(value * 1000),
    scale: 3,
  });

export const getCurrentRefundSum = (
  bucketItems?: BucketItem[],
  selectedIds?: Identifier[],
  itemsForRefund?: ItemForRefundDto[]
) => {
  if (!selectedIds) {
    return toFormat(rub(0), transformer);
  }

  const res = selectedIds.reduce((acc: Dinero<number>, id) => {
    const bucketItem: BucketItem | undefined = bucketItems?.find(
      (item) => item.id === id
    );

    if (!bucketItem) {
      return acc;
    }

    const itemForRefund = itemsForRefund?.find((item) => item.itemId === id);

    const isWeighted = bucketItem.unit === "кг";

    if (isWeighted) {
      if (!itemForRefund) {
        return add(acc, rub(bucketItem.sumDiscounted));
      }

      const pricePerKilogram = dinero({
        currency: RUB,
        amount: Math.round(
          (bucketItem.sumDiscounted! / bucketItem.quantity!) * 100
        ),
        scale: 2,
      });

      const sum = transformScale(
        multiply(pricePerKilogram, {
          amount: itemForRefund.weight! * 100,
          scale: 2,
        }),
        2
      );

      return add(acc, sum);
    }

    return add(acc, rub(bucketItem.sumDiscounted));
  }, rub(0));

  return toFormat(res, transformer);
};
