import * as React from "react";
import { useListContext } from "react-admin";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { nanoid } from "nanoid";

import CustomListItem from "../CustomListItem";

interface IData {
  primaryText?: (record: Record<string, any>) => string;
  secondaryText?: (record: Record<string, any>) => ReactJSXElement;
  tertiaryText?: (record: Record<string, any>) => string;
  checkbox?: (record: Record<string, any>) => ReactJSXElement;
  showButton?: (record: Record<string, any>) => ReactJSXElement;
  expand?: (record: Record<string, any>) => ReactJSXElement;
  externalData?: Record<string, any>[];
}

const CustomSimpleList = ({
  primaryText,
  secondaryText,
  tertiaryText,
  checkbox,
  showButton,
  expand,
  externalData,
}: IData) => {
  const { data } = useListContext();

  const dataList = externalData ? externalData : data;

  const view =
    dataList &&
    dataList.map((item) => {
      return (
        <CustomListItem
          key={nanoid()}
          item={item}
          primaryText={primaryText}
          secondaryText={secondaryText}
          tertiaryText={tertiaryText}
          checkbox={checkbox}
          showButton={showButton}
          expand={expand}
        />
      );
    });

  return <>{view}</>;
};
export default CustomSimpleList;
