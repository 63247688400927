import { GetListParams, GetOneParams, GetOneResult } from "react-admin";
import { GetListResult, UpdateParams } from "ra-core";

import { services } from "../base/api-instances";

import { Shop } from "./types";

import { getListWithoutApi } from "../helpers/staticDataList";

export let list: Shop[] = [];

export const cleanShopsCash = () => {
  list = [];
};

export async function getRootList(
  params: GetListParams
): Promise<GetListResult<Shop>> {
  if (!list.length) {
    try {
      const { data } = await services.makeRootControllerRequest().getAllShops();
      list = data as Shop[];
    } catch (e) {}
  }

  return {
    data: getListWithoutApi(list, params),
    total: list.length,
  };
}

export async function getRootOne(
  params: GetOneParams
): Promise<GetOneResult<Shop>> {
  const { data } = await services.makeRootControllerRequest().getShopById({
    shopId: params.id,
  });

  const withLogin = { ...data.shop, adminLogin: data.adminLogin };

  return {
    data: withLogin as Shop,
  };
}

export async function getAdminOne(): Promise<GetOneResult<Shop>> {
  const { data } = await services
    .makeAdminControllerRequest()
    .getShopByCurrentAdmin();

  return {
    data: data as Shop,
  };
}

export async function update(
  params: UpdateParams
): Promise<GetOneResult<Shop>> {
  const {
    id,
    address,
    name,
    merchant,
    cashBoxNumber,
    enabled,
    shopId,
    checkerEnabled,
  } = params.data;
  const paramsAdapted = {
    shopId: id || shopId,
    name,
    address,
    merchant,
    cashBoxNumber,
    enabled,
    checkerEnabled,
  };

  await services.makeRootControllerRequest().updateShop(paramsAdapted);

  return {
    data: { id: id || shopId },
  };
}
