import * as React from "react";
import {SearchInput, TextInput} from "react-admin";

export const postFilters = [
    <TextInput
        name="name"
        label="custom.filters.name"
        source="name"
        defaultValue=""
    />,
    <TextInput
        name="id"
        type="number"
        label="custom.filters.number_shop"
        source="id"
        defaultValue=""
    />,
    <SearchInput name="q" source="q" alwaysOn />,
];

export const namesMap = {
    id: "custom.filters.number_shop",
    name: "custom.filters.name",
    city: "custom.filters.city",
};
