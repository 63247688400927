import { BucketFullDto } from "src/api";
import { transformDate } from "../../lib";

export const checkAdapter = (data: BucketFullDto) => {
  const { bucket, itemDtos, shopAddress, fiscalOrder, refunds, fiscalUrl } =
    data;

  const transformItemDtos = itemDtos?.map((product) => {
    return {
      ...product,
      unit: product.unit === "PIECE" ? "шт" : "кг",
      item: {
        ...product.item,
        weight: product.item?.weight || 1,
      },
    };
  });

  return {
    id: bucket?.id,
    date: transformDate(bucket?.completeTime!),
    payMethod: bucket?.payMethod,
    fullSum: bucket?.amountPaid,
    sumWithoutDiscount: bucket?.provisionalAmount,
    items: transformItemDtos,
    bonusesDeducted: bucket?.bonusesDeducted,
    bonusesAccrued: bucket?.bonusesAccrued,
    authCode: bucket?.orderId,
    shopAddress,
    fiscalOrder,
    cardNumber: bucket?.cardNumber,
    friendCardId: bucket?.friendCardId,
    refunds,
    fiscalUrl,
  };
};
