import React, { useState } from "react";
import { Confirm, useNotify, useTranslate } from "react-admin";
import { Button } from "@mui/material";

import { ItemForRefundDto } from "src/api";

import { BucketItem } from "src/utils/api/bucket-items";
import { services } from "src/utils/api/base/api-instances";

import { IActionData } from "./types";

const Actions = ({
  ids,
  data,
  comment,
  isRefundButtonDisable,
  partialRefundsWeight,
  setPartialRefundsWeight,
  currentRefundSum,
  photoData,
}: IActionData) => {
  const translate = useTranslate();
  const notify = useNotify();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(true);

  const handleDialogClose = () => setIsOpen(false);

  const handleConfirm = () => {
    onRefund(ids, data);
    setIsOpen(false);
  };

  const onRefund = async (ids: number[], data: BucketItem[]) => {
    const requestParams: ItemForRefundDto[] = ids?.map((id) => {
      const foundPartialRefundItem = partialRefundsWeight.find(
        (item) => item.itemId === id
      );

      if (foundPartialRefundItem) {
        return foundPartialRefundItem;
      }

      const bucketItem = data.find((item) => item.id === id);

      const photoLink = photoData.find((item) => item.itemId === id);

      const partialRefundItem = {
        itemId: id,
        weight: bucketItem?.unit === "кг" ? bucketItem?.quantity : undefined,
        photoLink: photoLink?.url || "",
      };

      return partialRefundItem;
    });

    const requestParameters = {
      bucketId: data[0].bucketId,
      itemForRefundDto: requestParams,
      comment: comment,
    };

    try {
      await services
        .makeAdminControllerRequest()
        .createRefund(requestParameters);
      setPartialRefundsWeight([]);
      notify("custom.notify.return_successfully_processed", {
        type: "success",
      });
    } catch (e: any) {
      setPartialRefundsWeight([]);
      notify(e.response?.data["odata.error"]?.message?.value, {
        type: "error",
      });
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={handleClick}
        disabled={isRefundButtonDisable}
      >
        <>{`${translate("root.columns.issue_refund")} ${currentRefundSum}`}</>
      </Button>
      <Confirm
        isOpen={isOpen}
        title="custom.modal.title.confirm_return"
        content="custom.modal.confirm.you_want_return"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="ra.boolean.true"
        cancel="ra.boolean.false"
      />
    </>
  );
};

export default Actions;
