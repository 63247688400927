export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export function sortArrayOfObjects<T>(
  arr: T[],
  field: keyof T,
  direction: OrderDirection = OrderDirection.ASC
): T[] {
  const newArr = [...arr];

  newArr.sort((lh, rh) => {
    let lhValue = lh[field];
    let rhValue = rh[field];

    if (direction === "DESC") {
      [lhValue, rhValue] = [rhValue, lhValue];
    }

    if (typeof lhValue === "number" && typeof rhValue === "number") {
      return lhValue - rhValue;
    }

    if (typeof lhValue === "string" && typeof rhValue === "string") {
      return lhValue.localeCompare(rhValue);
    }

    if (typeof lhValue === "boolean" && typeof rhValue === "boolean") {
      return Number(lhValue) - Number(rhValue);
    }

    return 0;
  });

  return newArr;
}
