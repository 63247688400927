import { AuthProvider } from "react-admin";
import { useContext } from "react";

import { RoleContext, UserRole } from "src/providers/RoleProvider";

import { services } from "src/utils/api/base/api-instances";

import { logout } from "../utils/api/auth";

export const useAuthProvider: () => AuthProvider = () => {
  const { setUser } = useContext(RoleContext);

  return {
    login: async ({ username, password }) => {
      const response = await services
        .makeAuthControllerRequest()
        .doAdminPanelLogin({
          username,
          password,
        });

      if (response.status < 200 || response.status >= 300) {
        return Promise.reject();
      }

      localStorage.setItem("auth", "true");
      localStorage.setItem("username", username);

      if (username === process.env.REACT_APP_ROOT_LOGIN) {
        localStorage.setItem("role", UserRole.ROOT);

        setUser({ role: UserRole.ROOT });
      } else {
        localStorage.setItem("role", UserRole.ADMIN);

        setUser({ role: UserRole.ADMIN });
      }

      return Promise.resolve();
    },

    logout: async () => {
      return logout();
    },

    // when the dataProvider returns an error, check if this is an authentication error
    checkError: (error: any) => Promise.resolve(),

    // when the user navigates, make sure that their credentials are still valid
    checkAuth: () =>
      localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),

    // get the user's profile
    getIdentity: () => {
      const name = localStorage.getItem("username") || "Vasya";
      const role = localStorage.getItem("role");

      return Promise.resolve({ id: 1, fullName: `${role} ${name}` });
    },

    // get the user permissions (optional)
    getPermissions: (params: any) => Promise.resolve(),
  };
};
