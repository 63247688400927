import { FilterPayload } from "react-admin";

export const filterDataList = <T>(list: T[], filter: FilterPayload): T[] => {
  const filtersKeys = Object.keys(filter);

  if (filtersKeys.length === 0) {
    return list;
  }

  return filtersKeys.reduce<T[]>((acc: T[], key: string | number): T[] => {
    if (key === "q") {
      const res = list.filter((el) =>
        Object.values(el).find((item) => {
          if (typeof item === "string") {
            return item.toLowerCase().includes(filter.q.toLowerCase());
          }

          if (typeof item === "number") {
            return String(item) === String(filter.q);
          }

          return false;
        })
      );

      return [...acc, ...res];
    }

    const filteredByColumn = list.filter((el: T) => {
      if (key === "from" || key === "to") return true;

      return (
        String(el[key as keyof T]).toLowerCase() ===
        String(filter[key]).toLowerCase()
      );
    });

    const withoutDuplicates = new Set([...acc, ...filteredByColumn]);

    return Array.from(withoutDuplicates);
  }, []);
};
