import { useRecordContext, useTranslate } from "react-admin";
import React, { Dispatch, useState } from "react";

import { BucketItem } from "src/utils/api/bucket-items";
import { deleteProductPhoto } from "src/utils/api/photo";
import { ModalFrame } from "src/components/shared";

import { IPhotoData } from "../../../../types";

const PhotoField = ({
  label,
  record,
  handleOpen,
  photoData,
  setPhotoData,
}: {
  label: string;
  record?: Record<string, any>;
  handleOpen: (data: IPhotoData) => void;
  photoData: IPhotoData[];
  setPhotoData: Dispatch<React.SetStateAction<IPhotoData[]>>;
}) => {
  const translate = useTranslate();

  const [isFrameOn, setIsFrameOn] = useState(false);
  const rec: BucketItem = useRecordContext() || record;

  const frameOpen = () => {
    setIsFrameOn(true);
  };

  const frameClose = () => setIsFrameOn(false);

  const photoEl = photoData.find((el) => el.itemId === rec.id);

  return (
    <span
      onClick={() => {
        if (!photoEl) {
          handleOpen({ bucketId: rec.bucketId, itemId: rec.id });
        }
      }}
    >
      {photoEl ? (
        <>
          <span
            onClick={() => {
              frameOpen();
            }}
            style={{ color: "blue", padding: "5px" }}
          >
            Смотреть
          </span>
          <span
            style={{ color: "blue", padding: "5px" }}
            onClick={() => {
              const key = photoEl?.url?.split("/").slice(3).join("/")!;
              deleteProductPhoto({
                bucketId: photoEl.bucketId!,
                key,
              }).then(() => {
                setPhotoData((prev) =>
                  prev.filter((el) => el.itemId !== photoEl.itemId)
                );
              });
            }}
          >
            {translate("root.columns.translate")}
          </span>
          <ModalFrame
            data={photoEl.url!}
            isFrameOn={isFrameOn}
            frameClose={frameClose}
          />
        </>
      ) : (
        "📎"
      )}
    </span>
  );
};

export default PhotoField;
