import { FieldValues } from "react-hook-form";
import {
  Create,
  PasswordInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  SaveButton,
} from "react-admin";

import { services } from "src/utils/api/base/api-instances";

const SetAdminForm = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const onCreateAdmin = async (params: FieldValues, id: number) => {
    try {
      await services.makeRootControllerRequest().setAdmin({
        shopId: id,
        adminDto: params,
      });

      notify("custom.notify.admin_created", { type: "success" });
    } catch (err: any) {
      const message = err?.response?.data["odata.error"]?.message?.value;
      if (message) {
        notify(message, { type: "error" });
      }
    }
  };

  const PostEditToolbar = () => <SaveButton sx={{ margin: "0 15px 10px" }} />;

  return (
    <Create title=" ">
      <SimpleForm
        onSubmit={(params) => onCreateAdmin(params, +record.id)}
        toolbar={<PostEditToolbar />}
      >
        <input name="password" className="hidden" />

        <TextInput
          source="login"
          inputProps={{ autoComplete: "off" }}
          label="custom.labels.login"
          resettable
        />

        <PasswordInput
          name="password"
          source="password"
          inputProps={{ autoComplete: "new-password" }}
          label="custom.labels.password"
        />
      </SimpleForm>
    </Create>
  );
};

export default SetAdminForm;
