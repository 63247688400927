import React, { FC, ReactElement, useEffect, useState } from "react";
import { List, useTranslate } from "react-admin";

import {Box, Modal, TextField as CommentField, Typography} from "@mui/material";

import { ItemForRefundDto } from "src/api";
import { ReturnButton } from "src/components/shared";

import { BucketItemsDataGrid, WebCam } from "./components";
import { IPhotoData } from "./types";

const BucketItemsPage: FC = (): ReactElement => {
  const translate = useTranslate();

  const [sum, setSum] = useState("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [refundSum, setRefundSum] = useState("");
  const [currentRefundSum, setCurrentRefundSum] = useState("");
  const [comment, setComment] = useState("");
  const [screenOrientation, setScreenOrientation] = useState(0);
  const [partialRefundsWeight, setPartialRefundsWeight] = useState<
    ItemForRefundDto[]
  >([]);

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [currentBucketDataForPhoto, setCurrentBucketDataForPhoto] = useState(
    {} as IPhotoData
  );
  const [photoData, setPhotoData] = useState<IPhotoData[]>([]);

  const handleOpen = (data: IPhotoData) => {
    setIsCameraOn(true);
    setCurrentBucketDataForPhoto(data);
  };

  const handleClose = () => setIsCameraOn(false);

  const onSetComment = (e: { target: { value: string } }) => setComment(e.target.value);

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setScreenOrientation(window.screen.orientation.angle);
    });

    return () =>
      window.removeEventListener("orientationchange", () => {
        setScreenOrientation(window.screen.orientation.angle);
      });
  }, []);

  return (
    <>
      <ReturnButton path={`/purchases`} selectedIds={selectedIds} />
      <List pagination={false} filter={{ id: true }}>
        <BucketItemsDataGrid
          setSum={setSum}
          setRefundSum={setRefundSum}
          currentRefundSum={currentRefundSum}
          setCurrentRefundSum={setCurrentRefundSum}
          comment={comment}
          handleOpen={handleOpen}
          setPartialRefundsWeight={setPartialRefundsWeight}
          partialRefundsWeight={partialRefundsWeight}
          setSelectedIds={setSelectedIds}
          photoData={photoData}
          setPhotoData={setPhotoData}
        />
      </List>
      <div style={{ marginTop: "10px" }}>
        <CommentField
          fullWidth
          id="comment"
          onChange={onSetComment}
          name="comment"
          label={translate('root.columns.select_comment_and_product')}
        />
        <Box sx={{marginTop: "20px"}}>
            <Typography>{`${translate("root.columns.purchase_amount")}: ${sum || ""}`}</Typography>
            <Typography>{`${translate("root.columns.check_refund_amount")}: ${
                refundSum || ""
            }`}</Typography>
            <Typography>{`${translate(
                "root.columns.current_return_amount"
            )}: ${currentRefundSum}`}</Typography>
        </Box>
      </div>

      <Modal
        open={isCameraOn}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
          flexDirection: !screenOrientation ? "column" : "row",
        }}>
          <WebCam
            screenOrientation={screenOrientation}
            currentData={currentBucketDataForPhoto}
            photoData={photoData}
            setPhotoData={setPhotoData}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BucketItemsPage;
