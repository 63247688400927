import { services } from "../base/api-instances";
import { Api } from "../index";

export async function logout() {
  try {
    await services.makeAuthControllerRequest().doAdminPanelLogout();
  } catch (e) {}

  localStorage.removeItem("auth");
  localStorage.removeItem("role");

  Api.Shops.cleanShopsCash();
  Api.Purchases.cleanBucketsCash();

  return Promise.resolve();
}
