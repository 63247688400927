import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNotify } from "react-admin";
import { Button } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CameraSwitchIcon from "@mui/icons-material/Cameraswitch";

import { setProductPhoto } from "src/utils/api/photo";

import { ISetData } from "./types";
import { getBlob } from "../../lib/getFile";

const WebCam = ({
  currentData,
  photoData,
  setPhotoData,
  handleClose,
  screenOrientation,
}: ISetData) => {
  const notify = useNotify();
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const cameraView = isFrontCamera ? "user" : "environment";
  const webcamRef = useRef<Webcam>(null);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: cameraView,
  };

  const onSwitchCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  const onGetPhoto = useCallback(
    (fn) => {
      const imageSrc = fn();

      const blob = getBlob(imageSrc);

      const hasPhoto = photoData.find(
        (item) => item?.itemId === currentData.itemId
      );

      setProductPhoto({
        bucketId: currentData.bucketId!,
        file: blob,
      })
        .then((data) => {
          hasPhoto
            ? setPhotoData((prev) => {
                const res = [...prev];
                const photo = res.find(
                  (item) => item.itemId === currentData.itemId
                )!;
                photo.url = data.uri;

                return res;
              })
            : setPhotoData((prev) => [
                ...prev,
                {
                  itemId: currentData.itemId,
                  url: data.uri,
                  bucketId: currentData.bucketId,
                },
              ]);
          notify("custom.notify.photo_save", {
            type: "success",
          });
        })
        .catch(() => {
          notify("custom.notify.error_on_camera", { type: "error" });
        });
      handleClose();
    },
    [handleClose, notify, setPhotoData, currentData, photoData]
  );

  const capture = useCallback(() => {
    return webcamRef.current?.getScreenshot();
  }, [webcamRef]);

  const portraitStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  };

  const landScapeStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "30px",
  };

  const webCamStyle = screenOrientation === 0 ? portraitStyle : landScapeStyle;

  return (
    <>
      <Webcam
        audio={false}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={540}
        videoConstraints={videoConstraints}
      />
      <div style={webCamStyle}>
        <div>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={onSwitchCamera}
          >
            <CameraSwitchIcon />
          </Button>
        </div>
        <div>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => onGetPhoto(capture)}
          >
            <PhotoCameraIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default WebCam;
