import React, { ChangeEvent, useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

import { Props } from "./types";

const ChooseItemCheckbox = ({
  setIds,
  id,
  isAllCheckboxChecked,
  setIsAllCheckboxChecked,
  ids,
}: Props) => {
  const [isChecked, setIsChecked] = useState(isAllCheckboxChecked);

  const onAddProductId = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsChecked((prev) => !prev);

    setIsAllCheckboxChecked(false);

    if (target.checked) {
      setIds((prev) => {
        return [...prev, Number(id)];
      });
    } else {
      setIds((prev) => prev.filter((el) => el !== id));
    }
  };

  useEffect(() => {
    if (ids.includes(id)) {
      setIsChecked(true);
    }
  }, [id, ids]);

  return <Checkbox checked={isChecked} onChange={onAddProductId} />;
};

export default ChooseItemCheckbox;
