import React, { FC, ReactElement, useState, useEffect } from "react";
import { Title, useTranslate } from "react-admin";
import { Typography, Switch, FormControlLabel } from "@mui/material";

import { services } from "src/utils/api/base/api-instances";

import "./styles/index.scss";

const SettingsPage: FC = (): ReactElement => {
  const translate = useTranslate();

  const [isManualBarcode, setIsManualBarcode] = useState(false);

  const onScanGoSwitch = async () => {
    await services
      .makeRootControllerRequest()
      .setBarcodeManualConfigGlobalIsEnabled({ isEnabled: !isManualBarcode });
    setIsManualBarcode((prev) => !prev);
  };

  const getBCConfig = async () => {
    const BCConfig = await services
      .makeRootControllerRequest()
      .getConfigManualBarcodeGlobal();
    setIsManualBarcode(BCConfig.data.isEnabled);
  };

  useEffect(() => {
    getBCConfig();
  }, []);

  return (
    <>
      <Title title="custom.menu.settings_scan_and_go" />

      <div className="scanGoSwitchButtonPosition">
        <Typography>
          <FormControlLabel
            control={<Switch />}
            label={translate("custom.labels.manual_input_barcode")}
            color="secondary"
            onChange={onScanGoSwitch}
            checked={isManualBarcode}
          />
        </Typography>
      </div>
    </>
  );
};

export default SettingsPage;
