import { BucketFullDto } from "../../api";
import { add, dinero, toFormat } from "dinero.js";
import { RUB } from "@dinero.js/currencies";

export const refundSum = (data: BucketFullDto) => {
  const rub = (value: number) =>
    dinero({
      currency: RUB,
      amount: value * 1000,
      scale: 3,
    });

  const transformer = ({ amount }: { amount: number }) => `${amount} ₽`;

  if (data.refunds?.length !== 0) {
    const res = data.refunds?.reduce(
      (acc, item) => add(acc, rub(item.refund?.amountRefunded!)),
      rub(0)
    )!;

    return toFormat(res, transformer);
  }
};
