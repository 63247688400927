import { FC, ReactElement } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  NumberField,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Typography } from "@mui/material";

import { ReturnButton } from "src/components/shared";

import { SetAdminForm } from "./components";

const ShopTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <span>
      {translate("custom.labels.shop")} №{record.id}{" "}
    </span>
  );
};

const ShopPage: FC = (props): ReactElement => {
  const translate = useTranslate();

  return (
    <>
      <ReturnButton path="/shops" />
      <Show title={<ShopTitle />}>
        <SimpleShowLayout>
          <TextField source="id" label="root.columns.shop_number" />
          <TextField source="name" label="root.columns.name" />
          <TextField source="city" label="root.columns.city" />
          <TextField source="openingTime" label="root.columns.time_open" />
          <TextField source="closingTime" label="root.columns.time_close" />
          <TextField
            source="cashBoxNumber"
            label="root.columns.checkout_number"
          />
          <NumberField source="lat" label="root.columns.width" />
          <NumberField source="lon" label="root.columns.longitude" />
          <TextField source="format" label="root.columns.format" />
          <TextField source="merchant" label="root.columns.merchant" />
          <TextField source="address" label="root.columns.show_name" />
          <TextField source="adminLogin" label="root.columns.login_admin" />
          <BooleanField source="enabled" label="root.columns.on_sg" />
          <BooleanField source="checkerEnabled" label="root.columns.off_pch" />
          <hr />
          <Typography>{translate("custom.buttons.create_admin")}</Typography>
          <SetAdminForm />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default ShopPage;
