import { useContext, useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { Typography } from "@mui/material";
import { nanoid } from "nanoid";

import { RefundItemsDto } from "src/api";

import { RoleContext } from "src/providers";

import { CustomSimpleList } from "src/components/shared";

import { getItemsByRefund } from "../../lib";

import { CustomUrlFieldAdaptive } from "../../components";

import { RefundItemsDtoTranslated } from "./types";

const RefundAdaptivePanel = ({ record }: { record: RefundItemsDto }) => {
  const { isRoot } = useContext(RoleContext);
  const translate = useTranslate();

  const [refundItems, setRefundItems] = useState<RefundItemsDtoTranslated[]>(
    []
  );

  const element = (data: string) => (
    <img title="photo" src={data} width="50vmax" alt="pic" />
  );

  useEffect(() => {
    getItemsByRefund(record, setRefundItems, isRoot);
  }, [isRoot, record]);

  return (
    <div>
      <CustomSimpleList
        externalData={refundItems}
        secondaryText={(record: any) => (
          <ul style={{ listStyle: "none" }}>
            <Typography>
              <li key={nanoid()}>{`${translate(
                "custom.labels.name_product"
              )}: "${record.productName}"`}</li>
              <li key={nanoid()}>{`${translate(
                "custom.labels.gauge"
              )}: ${translate(record.unit)}`}</li>
              <li key={nanoid()}>{`${translate("custom.labels.product_id")}: ${
                record.productId
              }`}</li>
              <li key={nanoid()}>{`${translate("custom.labels.weight_w")}: ${
                record?.weight
              }`}</li>
              <li key={nanoid()}>{`${translate(
                "custom.labels.refund_amount"
              )}: ${record?.refundedAmountDiscounted}`}</li>
              <li key={nanoid()}>
                {translate("custom.labels.photo")}:{" "}
                {(
                  <CustomUrlFieldAdaptive
                    source={record?.photoLink}
                    element={element}
                  />
                ) || ""}
              </li>
            </Typography>
          </ul>
        )}
      />
    </div>
  );
};

export default RefundAdaptivePanel;
