import { UserSettingsDto } from "src/api";

import { services } from "src/utils/api/base/api-instances";

export async function onPostFormData(params: UserSettingsDto, isRoot: boolean) {
  if (isRoot) {
    return await services
      .makeRootControllerRequest()
      .postSettings({ userSettingsDto: params });
  }

  return await services
    .makeAdminControllerRequest()
    .postSettings1({ userSettingsDto: params });
}
