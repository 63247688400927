import { FC, ReactElement, useContext, useMemo } from "react";
import { useRecordContext, useRedirect, useTranslate } from "react-admin";
import { RoleContext } from "src/providers";
import { Button } from "@mui/material";

import { CheckPageControlPanelProps } from "./types";

const CheckPageControlPanel: FC<CheckPageControlPanelProps> = ({
  recordId,
  onViewLogs = () => {},
  isVisibleLog,
}): ReactElement => {
  const { isAdmin } = useContext(RoleContext);
  const record = useRecordContext();
  const redirect = useRedirect();
  const translate = useTranslate();
  const linkToRefundsPage = useMemo(() => {
    const queryParams = new URLSearchParams();

    queryParams.set(
      "filter",
      JSON.stringify({ bucketId: recordId, fullSum: record.fullSum })
    );

    return `/bucket-items/?${queryParams.toString()}`;
  }, [recordId, record.fullSum]);

  function onCancel() {
    redirect("/purchases");
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "1em",
      }}
    >
      {isAdmin && (
        <Button
          href={linkToRefundsPage}
          size="medium"
          variant="text"
          color="secondary"
        >
          <>{translate("root.columns.return")}</>
        </Button>
      )}

      {isAdmin && (
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={onCancel}
        >
          {translate("root.columns.cancel")}
        </Button>
      )}

      <Button
        size="small"
        onClick={onViewLogs}
        variant="text"
        color="secondary"
      >
        {isVisibleLog
          ? translate("root.columns.hide_logs")
          : translate("root.columns.show_logs")}
      </Button>
    </div>
  );
};

export default CheckPageControlPanel;
