import { DateTimeInput, SearchInput, TextInput } from "react-admin";
import * as React from "react";

export const postFilters = [
  <TextInput
    name="shopId"
    type="number"
    label="root.columns.shop_number"
    source="shopId"
  />,
  <TextInput
    name="id"
    type="number"
    label="root.columns.number_check"
    source="fiscalOrder"
  />,
  <TextInput
    name="amountPaid"
    type="number"
    label="root.columns.amount"
    source="amountPaid"
  />,
  <TextInput
    name="status"
    type="string"
    label="root.columns.status"
    source="status"
  />,
  <DateTimeInput name="from" label="root.columns.from" source="1" />,
  <DateTimeInput name="to" label="root.columns.to" source="2" />,
  <SearchInput name="q" source="q" alwaysOn />,
];

export const namesMap = {
  fiscalOrder: "root.columns.sales_receipt",
  amountPaid: "root.columns.sum_with_sale",
  provisionalAmount: "root.columns.sum_without_sale",
  cardNumber: "root.columns.card_number",
  shopId: "root.columns.shop_number",
  shopAddress: "root.columns.address",
  completeTime: "root.columns.create_time",
  status: "root.columns.status",
};
