import { Dispatch, SetStateAction, useContext } from "react";
import {
  Create,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from "react-admin";
import { Typography } from "@mui/material";

import { Period, UserSettingsDto } from "src/utils/api/refunds/types";

import { RoleContext } from "src/providers";

import { onPostFormData } from "../../lib";

const ReportSettingsForm = ({
  postDefaultValue,
  setPostDefaultValue,
}: {
  postDefaultValue: UserSettingsDto;
  setPostDefaultValue: Dispatch<SetStateAction<UserSettingsDto>>;
}) => {
  const { isRoot } = useContext(RoleContext);
  const translate = useTranslate();

  const PostEditToolbar = () => <SaveButton sx={{ margin: "0 15px 10px" }} />;

  return (
    <>
      <Typography sx={{ marginLeft: "10px" }}>
        <span>{translate("custom.labels.current_settings")}:</span>
        <br />
        <span>{`${translate("custom.labels.email")}: ${
          postDefaultValue.email || ""
        }`}</span>
        <br />
        <span>{`${translate("custom.labels.periodicity")}: ${
          postDefaultValue.periodType || ""
        }`}</span>
        <br />
      </Typography>
      <Create title=" ">
        <SimpleForm
          onSubmit={(params) => {
            onPostFormData(params, isRoot).then(({ data }) => {
              const transformData = {
                ...data,
                periodType: Period[data.periodType!],
              };
              setPostDefaultValue(transformData);
            });
          }}
          defaultValues={postDefaultValue}
          toolbar={<PostEditToolbar />}
        >
          <TextInput source="email" resettable label="custom.labels.email" />
          <SelectInput
            source="periodType"
            label="custom.labels.periodicity"
            choices={[
              { id: "NONE", name: "" },
              { id: "DAY", name: "custom.labels.day" },
              { id: "WEEK", name: "custom.labels.week" },
              { id: "MONTH", name: "custom.labels.month" },
            ]}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default ReportSettingsForm;
