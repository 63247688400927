import { Box, Modal } from "@mui/material";

const ModalFrame = ({
  data,
  isFrameOn,
  frameClose,
}: {
  data: string;
  isFrameOn: boolean;
  frameClose: () => void;
}) => {
  return (
    <Modal
      open={isFrameOn}
      onClose={frameClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Box>
        <img title="photo" src={data} width="50vmax" alt="pic" />
      </Box>
    </Modal>
  );
};

export default ModalFrame;
