import { useGetOne, useTranslate, useUpdate } from "react-admin";
import { ReactElement, useEffect, useState } from "react";
import { Checkbox, Button } from "@mui/material";

import { Shop } from "src/utils/api/shops/types";

import { FieldData, Params } from "./types";

const PriceCheckerAdaptiveField = ({
  label,
  record,
}: FieldData): ReactElement | null => {
  const [update] = useUpdate();
  const translate = useTranslate();
  const { data } = useGetOne("shops", { id: record.id });

  const {
    id,
    address,
    name,
    enabled,
    merchant,
    cashBoxNumber,
    checkerEnabled,
  } = data as Shop;

  const [check, setCheck] = useState(checkerEnabled);
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  const queryParams: Params = {
    shopId: Number(id),
    address,
    name: name!,
    enabled,
    merchant,
    cashBoxNumber,
    checkerEnabled: check,
  };

  const checkerHandler = () => {
    record.id &&
      update("shops", {
        id: record.id,
        data: queryParams,
        previousData: {},
      }).then(() => {
        setIsButtonDisable(true);
      });
  };

  useEffect(() => {
    setCheck(checkerEnabled);
  }, [checkerEnabled]);

  return record.id ? (
    <span>
      <label htmlFor="pch">{label}</label>
      <Checkbox
        id="pch"
        onChange={(e) => {
          setCheck(() => {
            setIsButtonDisable(!isButtonDisable);
            return e.target.checked;
          });
        }}
        checked={check}
      />
      &nbsp;
      <Button
        onClick={checkerHandler}
        disabled={isButtonDisable}
        variant="text"
        size="large"
      >
        {translate("ra.action.save")}
      </Button>
    </span>
  ) : null;
};

export default PriceCheckerAdaptiveField;
