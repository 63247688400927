export const getTimeStamp = (data: string) => {
  const date = new Date(data);
  return date.getTime();
};

export const isCorrectDateRange = (from: string, to: string) => {
  if (from && to) {
    const fromTimeStamp = getTimeStamp(from);
    const toTimeStamp = getTimeStamp(to);

    return toTimeStamp - fromTimeStamp > 0;
  }

  return true;
};
