import axios from "axios";

import {
  AdminControllerApi,
  AdminControllerFilesApi,
  AdminPanelLoginEndpointsApi,
  BucketControllerApi,
  Configuration,
  RootControllerApi,
} from "src/api";

import { logout } from "../../auth";

const baseURL = process.env.REACT_APP_BASE_URL;

const apiInstance = axios.create({
  baseURL,
  withCredentials: true,
});

apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      await logout();
    }

    return Promise.reject(error);
  }
);

const makeRootControllerRequest = () => {
  return new RootControllerApi(new Configuration(), "", apiInstance);
};

const makeAdminControllerRequest = () => {
  return new AdminControllerApi(new Configuration(), "", apiInstance);
};

const makeBucketControllerRequest = () => {
  return new BucketControllerApi(new Configuration(), "", apiInstance);
};

const makeAuthControllerRequest = () => {
  return new AdminPanelLoginEndpointsApi(new Configuration(), "", apiInstance);
};

const makeAdminControllerRequestFiles = () => {
  return new AdminControllerFilesApi(new Configuration(), "", apiInstance);
};

export const services = {
  apiInstance,
  makeRootControllerRequest,
  makeBucketControllerRequest,
  makeAdminControllerRequest,
  makeAuthControllerRequest,
  makeAdminControllerRequestFiles,
};
