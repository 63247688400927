import * as React from "react";
import { nanoid } from "nanoid";
import {CardHeader, CardContent, CardActions, Collapse, Card} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

import { ExpandMore } from "./components";

interface IData {
  primaryText?: (record: Record<string, any>) => string;
  secondaryText?: (record: Record<string, any>) => ReactJSXElement;
  tertiaryText?: (record: Record<string, any>) => string;
  checkbox?: (record: Record<string, any>) => ReactJSXElement;
  showButton?: (record: Record<string, any>) => ReactJSXElement;
  expand?: (record: Record<string, any>) => ReactJSXElement;
  externalData?: Record<string, any>[];
  item: Record<string, any>;
}

const CustomListItem = ({
  primaryText,
  secondaryText,
  tertiaryText,
  checkbox,
  showButton,
  expand,
  item,
}: IData) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string | number
  ) => {
    if ((e.target as Element).id === String(id)) {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      {item && (
        <Card key={nanoid()} sx={{ maxWidth: 345 }}>
          <CardHeader
            title={primaryText && primaryText(item)}
            subheader={tertiaryText && tertiaryText(item)}
          />
          <CardContent>{secondaryText && secondaryText(item)}</CardContent>
          <CardActions disableSpacing>
            {checkbox && checkbox(item)}
            {showButton && showButton(item)}
            {item.refundId && (
              <ExpandMore
                expand={expanded}
                onClick={(e) => handleExpandClick(e, item.id)}
                aria-expanded={expanded}
                aria-label="show more"
                id={item.id}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            )}
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>{expand && expand(item)}</CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
};

export default CustomListItem;
