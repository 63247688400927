import * as React from "react";
import { FC, ReactElement, useState } from "react";
import { nanoid } from "nanoid";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  NumberField,
  Datagrid,
  WithRecord,
  useRecordContext,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { useMediaQuery, useTheme } from "@material-ui/core";

import {
  CardField,
  CustomSimpleList,
  CustomUrlField,
  ReturnButton,
} from "src/components/shared";

import { CheckPageControlPanel, Logs } from "./components";

interface Props {}

const CheckPage: FC<Props> = (): ReactElement => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const [isVisibleLog, setIsVisibleLog] = useState(false);

  const onViewLogs = () => {
    setIsVisibleLog(!isVisibleLog);
  };

  const CheckTitle = () => {
    const record = useRecordContext();
    if (!record) return null;

    return <span>Чек №{record.fiscalOrder} </span>;
  };

  const onError = () => {
    notify("custom.notify.check_with_order_not_found!", { type: "error" });
    redirect("/purchases");
  };

  const element = (data: string) => (
      <iframe src={data} style={{minWidth: 400, minHeight: "100%"}} width="100vmin" height="100vmin" title="ofd" />
  );

  return (
    <>
      <ReturnButton path="/purchases" />
      <Show queryOptions={{ onError: onError }} title={<CheckTitle />}>
        <SimpleShowLayout>
          <TextField source="fiscalOrder" label="root.columns.number_check" />
          <TextField source="date" label="custom.labels.date" />
          <TextField source="payMethod" label="root.columns.payment_method" />
          <NumberField source="fullSum" label="root.columns.sum_with_sale" />
          <NumberField
            source="sumWithoutDiscount"
            label="root.columns.sum_without_sale"
          />
          <CardField source="cardNumber" label="root.columns.card_number" />
          <NumberField source="friendCardId" label="root.columns.card_friend" />
          <ArrayField source="items" label="root.columns.products">
            {isSmall ? (
              <CustomSimpleList
                primaryText={(record) => record.productName}
                secondaryText={(record) => (
                  <span style={{ whiteSpace: "pre" }} key={nanoid()}>
                    {`
                    ${translate("root.columns.code1c")}: ${
                      record.item.productId
                    }
                    ${translate("root.columns.weight_w")}: ${record.item.weight}
                    ${translate("root.columns.gauge")}: ${record.unit}
                    ${translate("root.columns.price")}: ${record.item.soldPrice}
                    ${translate("root.columns.amount")}: ${record.item.summ}
                    ${translate("root.columns.sum_with_sale")}: ${
                      record.item.summDiscountedWBonus
                    }
                    ${translate("root.columns.barcode")}: ${record.item.code}
                `}
                  </span>
                )}
              />
            ) : (
              <Datagrid bulkActionButtons={false}>
                <TextField
                  source="item.productId"
                  label="root.columns.code1c"
                />
                <TextField source="productName" label="root.columns.name" />
                <NumberField
                  source="item.weight"
                  label="custom.labels.weight_w"
                />
                <TextField source="unit" label="custom.labels.gauge" />
                <NumberField
                  source="item.soldPrice"
                  label="custom.labels.price"
                />
                <NumberField source="item.summ" label="root.columns.sum" />
                <NumberField
                  source="item.summDiscountedWBonus"
                  label="root.columns.sum_with_sale"
                />
                <TextField source="item.code" label="custom.labels.barcode" />
              </Datagrid>
            )}
          </ArrayField>
          <NumberField
            source="bonusesDeducted"
            label="root.columns.written_bonuses"
          />
          <NumberField
            source="bonusesAccrued"
            label="root.columns.accrued_bonuses"
          />
          <TextField source="shopAddress" label="root.columns.address_shop" />
          <TextField source="authCode" label="custom.labels.code_auth" />
          <CustomUrlField
            source="fiscalUrl"
            label="custom.labels.check_odf"
            element={element}
            height={600}
          />

          <WithRecord
            render={(record) => {
              return (
                <CheckPageControlPanel
                  recordId={record.id}
                  onViewLogs={onViewLogs}
                  isVisibleLog={isVisibleLog}
                />
              );
            }}
          />
          <hr />
          {isVisibleLog && <Logs />}
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default CheckPage;
