import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { Dispatch, useNotify, useTranslate } from "react-admin";
import { Input, InputAdornment } from "@mui/material";
import { Identifier } from "ra-core";

import { ItemForRefundDto } from "src/api";

const WeightInputField = ({
  setWeight,
  ids,
  record,
  weights,
  setIsErrorRefund,
  setIsRefundButtonDisable,
}: {
  setWeight: Dispatch<SetStateAction<ItemForRefundDto[]>>;
  ids: (Identifier | undefined)[];
  record: Record<string, any>;
  weights: ItemForRefundDto[];
  setIsErrorRefund: Dispatch<React.SetStateAction<boolean>>;
  isErrorRefund: boolean;
  setIsRefundButtonDisable: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const notify = useNotify();
  const translate = useTranslate();

  const [inputValue, setInputValue] = useState("");

  const isInputDisabled = !ids.includes(record.id) || record.unit === "шт";

  const onSetWeight = (
    id: number | string,
    setPartialRefundsWeight: Dispatch<SetStateAction<ItemForRefundDto[]>> | any
  ) => {
    if (record.quantity < inputValue) {
      setIsErrorRefund(true);
      notify("custom.notify.weight_large", { type: "error" });
    } else {
      setIsErrorRefund(false);
    }

    if (!!+inputValue) {
      const currentWeight = weights.find((weight) => weight.itemId === id);

      currentWeight
        ?
          setPartialRefundsWeight((prev: ItemForRefundDto[]) => {
            const res = [...prev];
            const weight = res.find((weight) => weight.itemId === id)!;
            weight.weight = Number(inputValue);

            return [res];
          })
        :
          setPartialRefundsWeight((prev: ItemForRefundDto[]) => [
            ...prev,
            {
              itemId: Number(id),
              weight: Number(inputValue),
            },
          ]);
      setIsRefundButtonDisable(false);
    } else {
      setPartialRefundsWeight((prev: ItemForRefundDto[]) =>
        prev.filter((item) => item.itemId !== id)
      );
      setIsRefundButtonDisable(true);
      notify("custom.notify.input_valid_weight", { type: "error" });
    }
  };

  const onInputWeight = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();

    if (value.includes(".")) {
      const slicedValueByNumberOfCharacters = value.substring(
        0,
        value.indexOf(".") + 4
      );

      setInputValue(slicedValueByNumberOfCharacters);
    } else {
      const slicedValueByNumberOfCharacters = value
        .replace(",", ".")
        .substring(0, value.indexOf(".") + 4);

      setInputValue(slicedValueByNumberOfCharacters);
    }
  };

  useEffect(() => {
    const partialWeight = weights?.find(
      (item) => record.id === item.itemId
    )?.weight;
    if (weights && partialWeight) {
      setInputValue(String(partialWeight) || "");
    }
  }, [weights, record]);

  return (
    <Input
      name="weight"
      id="standard-adornment-weight"
      onChange={onInputWeight}
      onBlur={() => onSetWeight(record.id, setWeight)}
      value={inputValue}
      endAdornment={
        <InputAdornment position="end">
          {translate("root.columns.kg")}
        </InputAdornment>
      }
      aria-describedby="standard-weight-helper-text"
      inputProps={{
        "aria-label": "root.columns.input_weight",
      }}
      placeholder="root.columns.input_weight"
      disabled={isInputDisabled}
    />
  );
};

export default WeightInputField;
