import { useEffect } from "react";
import { useTranslate } from "react-admin";
import { Box, Typography } from "@mui/material";

const Empty = ({
  setIsEmptyPage,
}: {
  setIsEmptyPage?: (flag: boolean) => void;
}) => {
  const translate = useTranslate();

  useEffect(() => {
    setIsEmptyPage && setIsEmptyPage(true);

    return () => setIsEmptyPage && setIsEmptyPage(false);
  }, [setIsEmptyPage]);

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <div>
        <Typography variant="h5" paragraph>
          {translate("root.columns.empty_list")}
        </Typography>
      </div>
    </Box>
  );
};

export default Empty;
