/* tslint:disable */
/* eslint-disable */
/**
 * SPAR Scan & GO API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
  /**
   *
   * @type {string}
   * @memberof AdminDto
   */
  login?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDto
   */
  password?: string;
}
/**
 *
 * @export
 * @interface BindingCard
 */
export interface BindingCard {
  /**
   * Идентификатор созданной ранее связки. Может использоваться, только если у продавца есть разрешение на работу со связками. Если этот параметр передаётся в данном запросе, то это означает: 1. Данный заказ может быть оплачен только с помощью связки; 2. Плательщик будет перенаправлен на платёжную страницу,    где требуется только ввод CVC.
   * @type {string}
   * @memberof BindingCard
   */
  bindingId?: string;
  /**
   * Маскированный номер карты, которая использовалась для оплаты.
   * @type {string}
   * @memberof BindingCard
   */
  maskedPan?: string;
  /**
   * Срок истечения действия карты в формате ГГГГММ. Указан только после оплаты заказа.
   * @type {string}
   * @memberof BindingCard
   */
  expiryDate?: string;
  /**
   * Способ совершения платежа (платёж в с вводом карточных данных, оплата по связке и т. п.). Может принимать следующие значения:  - CARD - оплата с вводом карточных данных;  - CARD_BINDING - оплата связкой;  - CARD_MOTO - оплата через колл-центр;  - CARD_PRESENT - оплата как cardPresent;  - SBRF_SBOL - оплата через Сбербанк Онлайн;  - UPOP - оплата через China Union Pay;  - FILE_BINDING - оплата через файл;  - SMS_BINDING - оплата через смс;  - P2P - перевод с карты на карту;  - P2P_BINDING - перевод связкой;  - PAYPAL - оплата со счёта PayPal;  - MTS - оплата со счёта МТС;  - APPLE_PAY - Apple Pay;  - APPLE_PAY_BINDING - оплата связкой Apple Pay;  - ANDROID_PAY - Android Pay;  - ANDROID_PAY_BINDING - оплата связкой Android Pay;  - GOOGLE_PAY_CARD - Google Pay нетокенизированная;  - GOOGLE_PAY_CARD_BINDING - оплата связкой    с не токенизированной картой GooglePay;  - GOOGLE_PAY_TOKENIZED - Google Pay токенизированная;  - GOOGLE_PAY_TOKENIZED_BINDING - оплата связкой    с токенизированной картой GooglePay;  - SAMSUNG_PAY - Samsung Pay;  - SAMSUNG_PAY_BINDING - оплата связкой Samsung Pay;  - IPOS - оплата iPOS;  - SBERPAY - оплата SberPay;  - SBERID - оплата SberID.
   * @type {string}
   * @memberof BindingCard
   */
  paymentWay?: string;
  /**
   * Наименование платёжной системы. Доступны следующие варианты:  - VISA;  - MASTERCARD;  - AMEX;  - JCB;  - CUP;  - MIR.
   * @type {string}
   * @memberof BindingCard
   */
  paymentSystem?: string;
}
/**
 *
 * @export
 * @interface BindingDto
 */
export interface BindingDto {
  /**
   *
   * @type {string}
   * @memberof BindingDto
   */
  bindingId: string;
}
/**
 *
 * @export
 * @interface Bucket
 */
export interface Bucket {
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  userId?: string;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  shopId?: number;
  /**
   * Manzana FISCAL-Cheque Number
   * @type {string}
   * @memberof Bucket
   */
  chequeNum?: string;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  amountPaid?: number;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  provisionalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  payByBonus?: number;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  coupons?: string;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  bonusesAccrued?: number;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  bonusesDeducted?: number;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  friendCardId?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  payMethod?: BucketPayMethodEnum;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  status?: BucketStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  lastActionTime?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  completeTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof Bucket
   */
  isBlocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  typeClass?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  cardHash?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  terminalNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  cashTransactionDate?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  bankId?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  refNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  cardType?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  authCode?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  bankType?: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  cardNumber?: string;
  /**
   * Total bucket `discountAmount` = `provisionalAmount` - (`amountPaid` + `bonusesDeducted`)
   * @type {number}
   * @memberof Bucket
   */
  discountAmount?: number;
}

export const BucketPayMethodEnum = {
  Pos: "POS",
  Sbp: "SBP",
} as const;

export type BucketPayMethodEnum =
  typeof BucketPayMethodEnum[keyof typeof BucketPayMethodEnum];
export const BucketStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type BucketStatusEnum =
  typeof BucketStatusEnum[keyof typeof BucketStatusEnum];

/**
 *
 * @export
 * @interface BucketDto
 */
export interface BucketDto {
  /**
   *
   * @type {Bucket}
   * @memberof BucketDto
   */
  bucket?: Bucket;
}
/**
 *
 * @export
 * @interface BucketFullDto
 */
export interface BucketFullDto {
  /**
   *
   * @type {Bucket}
   * @memberof BucketFullDto
   */
  bucket?: Bucket;
  /**
   *
   * @type {Array<ItemDto>}
   * @memberof BucketFullDto
   */
  itemDtos?: Array<ItemDto>;
  /**
   *
   * @type {Array<RefundFullDto>}
   * @memberof BucketFullDto
   */
  refunds?: Array<RefundFullDto>;
  /**
   *
   * @type {string}
   * @memberof BucketFullDto
   */
  shopAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BucketFullDto
   */
  fiscalOrder?: string;
  /**
   *
   * @type {string}
   * @memberof BucketFullDto
   */
  fiscalUrl?: string;
}
/**
 *
 * @export
 * @interface BucketWithInvoiceDto
 */
export interface BucketWithInvoiceDto {
  /**
   *
   * @type {number}
   * @memberof BucketWithInvoiceDto
   */
  amountPaid?: number;
  /**
   *
   * @type {number}
   * @memberof BucketWithInvoiceDto
   */
  provisionalAmount?: number;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  shopAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  fiscalOrder?: string;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  payMethod?: BucketWithInvoiceDtoPayMethodEnum;
  /**
   *
   * @type {number}
   * @memberof BucketWithInvoiceDto
   */
  bucketId?: number;
  /**
   *
   * @type {number}
   * @memberof BucketWithInvoiceDto
   */
  shopId?: number;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  cardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BucketWithInvoiceDto
   */
  status?: string;
}

export const BucketWithInvoiceDtoPayMethodEnum = {
  Pos: "POS",
  Sbp: "SBP",
} as const;

export type BucketWithInvoiceDtoPayMethodEnum =
  typeof BucketWithInvoiceDtoPayMethodEnum[keyof typeof BucketWithInvoiceDtoPayMethodEnum];

/**
 *
 * @export
 * @interface CardDto
 */
export interface CardDto {
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  currentLevel: string;
  /**
   *
   * @type {number}
   * @memberof CardDto
   */
  balance: number;
}
/**
 *
 * @export
 * @interface CardResponseBase
 */
export interface CardResponseBase {
  /**
   *
   * @type {number}
   * @memberof CardResponseBase
   */
  transactionID?: number;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  requestID?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  processed: string;
  /**
   *
   * @type {number}
   * @memberof CardResponseBase
   */
  returnCode?: number;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  message?: string;
  /**
   *
   * @type {Array<PersonalOfferType>}
   * @memberof CardResponseBase
   */
  personalOffer?: Array<PersonalOfferType>;
  /**
   *
   * @type {WriteOffConfirmType}
   * @memberof CardResponseBase
   */
  writeOffConfirm?: WriteOffConfirmType;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  cashierMessage?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  cashierMessage2?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  chequeMessage?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  chequeMessage2?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  personalMessage?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  personalMessage2?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  birthDate?: string;
  /**
   *
   * @type {number}
   * @memberof CardResponseBase
   */
  age?: number;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  contactCurrentLevel?: string;
  /**
   *
   * @type {string}
   * @memberof CardResponseBase
   */
  contactPreviousLevel?: string;
  /**
   *
   * @type {Array<ExtendedAttributeType>}
   * @memberof CardResponseBase
   */
  extendedAttribute?: Array<ExtendedAttributeType>;
  /**
   *
   * @type {Array<CardType>}
   * @memberof CardResponseBase
   */
  card?: Array<CardType>;
}
/**
 *
 * @export
 * @interface CardType
 */
export interface CardType {
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  cardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  track2?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  tracks?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  cardNumber2?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  bonusType?: string;
  /**
   *
   * @type {number}
   * @memberof CardType
   */
  discount?: number;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  collaborationType?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  cardTypeID?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  cardCurrentLevel?: string;
  /**
   *
   * @type {string}
   * @memberof CardType
   */
  cardPreviousLevel?: string;
  /**
   *
   * @type {Array<ExtendedAttributeType>}
   * @memberof CardType
   */
  extendedAttribute?: Array<ExtendedAttributeType>;
  /**
   *
   * @type {number}
   * @memberof CardType
   */
  cardBalance?: number;
  /**
   *
   * @type {number}
   * @memberof CardType
   */
  cardActiveBalance?: number;
}
/**
 *
 * @export
 * @interface ChequeItemType
 */
export interface ChequeItemType {
  /**
   *
   * @type {string}
   * @memberof ChequeItemType
   */
  positionNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ChequeItemType
   */
  article?: string;
  /**
   *
   * @type {string}
   * @memberof ChequeItemType
   */
  articleName?: string;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  summ?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  discount?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  summDiscounted?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  availablePayment?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  chargedBonus?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  chargedStatusBonus?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  writeoffBonus?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  writeoffStatusBonus?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  activeChargedBonus?: number;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  activeChargedStatusBonus?: number;
  /**
   *
   * @type {Array<ExtendedAttributeType>}
   * @memberof ChequeItemType
   */
  extendedAttribute?: Array<ExtendedAttributeType>;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  banned?: number;
  /**
   *
   * @type {string}
   * @memberof ChequeItemType
   */
  orderPositionNumber?: string;
  /**
   *
   * @type {number}
   * @memberof ChequeItemType
   */
  mcp?: number;
}
/**
 *
 * @export
 * @interface CloneBucketDto
 */
export interface CloneBucketDto {
  /**
   *
   * @type {number}
   * @memberof CloneBucketDto
   */
  bucketId?: number;
  /**
   *
   * @type {Array<RealItemPrice>}
   * @memberof CloneBucketDto
   */
  items?: Array<RealItemPrice>;
}
/**
 *
 * @export
 * @interface ConfigBarcode
 */
export interface ConfigBarcode {
  /**
   *
   * @type {string}
   * @memberof ConfigBarcode
   */
  manualParam?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfigBarcode
   */
  isEnabled: boolean;
}
/**
 *
 * @export
 * @interface ConfigPayMethod
 */
export interface ConfigPayMethod {
  /**
   *
   * @type {string}
   * @memberof ConfigPayMethod
   */
  payMethod?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfigPayMethod
   */
  isEnabled?: boolean;
}
/**
 *
 * @export
 * @interface CouponDto
 */
export interface CouponDto {
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  partnerName?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  number?: string;
  /**
   *
   * @type {number}
   * @memberof CouponDto
   */
  statusType: number;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  cardId?: string;
  /**
   *
   * @type {boolean}
   * @memberof CouponDto
   */
  isActive: boolean;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  partnerId?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  contactId?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  actualStart?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  actualEnd?: string;
  /**
   *
   * @type {boolean}
   * @memberof CouponDto
   */
  isPurchased: boolean;
  /**
   *
   * @type {number}
   * @memberof CouponDto
   */
  barcodeVisualizationTypeCode: number;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  campaignId?: string;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  campaignName?: string;
  /**
   *
   * @type {boolean}
   * @memberof CouponDto
   */
  autoApply: boolean;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  chequeId?: string;
  /**
   *
   * @type {number}
   * @memberof CouponDto
   */
  stateSource?: number;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  broadcastName?: string;
  /**
   *
   * @type {boolean}
   * @memberof CouponDto
   */
  forExternalPartner?: boolean;
  /**
   *
   * @type {string}
   * @memberof CouponDto
   */
  externalPartnerName?: string;
}
/**
 *
 * @export
 * @interface CouponType
 */
export interface CouponType {
  /**
   *
   * @type {string}
   * @memberof CouponType
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CouponType
   */
  emissionID?: string;
  /**
   *
   * @type {string}
   * @memberof CouponType
   */
  typeID?: string;
  /**
   *
   * @type {string}
   * @memberof CouponType
   */
  applicabilityMessage?: string;
  /**
   *
   * @type {string}
   * @memberof CouponType
   */
  applicabilityCode?: string;
}
/**
 *
 * @export
 * @interface ExtendedAttributeType
 */
export interface ExtendedAttributeType {
  /**
   *
   * @type {string}
   * @memberof ExtendedAttributeType
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedAttributeType
   */
  value?: string;
}
/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {number}
   * @memberof Item
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  bucketId?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  productId?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  soldPrice?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  originalPrice?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  summ?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  discount?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  summDiscounted?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  summDiscountedWBonus?: number;
}
/**
 *
 * @export
 * @interface ItemDto
 */
export interface ItemDto {
  /**
   *
   * @type {string}
   * @memberof ItemDto
   */
  productName?: string;
  /**
   *
   * @type {string}
   * @memberof ItemDto
   */
  unit?: ItemDtoUnitEnum;
  /**
   *
   * @type {Item}
   * @memberof ItemDto
   */
  item?: Item;
}

export const ItemDtoUnitEnum = {
  Piece: "PIECE",
  Kg: "KG",
} as const;

export type ItemDtoUnitEnum =
  typeof ItemDtoUnitEnum[keyof typeof ItemDtoUnitEnum];

/**
 *
 * @export
 * @interface ItemForRefundDto
 */
export interface ItemForRefundDto {
  /**
   *
   * @type {number}
   * @memberof ItemForRefundDto
   */
  itemId?: number;
  /**
   *
   * @type {number}
   * @memberof ItemForRefundDto
   */
  weight?: number;
  /**
   *
   * @type {string}
   * @memberof ItemForRefundDto
   */
  photoLink?: string;
}
/**
 *
 * @export
 * @interface LinkDto
 */
export interface LinkDto {
  /**
   *
   * @type {string}
   * @memberof LinkDto
   */
  key?: string;
  /**
   * Uploaded file URL
   * @type {string}
   * @memberof LinkDto
   */
  uri?: string;
}
/**
 *
 * @export
 * @interface LinksDto
 */
export interface LinksDto {
  /**
   *
   * @type {Array<LinkDto>}
   * @memberof LinksDto
   */
  links?: Array<LinkDto>;
}
/**
 *
 * @export
 * @interface ManzanaBalanceDto
 */
export interface ManzanaBalanceDto {
  /**
   *
   * @type {CardDto}
   * @memberof ManzanaBalanceDto
   */
  card?: CardDto;
  /**
   *
   * @type {Array<CouponDto>}
   * @memberof ManzanaBalanceDto
   */
  coupons?: Array<CouponDto>;
}
/**
 *
 * @export
 * @interface ManzanaError
 */
export interface ManzanaError {
  /**
   *
   * @type {OData}
   * @memberof ManzanaError
   */
  "odata.error"?: OData;
}
/**
 *
 * @export
 * @interface ManzanaResDto
 */
export interface ManzanaResDto {
  /**
   *
   * @type {Bucket}
   * @memberof ManzanaResDto
   */
  bucket?: Bucket;
  /**
   *
   * @type {Array<ChequeItemType>}
   * @memberof ManzanaResDto
   */
  items?: Array<ChequeItemType>;
  /**
   *
   * @type {Array<CouponType>}
   * @memberof ManzanaResDto
   */
  coupons?: Array<CouponType>;
}
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  lang?: string;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  value?: string;
}
/**
 *
 * @export
 * @interface OData
 */
export interface OData {
  /**
   *
   * @type {string}
   * @memberof OData
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof OData
   */
  strCode?: string;
  /**
   *
   * @type {Message}
   * @memberof OData
   */
  message?: Message;
}
/**
 *
 * @export
 * @interface PersonalOfferType
 */
export interface PersonalOfferType {
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  quantityGoods?: number;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  priceOfQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  minquantity?: number;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  effectDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  expiryDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  subjectID?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  subjectName?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalOfferType
   */
  subjectUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  print?: number;
  /**
   *
   * @type {number}
   * @memberof PersonalOfferType
   */
  internalID?: number;
}
/**
 *
 * @export
 * @interface RealItemPrice
 */
export interface RealItemPrice {
  /**
   *
   * @type {RealPrice}
   * @memberof RealItemPrice
   */
  realPrice?: RealPrice;
  /**
   *
   * @type {number}
   * @memberof RealItemPrice
   */
  weight?: number;
  /**
   *
   * @type {string}
   * @memberof RealItemPrice
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof RealItemPrice
   */
  itemId?: number;
}
/**
 *
 * @export
 * @interface RealPrice
 */
export interface RealPrice {
  /**
   *
   * @type {number}
   * @memberof RealPrice
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof RealPrice
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RealPrice
   */
  unit?: RealPriceUnitEnum;
  /**
   *
   * @type {number}
   * @memberof RealPrice
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof RealPrice
   */
  originalPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof RealPrice
   */
  discount?: number;
  /**
   *
   * @type {boolean}
   * @memberof RealPrice
   */
  isAlcohol?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RealPrice
   */
  isMarked?: boolean;
}

export const RealPriceUnitEnum = {
  Piece: "PIECE",
  Kg: "KG",
} as const;

export type RealPriceUnitEnum =
  typeof RealPriceUnitEnum[keyof typeof RealPriceUnitEnum];

/**
 *
 * @export
 * @interface Refund
 */
export interface Refund {
  /**
   *
   * @type {number}
   * @memberof Refund
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Refund
   */
  bucketId?: number;
  /**
   *
   * @type {string}
   * @memberof Refund
   */
  employeeLogin?: string;
  /**
   *
   * @type {number}
   * @memberof Refund
   */
  shopId?: number;
  /**
   *
   * @type {number}
   * @memberof Refund
   */
  amountRefunded?: number;
  /**
   *
   * @type {number}
   * @memberof Refund
   */
  attempt?: number;
  /**
   *
   * @type {string}
   * @memberof Refund
   */
  status?: RefundStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Refund
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof Refund
   */
  comment?: string;
  /**
   * Manzana RETURN-Cheque Number
   * @type {string}
   * @memberof Refund
   */
  chequeNum?: string;
  /**
   *
   * @type {string}
   * @memberof Refund
   */
  referenceNumber?: string;
}

export const RefundStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type RefundStatusEnum =
  typeof RefundStatusEnum[keyof typeof RefundStatusEnum];

/**
 *
 * @export
 * @interface RefundFullDto
 */
export interface RefundFullDto {
  /**
   *
   * @type {Refund}
   * @memberof RefundFullDto
   */
  refund?: Refund;
  /**
   *
   * @type {Array<RefundItem>}
   * @memberof RefundFullDto
   */
  refundItems?: Array<RefundItem>;
  /**
   *
   * @type {string}
   * @memberof RefundFullDto
   */
  fiscalRefund?: string;
  /**
   *
   * @type {string}
   * @memberof RefundFullDto
   */
  fiscalUrl?: string;
}
/**
 *
 * @export
 * @interface RefundItem
 */
export interface RefundItem {
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  refundId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  itemId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  refundedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof RefundItem
   */
  refundedAmountDiscounted?: number;
  /**
   *
   * @type {string}
   * @memberof RefundItem
   */
  photoLink?: string;
}
/**
 *
 * @export
 * @interface RefundItemsDto
 */
export interface RefundItemsDto {
  /**
   *
   * @type {string}
   * @memberof RefundItemsDto
   */
  productName?: string;
  /**
   *
   * @type {string}
   * @memberof RefundItemsDto
   */
  unit?: RefundItemsDtoUnitEnum;
  /**
   *
   * @type {number}
   * @memberof RefundItemsDto
   */
  productId?: number;
  /**
   *
   * @type {RefundItem}
   * @memberof RefundItemsDto
   */
  refundItem?: RefundItem;
}

export const RefundItemsDtoUnitEnum = {
  Piece: "PIECE",
  Kg: "KG",
} as const;

export type RefundItemsDtoUnitEnum =
  typeof RefundItemsDtoUnitEnum[keyof typeof RefundItemsDtoUnitEnum];

/**
 *
 * @export
 * @interface RefundWithInvoiceDto
 */
export interface RefundWithInvoiceDto {
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  amountPaid?: number;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  shopAddress?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  fiscalOrder?: string;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  finalAmountPaid?: number;
  /**
   *
   * @type {boolean}
   * @memberof RefundWithInvoiceDto
   */
  isExceedingAttempt?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  refundDate?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  merchant?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  fiscalRefund?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  fiscalRefundUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  fiscalOrderUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  friendCardId?: string;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  bucketId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  shopId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  amountRefunded?: number;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  referenceNumber?: string;
  /**
   *
   * @type {number}
   * @memberof RefundWithInvoiceDto
   */
  refundId?: number;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  cardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof RefundWithInvoiceDto
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ResponseBindingStatus
 */
export interface ResponseBindingStatus {
  /**
   *
   * @type {string}
   * @memberof ResponseBindingStatus
   */
  status?: ResponseBindingStatusStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ResponseBindingStatus
   */
  message?: string;
}

export const ResponseBindingStatusStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type ResponseBindingStatusStatusEnum =
  typeof ResponseBindingStatusStatusEnum[keyof typeof ResponseBindingStatusStatusEnum];

/**
 *
 * @export
 * @interface ResponseBindings
 */
export interface ResponseBindings {
  /**
   *
   * @type {string}
   * @memberof ResponseBindings
   */
  status?: ResponseBindingsStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ResponseBindings
   */
  message?: string;
  /**
   *
   * @type {Array<BindingCard>}
   * @memberof ResponseBindings
   */
  cards?: Array<BindingCard>;
}

export const ResponseBindingsStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type ResponseBindingsStatusEnum =
  typeof ResponseBindingsStatusEnum[keyof typeof ResponseBindingsStatusEnum];

/**
 *
 * @export
 * @interface ResponseOrder
 */
export interface ResponseOrder {
  /**
   *
   * @type {string}
   * @memberof ResponseOrder
   */
  status?: ResponseOrderStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ResponseOrder
   */
  bucketId?: number;
  /**
   *
   * @type {number}
   * @memberof ResponseOrder
   */
  depositedAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseOrder
   */
  message?: string;
  /**
   *
   * @type {Array<ResponseRefund>}
   * @memberof ResponseOrder
   */
  refunds?: Array<ResponseRefund>;
}

export const ResponseOrderStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type ResponseOrderStatusEnum =
  typeof ResponseOrderStatusEnum[keyof typeof ResponseOrderStatusEnum];

/**
 *
 * @export
 * @interface ResponseRefund
 */
export interface ResponseRefund {
  /**
   *
   * @type {string}
   * @memberof ResponseRefund
   */
  status?: ResponseRefundStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ResponseRefund
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof ResponseRefund
   */
  refundedAmount?: number;
}

export const ResponseRefundStatusEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Wait: "WAIT",
} as const;

export type ResponseRefundStatusEnum =
  typeof ResponseRefundStatusEnum[keyof typeof ResponseRefundStatusEnum];

/**
 *
 * @export
 * @interface Shop
 */
export interface Shop {
  /**
   *
   * @type {number}
   * @memberof Shop
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  openingTime?: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  closingTime?: string;
  /**
   *
   * @type {number}
   * @memberof Shop
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Shop
   */
  lon?: number;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  address?: string;
  /**
   *
   * @type {boolean}
   * @memberof Shop
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Shop
   */
  checkerEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  merchant?: string;
  /**
   *
   * @type {string}
   * @memberof Shop
   */
  cashBoxNumber?: string;
}
/**
 *
 * @export
 * @interface ShopDto
 */
export interface ShopDto {
  /**
   *
   * @type {number}
   * @memberof ShopDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ShopDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ShopDto
   */
  city?: string;
  /**
   * Open day-time in HH:mm format
   * @type {string}
   * @memberof ShopDto
   */
  openingTime?: string;
  /**
   * Close day-time in HH:mm format
   * @type {string}
   * @memberof ShopDto
   */
  closingTime?: string;
  /**
   *
   * @type {number}
   * @memberof ShopDto
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof ShopDto
   */
  lon?: number;
  /**
   *
   * @type {string}
   * @memberof ShopDto
   */
  address?: string;
  /**
   * Always true, redundant. TODO: Remove when no any old applications present in wild nature
   * @type {boolean}
   * @memberof ShopDto
   * @deprecated
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface ShopWithAdminLoginDto
 */
export interface ShopWithAdminLoginDto {
  /**
   *
   * @type {Shop}
   * @memberof ShopWithAdminLoginDto
   */
  shop?: Shop;
  /**
   *
   * @type {string}
   * @memberof ShopWithAdminLoginDto
   */
  adminLogin?: string;
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email?: string;
}
/**
 *
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
  /**
   * Refund reports email send period
   * @type {string}
   * @memberof UserSettingsDto
   */
  periodType?: UserSettingsDtoPeriodTypeEnum;
  /**
   * Refund reports email address to send to
   * @type {string}
   * @memberof UserSettingsDto
   */
  email?: string;
}

export const UserSettingsDtoPeriodTypeEnum = {
  None: "NONE",
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
} as const;

export type UserSettingsDtoPeriodTypeEnum =
  typeof UserSettingsDtoPeriodTypeEnum[keyof typeof UserSettingsDtoPeriodTypeEnum];

/**
 *
 * @export
 * @interface WriteOffConfirmType
 */
export interface WriteOffConfirmType {
  /**
   *
   * @type {boolean}
   * @memberof WriteOffConfirmType
   */
  confirm?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WriteOffConfirmType
   */
  sendCode?: boolean;
  /**
   *
   * @type {string}
   * @memberof WriteOffConfirmType
   */
  verificationCode?: string;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} bucketId
     * @param {Array<ItemForRefundDto>} itemForRefundDto
     * @param {string} [comment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRefund: async (
      bucketId: number,
      itemForRefundDto: Array<ItemForRefundDto>,
      comment?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("createRefund", "bucketId", bucketId);
      // verify required parameter 'itemForRefundDto' is not null or undefined
      assertParamExists("createRefund", "itemForRefundDto", itemForRefundDto);
      const localVarPath = `/admin/api/createRefund/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (comment !== undefined) {
        localVarQueryParameter["comment"] = comment;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        itemForRefundDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBucketsWithParameters1: async (
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/getAllBucketsWithParameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (fiscalOrder !== undefined) {
        localVarQueryParameter["fiscalOrder"] = fiscalOrder;
      }

      if (amountPaid !== undefined) {
        localVarQueryParameter["amountPaid"] = amountPaid;
      }

      if (friendCardId !== undefined) {
        localVarQueryParameter["friendCardId"] = friendCardId;
      }

      if (timeStart !== undefined) {
        localVarQueryParameter["timeStart"] =
          (timeStart as any) instanceof Date
            ? (timeStart as any).toISOString()
            : timeStart;
      }

      if (timeEnd !== undefined) {
        localVarQueryParameter["timeEnd"] =
          (timeEnd as any) instanceof Date
            ? (timeEnd as any).toISOString()
            : timeEnd;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRefundsWithParameters1: async (
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/getAllRefundsWithParameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (fiscalRefund !== undefined) {
        localVarQueryParameter["fiscalRefund"] = fiscalRefund;
      }

      if (timeStart !== undefined) {
        localVarQueryParameter["timeStart"] =
          (timeStart as any) instanceof Date
            ? (timeStart as any).toISOString()
            : timeStart;
      }

      if (timeEnd !== undefined) {
        localVarQueryParameter["timeEnd"] =
          (timeEnd as any) instanceof Date
            ? (timeEnd as any).toISOString()
            : timeEnd;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformation1: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getBucketFullInformation1", "bucketId", bucketId);
      const localVarPath =
        `/admin/api/getBucketFullInformation/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformationByFiscalNumber1: async (
      fiscalNumber: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fiscalNumber' is not null or undefined
      assertParamExists(
        "getBucketFullInformationByFiscalNumber1",
        "fiscalNumber",
        fiscalNumber
      );
      const localVarPath =
        `/admin/api/getBucketFullInformationByFiscalNumber/{fiscalNumber}`.replace(
          `{${"fiscalNumber"}}`,
          encodeURIComponent(String(fiscalNumber))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus2: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getOrderStatus2", "bucketId", bucketId);
      const localVarPath = `/admin/api/getOrderStatus/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefundItemsByRefund1: async (
      refundId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'refundId' is not null or undefined
      assertParamExists("getRefundItemsByRefund1", "refundId", refundId);
      const localVarPath =
        `/admin/api/getRefundItemsByRefund/{refundId}`.replace(
          `{${"refundId"}}`,
          encodeURIComponent(String(refundId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings1: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopByCurrentAdmin: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/getShopByCurrentAdmin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSettings1: async (
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSettingsDto' is not null or undefined
      assertParamExists("postSettings1", "userSettingsDto", userSettingsDto);
      const localVarPath = `/admin/api/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (sendNow !== undefined) {
        localVarQueryParameter["sendNow"] = sendNow;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSettingsDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAttemptForBucketWithRefunds1: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists(
        "resetAttemptForBucketWithRefunds1",
        "bucketId",
        bucketId
      );
      const localVarPath =
        `/admin/api/resetAttemptForBucketWithRefunds/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {Array<ItemForRefundDto>} itemForRefundDto
     * @param {string} [comment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRefund(
      bucketId: number,
      itemForRefundDto: Array<ItemForRefundDto>,
      comment?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRefund>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRefund(
        bucketId,
        itemForRefundDto,
        comment,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBucketsWithParameters1(
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BucketWithInvoiceDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBucketsWithParameters1(
          fiscalOrder,
          amountPaid,
          friendCardId,
          timeStart,
          timeEnd,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRefundsWithParameters1(
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RefundWithInvoiceDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllRefundsWithParameters1(
          fiscalRefund,
          timeStart,
          timeEnd,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketFullInformation1(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketFullDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBucketFullInformation1(
          bucketId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketFullInformationByFiscalNumber1(
      fiscalNumber: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketFullDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBucketFullInformationByFiscalNumber1(
          fiscalNumber,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStatus2(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOrder>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderStatus2(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRefundItemsByRefund1(
      refundId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RefundItemsDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRefundItemsByRefund1(
          refundId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettings1(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserSettingsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings1(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShopByCurrentAdmin(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shop>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getShopByCurrentAdmin(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSettings1(
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserSettingsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSettings1(
        userSettingsDto,
        sendNow,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetAttemptForBucketWithRefunds1(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetAttemptForBucketWithRefunds1(
          bucketId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminControllerApiFp(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {Array<ItemForRefundDto>} itemForRefundDto
     * @param {string} [comment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRefund(
      bucketId: number,
      itemForRefundDto: Array<ItemForRefundDto>,
      comment?: string,
      options?: any
    ): AxiosPromise<ResponseRefund> {
      return localVarFp
        .createRefund(bucketId, itemForRefundDto, comment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBucketsWithParameters1(
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      options?: any
    ): AxiosPromise<Array<BucketWithInvoiceDto>> {
      return localVarFp
        .getAllBucketsWithParameters1(
          fiscalOrder,
          amountPaid,
          friendCardId,
          timeStart,
          timeEnd,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRefundsWithParameters1(
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      options?: any
    ): AxiosPromise<Array<RefundWithInvoiceDto>> {
      return localVarFp
        .getAllRefundsWithParameters1(fiscalRefund, timeStart, timeEnd, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformation1(
      bucketId: number,
      options?: any
    ): AxiosPromise<BucketFullDto> {
      return localVarFp
        .getBucketFullInformation1(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformationByFiscalNumber1(
      fiscalNumber: string,
      options?: any
    ): AxiosPromise<BucketFullDto> {
      return localVarFp
        .getBucketFullInformationByFiscalNumber1(fiscalNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus2(
      bucketId: number,
      options?: any
    ): AxiosPromise<ResponseOrder> {
      return localVarFp
        .getOrderStatus2(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefundItemsByRefund1(
      refundId: number,
      options?: any
    ): AxiosPromise<Array<RefundItemsDto>> {
      return localVarFp
        .getRefundItemsByRefund1(refundId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings1(options?: any): AxiosPromise<UserSettingsDto> {
      return localVarFp
        .getSettings1(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopByCurrentAdmin(options?: any): AxiosPromise<Shop> {
      return localVarFp
        .getShopByCurrentAdmin(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSettings1(
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options?: any
    ): AxiosPromise<UserSettingsDto> {
      return localVarFp
        .postSettings1(userSettingsDto, sendNow, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAttemptForBucketWithRefunds1(
      bucketId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetAttemptForBucketWithRefunds1(bucketId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createRefund operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiCreateRefundRequest
 */
export interface AdminControllerApiCreateRefundRequest {
  /**
   *
   * @type {number}
   * @memberof AdminControllerApiCreateRefund
   */
  readonly bucketId: number;

  /**
   *
   * @type {Array<ItemForRefundDto>}
   * @memberof AdminControllerApiCreateRefund
   */
  readonly itemForRefundDto: Array<ItemForRefundDto>;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiCreateRefund
   */
  readonly comment?: string;
}

/**
 * Request parameters for getAllBucketsWithParameters1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetAllBucketsWithParameters1Request
 */
export interface AdminControllerApiGetAllBucketsWithParameters1Request {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllBucketsWithParameters1
   */
  readonly fiscalOrder?: string;

  /**
   *
   * @type {number}
   * @memberof AdminControllerApiGetAllBucketsWithParameters1
   */
  readonly amountPaid?: number;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllBucketsWithParameters1
   */
  readonly friendCardId?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllBucketsWithParameters1
   */
  readonly timeStart?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllBucketsWithParameters1
   */
  readonly timeEnd?: string;
}

/**
 * Request parameters for getAllRefundsWithParameters1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetAllRefundsWithParameters1Request
 */
export interface AdminControllerApiGetAllRefundsWithParameters1Request {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllRefundsWithParameters1
   */
  readonly fiscalRefund?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllRefundsWithParameters1
   */
  readonly timeStart?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAllRefundsWithParameters1
   */
  readonly timeEnd?: string;
}

/**
 * Request parameters for getBucketFullInformation1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetBucketFullInformation1Request
 */
export interface AdminControllerApiGetBucketFullInformation1Request {
  /**
   *
   * @type {number}
   * @memberof AdminControllerApiGetBucketFullInformation1
   */
  readonly bucketId: number;
}

/**
 * Request parameters for getBucketFullInformationByFiscalNumber1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetBucketFullInformationByFiscalNumber1Request
 */
export interface AdminControllerApiGetBucketFullInformationByFiscalNumber1Request {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetBucketFullInformationByFiscalNumber1
   */
  readonly fiscalNumber: string;
}

/**
 * Request parameters for getOrderStatus2 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetOrderStatus2Request
 */
export interface AdminControllerApiGetOrderStatus2Request {
  /**
   *
   * @type {number}
   * @memberof AdminControllerApiGetOrderStatus2
   */
  readonly bucketId: number;
}

/**
 * Request parameters for getRefundItemsByRefund1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetRefundItemsByRefund1Request
 */
export interface AdminControllerApiGetRefundItemsByRefund1Request {
  /**
   *
   * @type {number}
   * @memberof AdminControllerApiGetRefundItemsByRefund1
   */
  readonly refundId: number;
}

/**
 * Request parameters for postSettings1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiPostSettings1Request
 */
export interface AdminControllerApiPostSettings1Request {
  /**
   *
   * @type {UserSettingsDto}
   * @memberof AdminControllerApiPostSettings1
   */
  readonly userSettingsDto: UserSettingsDto;

  /**
   *
   * @type {boolean}
   * @memberof AdminControllerApiPostSettings1
   */
  readonly sendNow?: boolean;
}

/**
 * Request parameters for resetAttemptForBucketWithRefunds1 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiResetAttemptForBucketWithRefunds1Request
 */
export interface AdminControllerApiResetAttemptForBucketWithRefunds1Request {
  /**
   *
   * @type {number}
   * @memberof AdminControllerApiResetAttemptForBucketWithRefunds1
   */
  readonly bucketId: number;
}

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
  /**
   *
   * @param {AdminControllerApiCreateRefundRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public createRefund(
    requestParameters: AdminControllerApiCreateRefundRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .createRefund(
        requestParameters.bucketId,
        requestParameters.itemForRefundDto,
        requestParameters.comment,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetAllBucketsWithParameters1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getAllBucketsWithParameters1(
    requestParameters: AdminControllerApiGetAllBucketsWithParameters1Request = {},
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getAllBucketsWithParameters1(
        requestParameters.fiscalOrder,
        requestParameters.amountPaid,
        requestParameters.friendCardId,
        requestParameters.timeStart,
        requestParameters.timeEnd,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetAllRefundsWithParameters1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getAllRefundsWithParameters1(
    requestParameters: AdminControllerApiGetAllRefundsWithParameters1Request = {},
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getAllRefundsWithParameters1(
        requestParameters.fiscalRefund,
        requestParameters.timeStart,
        requestParameters.timeEnd,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetBucketFullInformation1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getBucketFullInformation1(
    requestParameters: AdminControllerApiGetBucketFullInformation1Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getBucketFullInformation1(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetBucketFullInformationByFiscalNumber1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getBucketFullInformationByFiscalNumber1(
    requestParameters: AdminControllerApiGetBucketFullInformationByFiscalNumber1Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getBucketFullInformationByFiscalNumber1(
        requestParameters.fiscalNumber,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetOrderStatus2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getOrderStatus2(
    requestParameters: AdminControllerApiGetOrderStatus2Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getOrderStatus2(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiGetRefundItemsByRefund1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getRefundItemsByRefund1(
    requestParameters: AdminControllerApiGetRefundItemsByRefund1Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .getRefundItemsByRefund1(requestParameters.refundId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getSettings1(options?: AxiosRequestConfig) {
    return AdminControllerApiFp(this.configuration)
      .getSettings1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getShopByCurrentAdmin(options?: AxiosRequestConfig) {
    return AdminControllerApiFp(this.configuration)
      .getShopByCurrentAdmin(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiPostSettings1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public postSettings1(
    requestParameters: AdminControllerApiPostSettings1Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .postSettings1(
        requestParameters.userSettingsDto,
        requestParameters.sendNow,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerApiResetAttemptForBucketWithRefunds1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public resetAttemptForBucketWithRefunds1(
    requestParameters: AdminControllerApiResetAttemptForBucketWithRefunds1Request,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerApiFp(this.configuration)
      .resetAttemptForBucketWithRefunds1(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminControllerFilesApi - axios parameter creator
 * @export
 */
export const AdminControllerFilesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} bucketId
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delFile: async (
      bucketId: number,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("delFile", "bucketId", bucketId);
      // verify required parameter 'key' is not null or undefined
      assertParamExists("delFile", "key", key);
      const localVarPath = `/admin/api/files/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFiles: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getFiles", "bucketId", bucketId);
      const localVarPath = `/admin/api/files/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFile: async (
      bucketId: number,
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("postFile", "bucketId", bucketId);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postFile", "file", file);
      const localVarPath = `/admin/api/files/{bucketId}/upload`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminControllerFilesApi - functional programming interface
 * @export
 */
export const AdminControllerFilesApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdminControllerFilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delFile(
      bucketId: number,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delFile(
        bucketId,
        key,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFiles(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinksDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postFile(
      bucketId: number,
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postFile(
        bucketId,
        file,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdminControllerFilesApi - factory interface
 * @export
 */
export const AdminControllerFilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminControllerFilesApiFp(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delFile(bucketId: number, key: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .delFile(bucketId, key, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFiles(bucketId: number, options?: any): AxiosPromise<LinksDto> {
      return localVarFp
        .getFiles(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFile(
      bucketId: number,
      file: any,
      options?: any
    ): AxiosPromise<LinkDto> {
      return localVarFp
        .postFile(bucketId, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delFile operation in AdminControllerFilesApi.
 * @export
 * @interface AdminControllerFilesApiDelFileRequest
 */
export interface AdminControllerFilesApiDelFileRequest {
  /**
   *
   * @type {number}
   * @memberof AdminControllerFilesApiDelFile
   */
  readonly bucketId: number;

  /**
   *
   * @type {string}
   * @memberof AdminControllerFilesApiDelFile
   */
  readonly key: string;
}

/**
 * Request parameters for getFiles operation in AdminControllerFilesApi.
 * @export
 * @interface AdminControllerFilesApiGetFilesRequest
 */
export interface AdminControllerFilesApiGetFilesRequest {
  /**
   *
   * @type {number}
   * @memberof AdminControllerFilesApiGetFiles
   */
  readonly bucketId: number;
}

/**
 * Request parameters for postFile operation in AdminControllerFilesApi.
 * @export
 * @interface AdminControllerFilesApiPostFileRequest
 */
export interface AdminControllerFilesApiPostFileRequest {
  /**
   *
   * @type {number}
   * @memberof AdminControllerFilesApiPostFile
   */
  readonly bucketId: number;

  /**
   *
   * @type {any}
   * @memberof AdminControllerFilesApiPostFile
   */
  readonly file: any;
}

/**
 * AdminControllerFilesApi - object-oriented interface
 * @export
 * @class AdminControllerFilesApi
 * @extends {BaseAPI}
 */
export class AdminControllerFilesApi extends BaseAPI {
  /**
   *
   * @param {AdminControllerFilesApiDelFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerFilesApi
   */
  public delFile(
    requestParameters: AdminControllerFilesApiDelFileRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerFilesApiFp(this.configuration)
      .delFile(requestParameters.bucketId, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerFilesApiGetFilesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerFilesApi
   */
  public getFiles(
    requestParameters: AdminControllerFilesApiGetFilesRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerFilesApiFp(this.configuration)
      .getFiles(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminControllerFilesApiPostFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerFilesApi
   */
  public postFile(
    requestParameters: AdminControllerFilesApiPostFileRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminControllerFilesApiFp(this.configuration)
      .postFile(requestParameters.bucketId, requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminPanelLoginEndpointsApi - axios parameter creator
 * @export
 */
export const AdminPanelLoginEndpointsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [username]
     * @param {string} [password]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    doAdminPanelLogin: async (
      username?: string,
      password?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin-panel-login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (username !== undefined) {
        localVarFormParams.set("username", username as any);
      }

      if (password !== undefined) {
        localVarFormParams.set("password", password as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    doAdminPanelLogout: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin-panel-logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminPanelLoginEndpointsApi - functional programming interface
 * @export
 */
export const AdminPanelLoginEndpointsApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdminPanelLoginEndpointsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [username]
     * @param {string} [password]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async doAdminPanelLogin(
      username?: string,
      password?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.doAdminPanelLogin(
          username,
          password,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async doAdminPanelLogout(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.doAdminPanelLogout(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdminPanelLoginEndpointsApi - factory interface
 * @export
 */
export const AdminPanelLoginEndpointsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminPanelLoginEndpointsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [username]
     * @param {string} [password]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    doAdminPanelLogin(
      username?: string,
      password?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .doAdminPanelLogin(username, password, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    doAdminPanelLogout(options?: any): AxiosPromise<void> {
      return localVarFp
        .doAdminPanelLogout(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for doAdminPanelLogin operation in AdminPanelLoginEndpointsApi.
 * @export
 * @interface AdminPanelLoginEndpointsApiDoAdminPanelLoginRequest
 */
export interface AdminPanelLoginEndpointsApiDoAdminPanelLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AdminPanelLoginEndpointsApiDoAdminPanelLogin
   */
  readonly username?: string;

  /**
   *
   * @type {string}
   * @memberof AdminPanelLoginEndpointsApiDoAdminPanelLogin
   */
  readonly password?: string;
}

/**
 * AdminPanelLoginEndpointsApi - object-oriented interface
 * @export
 * @class AdminPanelLoginEndpointsApi
 * @extends {BaseAPI}
 */
export class AdminPanelLoginEndpointsApi extends BaseAPI {
  /**
   *
   * @param {AdminPanelLoginEndpointsApiDoAdminPanelLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPanelLoginEndpointsApi
   */
  public doAdminPanelLogin(
    requestParameters: AdminPanelLoginEndpointsApiDoAdminPanelLoginRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AdminPanelLoginEndpointsApiFp(this.configuration)
      .doAdminPanelLogin(
        requestParameters.username,
        requestParameters.password,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPanelLoginEndpointsApi
   */
  public doAdminPanelLogout(options?: AxiosRequestConfig) {
    return AdminPanelLoginEndpointsApiFp(this.configuration)
      .doAdminPanelLogout(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BucketControllerApi - axios parameter creator
 * @export
 */
export const BucketControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {number} [weight]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemToBucketByProductId: async (
      bucketId: number,
      productId: number,
      weight?: number,
      code?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("addItemToBucketByProductId", "bucketId", bucketId);
      // verify required parameter 'productId' is not null or undefined
      assertParamExists("addItemToBucketByProductId", "productId", productId);
      const localVarPath =
        `/api/v1/bucket/addItemToBucket/{bucketId}/{productId}`
          .replace(`{${"bucketId"}}`, encodeURIComponent(String(bucketId)))
          .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (weight !== undefined) {
        localVarQueryParameter["weight"] = weight;
      }

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockingBucket: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("blockingBucket", "bucketId", bucketId);
      const localVarPath = `/api/v1/bucket/blockingBucket/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearBucketById: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("clearBucketById", "bucketId", bucketId);
      const localVarPath = `/api/v1/bucket/clearBucket/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {number} oldBucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloneBucket: async (
      shopId: number,
      oldBucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("cloneBucket", "shopId", shopId);
      // verify required parameter 'oldBucketId' is not null or undefined
      assertParamExists("cloneBucket", "oldBucketId", oldBucketId);
      const localVarPath = `/api/v1/bucket/cloneBucket/{shopId}/{oldBucketId}`
        .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)))
        .replace(`{${"oldBucketId"}}`, encodeURIComponent(String(oldBucketId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewBucket: async (
      shopId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("createNewBucket", "shopId", shopId);
      const localVarPath = `/api/v1/bucket/createNewBucket/{shopId}`.replace(
        `{${"shopId"}}`,
        encodeURIComponent(String(shopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBucketsByUserId: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/bucket/findAllBucketsByUser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsByUserIdAndShopId: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getItemsByUserIdAndShopId", "bucketId", bucketId);
      const localVarPath =
        `/api/v1/bucket/getAllItemsFromBucket/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeItemByItemId: async (
      bucketId: number,
      itemId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("removeItemByItemId", "bucketId", bucketId);
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists("removeItemByItemId", "itemId", itemId);
      const localVarPath =
        `/api/v1/bucket/deleteItemFromBucket/{bucketId}/{itemId}`
          .replace(`{${"bucketId"}}`, encodeURIComponent(String(bucketId)))
          .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductsById: async (
      bucketId: number,
      productId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("removeProductsById", "bucketId", bucketId);
      // verify required parameter 'productId' is not null or undefined
      assertParamExists("removeProductsById", "productId", productId);
      const localVarPath =
        `/api/v1/bucket/deleteProductTypeFromBucket/{bucketId}/{productId}`
          .replace(`{${"bucketId"}}`, encodeURIComponent(String(bucketId)))
          .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BucketControllerApi - functional programming interface
 * @export
 */
export const BucketControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BucketControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {number} [weight]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addItemToBucketByProductId(
      bucketId: number,
      productId: number,
      weight?: number,
      code?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addItemToBucketByProductId(
          bucketId,
          productId,
          weight,
          code,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockingBucket(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blockingBucket(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearBucketById(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clearBucketById(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {number} oldBucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cloneBucket(
      shopId: number,
      oldBucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneBucketDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cloneBucket(
        shopId,
        oldBucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewBucket(
      shopId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNewBucket(
        shopId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findBucketsByUserId(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BucketDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findBucketsByUserId(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemsByUserIdAndShopId(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RealItemPrice>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getItemsByUserIdAndShopId(
          bucketId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeItemByItemId(
      bucketId: number,
      itemId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeItemByItemId(
          bucketId,
          itemId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeProductsById(
      bucketId: number,
      productId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeProductsById(
          bucketId,
          productId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * BucketControllerApi - factory interface
 * @export
 */
export const BucketControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BucketControllerApiFp(configuration);
  return {
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {number} [weight]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemToBucketByProductId(
      bucketId: number,
      productId: number,
      weight?: number,
      code?: string,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .addItemToBucketByProductId(bucketId, productId, weight, code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockingBucket(bucketId: number, options?: any): AxiosPromise<BucketDto> {
      return localVarFp
        .blockingBucket(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearBucketById(bucketId: number, options?: any): AxiosPromise<number> {
      return localVarFp
        .clearBucketById(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {number} oldBucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloneBucket(
      shopId: number,
      oldBucketId: number,
      options?: any
    ): AxiosPromise<CloneBucketDto> {
      return localVarFp
        .cloneBucket(shopId, oldBucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewBucket(shopId: number, options?: any): AxiosPromise<BucketDto> {
      return localVarFp
        .createNewBucket(shopId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBucketsByUserId(options?: any): AxiosPromise<Array<BucketDto>> {
      return localVarFp
        .findBucketsByUserId(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsByUserIdAndShopId(
      bucketId: number,
      options?: any
    ): AxiosPromise<Array<RealItemPrice>> {
      return localVarFp
        .getItemsByUserIdAndShopId(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeItemByItemId(
      bucketId: number,
      itemId: number,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .removeItemByItemId(bucketId, itemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductsById(
      bucketId: number,
      productId: number,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .removeProductsById(bucketId, productId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addItemToBucketByProductId operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiAddItemToBucketByProductIdRequest
 */
export interface BucketControllerApiAddItemToBucketByProductIdRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiAddItemToBucketByProductId
   */
  readonly bucketId: number;

  /**
   *
   * @type {number}
   * @memberof BucketControllerApiAddItemToBucketByProductId
   */
  readonly productId: number;

  /**
   *
   * @type {number}
   * @memberof BucketControllerApiAddItemToBucketByProductId
   */
  readonly weight?: number;

  /**
   *
   * @type {string}
   * @memberof BucketControllerApiAddItemToBucketByProductId
   */
  readonly code?: string;
}

/**
 * Request parameters for blockingBucket operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiBlockingBucketRequest
 */
export interface BucketControllerApiBlockingBucketRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiBlockingBucket
   */
  readonly bucketId: number;
}

/**
 * Request parameters for clearBucketById operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiClearBucketByIdRequest
 */
export interface BucketControllerApiClearBucketByIdRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiClearBucketById
   */
  readonly bucketId: number;
}

/**
 * Request parameters for cloneBucket operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiCloneBucketRequest
 */
export interface BucketControllerApiCloneBucketRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiCloneBucket
   */
  readonly shopId: number;

  /**
   *
   * @type {number}
   * @memberof BucketControllerApiCloneBucket
   */
  readonly oldBucketId: number;
}

/**
 * Request parameters for createNewBucket operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiCreateNewBucketRequest
 */
export interface BucketControllerApiCreateNewBucketRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiCreateNewBucket
   */
  readonly shopId: number;
}

/**
 * Request parameters for getItemsByUserIdAndShopId operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiGetItemsByUserIdAndShopIdRequest
 */
export interface BucketControllerApiGetItemsByUserIdAndShopIdRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiGetItemsByUserIdAndShopId
   */
  readonly bucketId: number;
}

/**
 * Request parameters for removeItemByItemId operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiRemoveItemByItemIdRequest
 */
export interface BucketControllerApiRemoveItemByItemIdRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiRemoveItemByItemId
   */
  readonly bucketId: number;

  /**
   *
   * @type {number}
   * @memberof BucketControllerApiRemoveItemByItemId
   */
  readonly itemId: number;
}

/**
 * Request parameters for removeProductsById operation in BucketControllerApi.
 * @export
 * @interface BucketControllerApiRemoveProductsByIdRequest
 */
export interface BucketControllerApiRemoveProductsByIdRequest {
  /**
   *
   * @type {number}
   * @memberof BucketControllerApiRemoveProductsById
   */
  readonly bucketId: number;

  /**
   *
   * @type {number}
   * @memberof BucketControllerApiRemoveProductsById
   */
  readonly productId: number;
}

/**
 * BucketControllerApi - object-oriented interface
 * @export
 * @class BucketControllerApi
 * @extends {BaseAPI}
 */
export class BucketControllerApi extends BaseAPI {
  /**
   *
   * @param {BucketControllerApiAddItemToBucketByProductIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public addItemToBucketByProductId(
    requestParameters: BucketControllerApiAddItemToBucketByProductIdRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .addItemToBucketByProductId(
        requestParameters.bucketId,
        requestParameters.productId,
        requestParameters.weight,
        requestParameters.code,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiBlockingBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public blockingBucket(
    requestParameters: BucketControllerApiBlockingBucketRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .blockingBucket(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiClearBucketByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public clearBucketById(
    requestParameters: BucketControllerApiClearBucketByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .clearBucketById(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiCloneBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public cloneBucket(
    requestParameters: BucketControllerApiCloneBucketRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .cloneBucket(
        requestParameters.shopId,
        requestParameters.oldBucketId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiCreateNewBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public createNewBucket(
    requestParameters: BucketControllerApiCreateNewBucketRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .createNewBucket(requestParameters.shopId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public findBucketsByUserId(options?: AxiosRequestConfig) {
    return BucketControllerApiFp(this.configuration)
      .findBucketsByUserId(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiGetItemsByUserIdAndShopIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public getItemsByUserIdAndShopId(
    requestParameters: BucketControllerApiGetItemsByUserIdAndShopIdRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .getItemsByUserIdAndShopId(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiRemoveItemByItemIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public removeItemByItemId(
    requestParameters: BucketControllerApiRemoveItemByItemIdRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .removeItemByItemId(
        requestParameters.bucketId,
        requestParameters.itemId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BucketControllerApiRemoveProductsByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketControllerApi
   */
  public removeProductsById(
    requestParameters: BucketControllerApiRemoveProductsByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return BucketControllerApiFp(this.configuration)
      .removeProductsById(
        requestParameters.bucketId,
        requestParameters.productId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManzanaControllerApi - axios parameter creator
 * @export
 */
export const ManzanaControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance: async (
      cardNum: string,
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cardNum' is not null or undefined
      assertParamExists("balance", "cardNum", cardNum);
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("balance", "bucketId", bucketId);
      const localVarPath = `/api/v1/pay/manzana/getBalance/{cardNum}/{bucketId}`
        .replace(`{${"cardNum"}}`, encodeURIComponent(String(cardNum)))
        .replace(`{${"bucketId"}}`, encodeURIComponent(String(bucketId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCards: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getCards", "bucketId", bucketId);
      const localVarPath = `/api/v1/pay/manzana/getCards/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} [payByBonus] Manzana Loyalty Bonus amount to spend on
     * @param {Array<string>} [couponNums] Manzana Loyalty Coupon numbers to apply on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSoldPriceForBucket: async (
      bucketId: number,
      cardNum: string,
      payByBonus?: number,
      couponNums?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("setSoldPriceForBucket", "bucketId", bucketId);
      // verify required parameter 'cardNum' is not null or undefined
      assertParamExists("setSoldPriceForBucket", "cardNum", cardNum);
      const localVarPath =
        `/api/v1/pay/manzana/setSoldPriceForBucket/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (cardNum !== undefined) {
        localVarQueryParameter["cardNum"] = cardNum;
      }

      if (payByBonus !== undefined) {
        localVarQueryParameter["payByBonus"] = payByBonus;
      }

      if (couponNums) {
        localVarQueryParameter["couponNums"] = couponNums;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManzanaControllerApi - functional programming interface
 * @export
 */
export const ManzanaControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ManzanaControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async balance(
      cardNum: string,
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ManzanaBalanceDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.balance(
        cardNum,
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCards(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CardResponseBase>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} [payByBonus] Manzana Loyalty Bonus amount to spend on
     * @param {Array<string>} [couponNums] Manzana Loyalty Coupon numbers to apply on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setSoldPriceForBucket(
      bucketId: number,
      cardNum: string,
      payByBonus?: number,
      couponNums?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManzanaResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setSoldPriceForBucket(
          bucketId,
          cardNum,
          payByBonus,
          couponNums,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ManzanaControllerApi - factory interface
 * @export
 */
export const ManzanaControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ManzanaControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance(
      cardNum: string,
      bucketId: number,
      options?: any
    ): AxiosPromise<ManzanaBalanceDto> {
      return localVarFp
        .balance(cardNum, bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCards(bucketId: number, options?: any): AxiosPromise<CardResponseBase> {
      return localVarFp
        .getCards(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId Bucket Id
     * @param {string} cardNum Manzana Loyalty Card Number, 13 digits
     * @param {number} [payByBonus] Manzana Loyalty Bonus amount to spend on
     * @param {Array<string>} [couponNums] Manzana Loyalty Coupon numbers to apply on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSoldPriceForBucket(
      bucketId: number,
      cardNum: string,
      payByBonus?: number,
      couponNums?: Array<string>,
      options?: any
    ): AxiosPromise<ManzanaResDto> {
      return localVarFp
        .setSoldPriceForBucket(
          bucketId,
          cardNum,
          payByBonus,
          couponNums,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for balance operation in ManzanaControllerApi.
 * @export
 * @interface ManzanaControllerApiBalanceRequest
 */
export interface ManzanaControllerApiBalanceRequest {
  /**
   * Manzana Loyalty Card Number, 13 digits
   * @type {string}
   * @memberof ManzanaControllerApiBalance
   */
  readonly cardNum: string;

  /**
   * Bucket Id
   * @type {number}
   * @memberof ManzanaControllerApiBalance
   */
  readonly bucketId: number;
}

/**
 * Request parameters for getCards operation in ManzanaControllerApi.
 * @export
 * @interface ManzanaControllerApiGetCardsRequest
 */
export interface ManzanaControllerApiGetCardsRequest {
  /**
   * Bucket Id
   * @type {number}
   * @memberof ManzanaControllerApiGetCards
   */
  readonly bucketId: number;
}

/**
 * Request parameters for setSoldPriceForBucket operation in ManzanaControllerApi.
 * @export
 * @interface ManzanaControllerApiSetSoldPriceForBucketRequest
 */
export interface ManzanaControllerApiSetSoldPriceForBucketRequest {
  /**
   * Bucket Id
   * @type {number}
   * @memberof ManzanaControllerApiSetSoldPriceForBucket
   */
  readonly bucketId: number;

  /**
   * Manzana Loyalty Card Number, 13 digits
   * @type {string}
   * @memberof ManzanaControllerApiSetSoldPriceForBucket
   */
  readonly cardNum: string;

  /**
   * Manzana Loyalty Bonus amount to spend on
   * @type {number}
   * @memberof ManzanaControllerApiSetSoldPriceForBucket
   */
  readonly payByBonus?: number;

  /**
   * Manzana Loyalty Coupon numbers to apply on
   * @type {Array<string>}
   * @memberof ManzanaControllerApiSetSoldPriceForBucket
   */
  readonly couponNums?: Array<string>;
}

/**
 * ManzanaControllerApi - object-oriented interface
 * @export
 * @class ManzanaControllerApi
 * @extends {BaseAPI}
 */
export class ManzanaControllerApi extends BaseAPI {
  /**
   *
   * @param {ManzanaControllerApiBalanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManzanaControllerApi
   */
  public balance(
    requestParameters: ManzanaControllerApiBalanceRequest,
    options?: AxiosRequestConfig
  ) {
    return ManzanaControllerApiFp(this.configuration)
      .balance(requestParameters.cardNum, requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManzanaControllerApiGetCardsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManzanaControllerApi
   */
  public getCards(
    requestParameters: ManzanaControllerApiGetCardsRequest,
    options?: AxiosRequestConfig
  ) {
    return ManzanaControllerApiFp(this.configuration)
      .getCards(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManzanaControllerApiSetSoldPriceForBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManzanaControllerApi
   */
  public setSoldPriceForBucket(
    requestParameters: ManzanaControllerApiSetSoldPriceForBucketRequest,
    options?: AxiosRequestConfig
  ) {
    return ManzanaControllerApiFp(this.configuration)
      .setSoldPriceForBucket(
        requestParameters.bucketId,
        requestParameters.cardNum,
        requestParameters.payByBonus,
        requestParameters.couponNums,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderControllerApi - axios parameter creator
 * @export
 */
export const OrderControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {number} bucketId
     * @param {string} email
     * @param {string} [fcmToken] Firebase Cloud Messaging token to receive push notifications with the order payment status info.
     * @param {boolean} [saveEmail] Update email in user profile
     * @param {BindingDto} [bindingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderForPay: async (
      payMethod: "POS" | "SBP",
      bucketId: number,
      email: string,
      fcmToken?: string,
      saveEmail?: boolean,
      bindingDto?: BindingDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'payMethod' is not null or undefined
      assertParamExists("createOrderForPay", "payMethod", payMethod);
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("createOrderForPay", "bucketId", bucketId);
      // verify required parameter 'email' is not null or undefined
      assertParamExists("createOrderForPay", "email", email);
      const localVarPath = `/api/v1/order/createOrder/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (payMethod !== undefined) {
        localVarQueryParameter["payMethod"] = payMethod;
      }

      if (email !== undefined) {
        localVarQueryParameter["email"] = email;
      }

      if (fcmToken !== undefined) {
        localVarQueryParameter["fcmToken"] = fcmToken;
      }

      if (saveEmail !== undefined) {
        localVarQueryParameter["saveEmail"] = saveEmail;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bindingDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getBindings", "bucketId", bucketId);
      const localVarPath = `/api/v1/order/POS/getBindings/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserInfo: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/order/getCurrentUserInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus1: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getOrderStatus1", "bucketId", bucketId);
      const localVarPath = `/api/v1/order/getOrderStatus/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayMethodConfigGlobal1: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/order/getPayMethodConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {BindingDto} bindingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unBindCard: async (
      bucketId: number,
      bindingDto: BindingDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("unBindCard", "bucketId", bucketId);
      // verify required parameter 'bindingDto' is not null or undefined
      assertParamExists("unBindCard", "bindingDto", bindingDto);
      const localVarPath = `/api/v1/order/POS/unBindCard/{bucketId}`.replace(
        `{${"bucketId"}}`,
        encodeURIComponent(String(bucketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bindingDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderControllerApi - functional programming interface
 * @export
 */
export const OrderControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {number} bucketId
     * @param {string} email
     * @param {string} [fcmToken] Firebase Cloud Messaging token to receive push notifications with the order payment status info.
     * @param {boolean} [saveEmail] Update email in user profile
     * @param {BindingDto} [bindingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrderForPay(
      payMethod: "POS" | "SBP",
      bucketId: number,
      email: string,
      fcmToken?: string,
      saveEmail?: boolean,
      bindingDto?: BindingDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOrder>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOrderForPay(
          payMethod,
          bucketId,
          email,
          fcmToken,
          saveEmail,
          bindingDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBindings(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ResponseBindings>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBindings(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentUserInfo(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentUserInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStatus1(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOrder>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderStatus1(
        bucketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayMethodConfigGlobal1(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ConfigPayMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayMethodConfigGlobal1(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {BindingDto} bindingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unBindCard(
      bucketId: number,
      bindingDto: BindingDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ResponseBindingStatus>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unBindCard(
        bucketId,
        bindingDto,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * OrderControllerApi - factory interface
 * @export
 */
export const OrderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrderControllerApiFp(configuration);
  return {
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {number} bucketId
     * @param {string} email
     * @param {string} [fcmToken] Firebase Cloud Messaging token to receive push notifications with the order payment status info.
     * @param {boolean} [saveEmail] Update email in user profile
     * @param {BindingDto} [bindingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderForPay(
      payMethod: "POS" | "SBP",
      bucketId: number,
      email: string,
      fcmToken?: string,
      saveEmail?: boolean,
      bindingDto?: BindingDto,
      options?: any
    ): AxiosPromise<ResponseOrder> {
      return localVarFp
        .createOrderForPay(
          payMethod,
          bucketId,
          email,
          fcmToken,
          saveEmail,
          bindingDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings(
      bucketId: number,
      options?: any
    ): AxiosPromise<ResponseBindings> {
      return localVarFp
        .getBindings(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserInfo(options?: any): AxiosPromise<UserDto> {
      return localVarFp
        .getCurrentUserInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus1(
      bucketId: number,
      options?: any
    ): AxiosPromise<ResponseOrder> {
      return localVarFp
        .getOrderStatus1(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayMethodConfigGlobal1(
      options?: any
    ): AxiosPromise<Array<ConfigPayMethod>> {
      return localVarFp
        .getPayMethodConfigGlobal1(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {BindingDto} bindingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unBindCard(
      bucketId: number,
      bindingDto: BindingDto,
      options?: any
    ): AxiosPromise<ResponseBindingStatus> {
      return localVarFp
        .unBindCard(bucketId, bindingDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createOrderForPay operation in OrderControllerApi.
 * @export
 * @interface OrderControllerApiCreateOrderForPayRequest
 */
export interface OrderControllerApiCreateOrderForPayRequest {
  /**
   *
   * @type {'POS' | 'SBP'}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly payMethod: "POS" | "SBP";

  /**
   *
   * @type {number}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly bucketId: number;

  /**
   *
   * @type {string}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly email: string;

  /**
   * Firebase Cloud Messaging token to receive push notifications with the order payment status info.
   * @type {string}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly fcmToken?: string;

  /**
   * Update email in user profile
   * @type {boolean}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly saveEmail?: boolean;

  /**
   *
   * @type {BindingDto}
   * @memberof OrderControllerApiCreateOrderForPay
   */
  readonly bindingDto?: BindingDto;
}

/**
 * Request parameters for getBindings operation in OrderControllerApi.
 * @export
 * @interface OrderControllerApiGetBindingsRequest
 */
export interface OrderControllerApiGetBindingsRequest {
  /**
   *
   * @type {number}
   * @memberof OrderControllerApiGetBindings
   */
  readonly bucketId: number;
}

/**
 * Request parameters for getOrderStatus1 operation in OrderControllerApi.
 * @export
 * @interface OrderControllerApiGetOrderStatus1Request
 */
export interface OrderControllerApiGetOrderStatus1Request {
  /**
   *
   * @type {number}
   * @memberof OrderControllerApiGetOrderStatus1
   */
  readonly bucketId: number;
}

/**
 * Request parameters for unBindCard operation in OrderControllerApi.
 * @export
 * @interface OrderControllerApiUnBindCardRequest
 */
export interface OrderControllerApiUnBindCardRequest {
  /**
   *
   * @type {number}
   * @memberof OrderControllerApiUnBindCard
   */
  readonly bucketId: number;

  /**
   *
   * @type {BindingDto}
   * @memberof OrderControllerApiUnBindCard
   */
  readonly bindingDto: BindingDto;
}

/**
 * OrderControllerApi - object-oriented interface
 * @export
 * @class OrderControllerApi
 * @extends {BaseAPI}
 */
export class OrderControllerApi extends BaseAPI {
  /**
   *
   * @param {OrderControllerApiCreateOrderForPayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public createOrderForPay(
    requestParameters: OrderControllerApiCreateOrderForPayRequest,
    options?: AxiosRequestConfig
  ) {
    return OrderControllerApiFp(this.configuration)
      .createOrderForPay(
        requestParameters.payMethod,
        requestParameters.bucketId,
        requestParameters.email,
        requestParameters.fcmToken,
        requestParameters.saveEmail,
        requestParameters.bindingDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrderControllerApiGetBindingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public getBindings(
    requestParameters: OrderControllerApiGetBindingsRequest,
    options?: AxiosRequestConfig
  ) {
    return OrderControllerApiFp(this.configuration)
      .getBindings(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public getCurrentUserInfo(options?: AxiosRequestConfig) {
    return OrderControllerApiFp(this.configuration)
      .getCurrentUserInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrderControllerApiGetOrderStatus1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public getOrderStatus1(
    requestParameters: OrderControllerApiGetOrderStatus1Request,
    options?: AxiosRequestConfig
  ) {
    return OrderControllerApiFp(this.configuration)
      .getOrderStatus1(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public getPayMethodConfigGlobal1(options?: AxiosRequestConfig) {
    return OrderControllerApiFp(this.configuration)
      .getPayMethodConfigGlobal1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrderControllerApiUnBindCardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerApi
   */
  public unBindCard(
    requestParameters: OrderControllerApiUnBindCardRequest,
    options?: AxiosRequestConfig
  ) {
    return OrderControllerApiFp(this.configuration)
      .unBindCard(
        requestParameters.bucketId,
        requestParameters.bindingDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PriceControllerApi - axios parameter creator
 * @export
 */
export const PriceControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} shopId
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byProductBarcode: async (
      shopId: number,
      barcode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("byProductBarcode", "shopId", shopId);
      // verify required parameter 'barcode' is not null or undefined
      assertParamExists("byProductBarcode", "barcode", barcode);
      const localVarPath = `/api/v1/price/{shopId}/{barcode}`
        .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)))
        .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byProductId: async (
      shopId: number,
      productId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("byProductId", "shopId", shopId);
      // verify required parameter 'productId' is not null or undefined
      assertParamExists("byProductId", "productId", productId);
      const localVarPath = `/api/v1/price/byProduct/{shopId}/{productId}`
        .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)))
        .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigManualBarcodeGlobal1: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/price/getBarcodeManualConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PriceControllerApi - functional programming interface
 * @export
 */
export const PriceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PriceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} shopId
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async byProductBarcode(
      shopId: number,
      barcode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPrice>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.byProductBarcode(
          shopId,
          barcode,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async byProductId(
      shopId: number,
      productId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPrice>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.byProductId(
        shopId,
        productId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfigManualBarcodeGlobal1(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigBarcode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConfigManualBarcodeGlobal1(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PriceControllerApi - factory interface
 * @export
 */
export const PriceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PriceControllerApiFp(configuration);
  return {
    /**
     *
     * @param {number} shopId
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byProductBarcode(
      shopId: number,
      barcode: string,
      options?: any
    ): AxiosPromise<RealPrice> {
      return localVarFp
        .byProductBarcode(shopId, barcode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byProductId(
      shopId: number,
      productId: number,
      options?: any
    ): AxiosPromise<RealPrice> {
      return localVarFp
        .byProductId(shopId, productId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigManualBarcodeGlobal1(options?: any): AxiosPromise<ConfigBarcode> {
      return localVarFp
        .getConfigManualBarcodeGlobal1(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for byProductBarcode operation in PriceControllerApi.
 * @export
 * @interface PriceControllerApiByProductBarcodeRequest
 */
export interface PriceControllerApiByProductBarcodeRequest {
  /**
   *
   * @type {number}
   * @memberof PriceControllerApiByProductBarcode
   */
  readonly shopId: number;

  /**
   *
   * @type {string}
   * @memberof PriceControllerApiByProductBarcode
   */
  readonly barcode: string;
}

/**
 * Request parameters for byProductId operation in PriceControllerApi.
 * @export
 * @interface PriceControllerApiByProductIdRequest
 */
export interface PriceControllerApiByProductIdRequest {
  /**
   *
   * @type {number}
   * @memberof PriceControllerApiByProductId
   */
  readonly shopId: number;

  /**
   *
   * @type {number}
   * @memberof PriceControllerApiByProductId
   */
  readonly productId: number;
}

/**
 * PriceControllerApi - object-oriented interface
 * @export
 * @class PriceControllerApi
 * @extends {BaseAPI}
 */
export class PriceControllerApi extends BaseAPI {
  /**
   *
   * @param {PriceControllerApiByProductBarcodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceControllerApi
   */
  public byProductBarcode(
    requestParameters: PriceControllerApiByProductBarcodeRequest,
    options?: AxiosRequestConfig
  ) {
    return PriceControllerApiFp(this.configuration)
      .byProductBarcode(
        requestParameters.shopId,
        requestParameters.barcode,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PriceControllerApiByProductIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceControllerApi
   */
  public byProductId(
    requestParameters: PriceControllerApiByProductIdRequest,
    options?: AxiosRequestConfig
  ) {
    return PriceControllerApiFp(this.configuration)
      .byProductId(
        requestParameters.shopId,
        requestParameters.productId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceControllerApi
   */
  public getConfigManualBarcodeGlobal1(options?: AxiosRequestConfig) {
    return PriceControllerApiFp(this.configuration)
      .getConfigManualBarcodeGlobal1(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RootControllerApi - axios parameter creator
 * @export
 */
export const RootControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBucketsWithParameters: async (
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/getAllBucketsWithParameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (fiscalOrder !== undefined) {
        localVarQueryParameter["fiscalOrder"] = fiscalOrder;
      }

      if (amountPaid !== undefined) {
        localVarQueryParameter["amountPaid"] = amountPaid;
      }

      if (friendCardId !== undefined) {
        localVarQueryParameter["friendCardId"] = friendCardId;
      }

      if (timeStart !== undefined) {
        localVarQueryParameter["timeStart"] =
          (timeStart as any) instanceof Date
            ? (timeStart as any).toISOString()
            : timeStart;
      }

      if (timeEnd !== undefined) {
        localVarQueryParameter["timeEnd"] =
          (timeEnd as any) instanceof Date
            ? (timeEnd as any).toISOString()
            : timeEnd;
      }

      if (shopId !== undefined) {
        localVarQueryParameter["shopId"] = shopId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRefundsWithParameters: async (
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/getAllRefundsWithParameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (fiscalRefund !== undefined) {
        localVarQueryParameter["fiscalRefund"] = fiscalRefund;
      }

      if (timeStart !== undefined) {
        localVarQueryParameter["timeStart"] =
          (timeStart as any) instanceof Date
            ? (timeStart as any).toISOString()
            : timeStart;
      }

      if (timeEnd !== undefined) {
        localVarQueryParameter["timeEnd"] =
          (timeEnd as any) instanceof Date
            ? (timeEnd as any).toISOString()
            : timeEnd;
      }

      if (shopId !== undefined) {
        localVarQueryParameter["shopId"] = shopId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllShops: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/getAllShops`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformation: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists("getBucketFullInformation", "bucketId", bucketId);
      const localVarPath =
        `/root/api/getBucketFullInformation/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformationByFiscalNumber: async (
      fiscalNumber: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fiscalNumber' is not null or undefined
      assertParamExists(
        "getBucketFullInformationByFiscalNumber",
        "fiscalNumber",
        fiscalNumber
      );
      const localVarPath =
        `/root/api/getBucketFullInformationByFiscalNumber/{fiscalNumber}`.replace(
          `{${"fiscalNumber"}}`,
          encodeURIComponent(String(fiscalNumber))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigManualBarcodeGlobal: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/getBarcodeManualConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bId Bucket ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus: async (
      bId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bId' is not null or undefined
      assertParamExists("getOrderStatus", "bId", bId);
      const localVarPath = `/root/api/orderStatus/{bId}`.replace(
        `{${"bId"}}`,
        encodeURIComponent(String(bId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayMethodConfigGlobal: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/getPayMethodConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefundItemsByRefund: async (
      refundId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'refundId' is not null or undefined
      assertParamExists("getRefundItemsByRefund", "refundId", refundId);
      const localVarPath =
        `/root/api/getRefundItemsByRefund/{refundId}`.replace(
          `{${"refundId"}}`,
          encodeURIComponent(String(refundId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopById: async (
      shopId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("getShopById", "shopId", shopId);
      const localVarPath = `/root/api/getShopById/{shopId}`.replace(
        `{${"shopId"}}`,
        encodeURIComponent(String(shopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initGlobalConfigsByDefaultFalseParameters: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/root/api/initGlobalConfigsByDefaultFalseParameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSettings: async (
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSettingsDto' is not null or undefined
      assertParamExists("postSettings", "userSettingsDto", userSettingsDto);
      const localVarPath = `/root/api/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (sendNow !== undefined) {
        localVarQueryParameter["sendNow"] = sendNow;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSettingsDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAttemptForBucketWithRefunds: async (
      bucketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketId' is not null or undefined
      assertParamExists(
        "resetAttemptForBucketWithRefunds",
        "bucketId",
        bucketId
      );
      const localVarPath =
        `/root/api/resetAttemptForBucketWithRefunds/{bucketId}`.replace(
          `{${"bucketId"}}`,
          encodeURIComponent(String(bucketId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {AdminDto} adminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAdmin: async (
      shopId: number,
      adminDto: AdminDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("setAdmin", "shopId", shopId);
      // verify required parameter 'adminDto' is not null or undefined
      assertParamExists("setAdmin", "adminDto", adminDto);
      const localVarPath = `/root/api/setAdmin/{shopId}`.replace(
        `{${"shopId"}}`,
        encodeURIComponent(String(shopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setBarcodeManualConfigGlobalIsEnabled: async (
      isEnabled: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'isEnabled' is not null or undefined
      assertParamExists(
        "setBarcodeManualConfigGlobalIsEnabled",
        "isEnabled",
        isEnabled
      );
      const localVarPath = `/root/api/setBarcodeManualConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (isEnabled !== undefined) {
        localVarQueryParameter["isEnabled"] = isEnabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setPayMethodConfigGlobalIsEnabled: async (
      payMethod: "POS" | "SBP",
      isEnabled: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'payMethod' is not null or undefined
      assertParamExists(
        "setPayMethodConfigGlobalIsEnabled",
        "payMethod",
        payMethod
      );
      // verify required parameter 'isEnabled' is not null or undefined
      assertParamExists(
        "setPayMethodConfigGlobalIsEnabled",
        "isEnabled",
        isEnabled
      );
      const localVarPath = `/root/api/setPayMethodConfigGlobal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (payMethod !== undefined) {
        localVarQueryParameter["payMethod"] = payMethod;
      }

      if (isEnabled !== undefined) {
        localVarQueryParameter["isEnabled"] = isEnabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} shopId
     * @param {string} name
     * @param {boolean} enabled
     * @param {string} [address]
     * @param {string} [merchant]
     * @param {string} [cashBoxNumber]
     * @param {boolean} [checkerEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateShop: async (
      shopId: number,
      name: string,
      enabled: boolean,
      address?: string,
      merchant?: string,
      cashBoxNumber?: string,
      checkerEnabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shopId' is not null or undefined
      assertParamExists("updateShop", "shopId", shopId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("updateShop", "name", name);
      // verify required parameter 'enabled' is not null or undefined
      assertParamExists("updateShop", "enabled", enabled);
      const localVarPath = `/root/api/updateShop/{shopId}`.replace(
        `{${"shopId"}}`,
        encodeURIComponent(String(shopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (enabled !== undefined) {
        localVarQueryParameter["enabled"] = enabled;
      }

      if (merchant !== undefined) {
        localVarQueryParameter["merchant"] = merchant;
      }

      if (cashBoxNumber !== undefined) {
        localVarQueryParameter["cashBoxNumber"] = cashBoxNumber;
      }

      if (checkerEnabled !== undefined) {
        localVarQueryParameter["checkerEnabled"] = checkerEnabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RootControllerApi - functional programming interface
 * @export
 */
export const RootControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RootControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBucketsWithParameters(
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BucketWithInvoiceDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBucketsWithParameters(
          fiscalOrder,
          amountPaid,
          friendCardId,
          timeStart,
          timeEnd,
          shopId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRefundsWithParameters(
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RefundWithInvoiceDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllRefundsWithParameters(
          fiscalRefund,
          timeStart,
          timeEnd,
          shopId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllShops(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Shop>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShops(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketFullInformation(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketFullDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBucketFullInformation(
          bucketId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketFullInformationByFiscalNumber(
      fiscalNumber: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketFullDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBucketFullInformationByFiscalNumber(
          fiscalNumber,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfigManualBarcodeGlobal(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigBarcode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConfigManualBarcodeGlobal(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bId Bucket ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStatus(
      bId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOrder>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderStatus(
        bId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayMethodConfigGlobal(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ConfigPayMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayMethodConfigGlobal(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRefundItemsByRefund(
      refundId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RefundItemsDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRefundItemsByRefund(
          refundId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettings(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserSettingsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShopById(
      shopId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ShopWithAdminLoginDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getShopById(
        shopId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initGlobalConfigsByDefaultFalseParameters(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.initGlobalConfigsByDefaultFalseParameters(
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSettings(
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserSettingsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSettings(
        userSettingsDto,
        sendNow,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetAttemptForBucketWithRefunds(
      bucketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetAttemptForBucketWithRefunds(
          bucketId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {AdminDto} adminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setAdmin(
      shopId: number,
      adminDto: AdminDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setAdmin(
        shopId,
        adminDto,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setBarcodeManualConfigGlobalIsEnabled(
      isEnabled: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setBarcodeManualConfigGlobalIsEnabled(
          isEnabled,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setPayMethodConfigGlobalIsEnabled(
      payMethod: "POS" | "SBP",
      isEnabled: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setPayMethodConfigGlobalIsEnabled(
          payMethod,
          isEnabled,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} shopId
     * @param {string} name
     * @param {boolean} enabled
     * @param {string} [address]
     * @param {string} [merchant]
     * @param {string} [cashBoxNumber]
     * @param {boolean} [checkerEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateShop(
      shopId: number,
      name: string,
      enabled: boolean,
      address?: string,
      merchant?: string,
      cashBoxNumber?: string,
      checkerEnabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateShop(
        shopId,
        name,
        enabled,
        address,
        merchant,
        cashBoxNumber,
        checkerEnabled,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * RootControllerApi - factory interface
 * @export
 */
export const RootControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RootControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} [fiscalOrder]
     * @param {number} [amountPaid]
     * @param {string} [friendCardId]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBucketsWithParameters(
      fiscalOrder?: string,
      amountPaid?: number,
      friendCardId?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options?: any
    ): AxiosPromise<Array<BucketWithInvoiceDto>> {
      return localVarFp
        .getAllBucketsWithParameters(
          fiscalOrder,
          amountPaid,
          friendCardId,
          timeStart,
          timeEnd,
          shopId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [fiscalRefund]
     * @param {string} [timeStart]
     * @param {string} [timeEnd]
     * @param {number} [shopId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRefundsWithParameters(
      fiscalRefund?: string,
      timeStart?: string,
      timeEnd?: string,
      shopId?: number,
      options?: any
    ): AxiosPromise<Array<RefundWithInvoiceDto>> {
      return localVarFp
        .getAllRefundsWithParameters(
          fiscalRefund,
          timeStart,
          timeEnd,
          shopId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllShops(options?: any): AxiosPromise<Array<Shop>> {
      return localVarFp
        .getAllShops(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformation(
      bucketId: number,
      options?: any
    ): AxiosPromise<BucketFullDto> {
      return localVarFp
        .getBucketFullInformation(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} fiscalNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketFullInformationByFiscalNumber(
      fiscalNumber: string,
      options?: any
    ): AxiosPromise<BucketFullDto> {
      return localVarFp
        .getBucketFullInformationByFiscalNumber(fiscalNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigManualBarcodeGlobal(options?: any): AxiosPromise<ConfigBarcode> {
      return localVarFp
        .getConfigManualBarcodeGlobal(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bId Bucket ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatus(bId: number, options?: any): AxiosPromise<ResponseOrder> {
      return localVarFp
        .getOrderStatus(bId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayMethodConfigGlobal(
      options?: any
    ): AxiosPromise<Array<ConfigPayMethod>> {
      return localVarFp
        .getPayMethodConfigGlobal(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefundItemsByRefund(
      refundId: number,
      options?: any
    ): AxiosPromise<Array<RefundItemsDto>> {
      return localVarFp
        .getRefundItemsByRefund(refundId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings(options?: any): AxiosPromise<UserSettingsDto> {
      return localVarFp
        .getSettings(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopById(
      shopId: number,
      options?: any
    ): AxiosPromise<ShopWithAdminLoginDto> {
      return localVarFp
        .getShopById(shopId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initGlobalConfigsByDefaultFalseParameters(
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .initGlobalConfigsByDefaultFalseParameters(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserSettingsDto} userSettingsDto
     * @param {boolean} [sendNow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSettings(
      userSettingsDto: UserSettingsDto,
      sendNow?: boolean,
      options?: any
    ): AxiosPromise<UserSettingsDto> {
      return localVarFp
        .postSettings(userSettingsDto, sendNow, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} bucketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAttemptForBucketWithRefunds(
      bucketId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetAttemptForBucketWithRefunds(bucketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {AdminDto} adminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAdmin(
      shopId: number,
      adminDto: AdminDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setAdmin(shopId, adminDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setBarcodeManualConfigGlobalIsEnabled(
      isEnabled: boolean,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setBarcodeManualConfigGlobalIsEnabled(isEnabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'POS' | 'SBP'} payMethod
     * @param {boolean} isEnabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setPayMethodConfigGlobalIsEnabled(
      payMethod: "POS" | "SBP",
      isEnabled: boolean,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setPayMethodConfigGlobalIsEnabled(payMethod, isEnabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} shopId
     * @param {string} name
     * @param {boolean} enabled
     * @param {string} [address]
     * @param {string} [merchant]
     * @param {string} [cashBoxNumber]
     * @param {boolean} [checkerEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateShop(
      shopId: number,
      name: string,
      enabled: boolean,
      address?: string,
      merchant?: string,
      cashBoxNumber?: string,
      checkerEnabled?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateShop(
          shopId,
          name,
          enabled,
          address,
          merchant,
          cashBoxNumber,
          checkerEnabled,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllBucketsWithParameters operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetAllBucketsWithParametersRequest
 */
export interface RootControllerApiGetAllBucketsWithParametersRequest {
  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly fiscalOrder?: string;

  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly amountPaid?: number;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly friendCardId?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly timeStart?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly timeEnd?: string;

  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetAllBucketsWithParameters
   */
  readonly shopId?: number;
}

/**
 * Request parameters for getAllRefundsWithParameters operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetAllRefundsWithParametersRequest
 */
export interface RootControllerApiGetAllRefundsWithParametersRequest {
  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllRefundsWithParameters
   */
  readonly fiscalRefund?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllRefundsWithParameters
   */
  readonly timeStart?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetAllRefundsWithParameters
   */
  readonly timeEnd?: string;

  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetAllRefundsWithParameters
   */
  readonly shopId?: number;
}

/**
 * Request parameters for getBucketFullInformation operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetBucketFullInformationRequest
 */
export interface RootControllerApiGetBucketFullInformationRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetBucketFullInformation
   */
  readonly bucketId: number;
}

/**
 * Request parameters for getBucketFullInformationByFiscalNumber operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetBucketFullInformationByFiscalNumberRequest
 */
export interface RootControllerApiGetBucketFullInformationByFiscalNumberRequest {
  /**
   *
   * @type {string}
   * @memberof RootControllerApiGetBucketFullInformationByFiscalNumber
   */
  readonly fiscalNumber: string;
}

/**
 * Request parameters for getOrderStatus operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetOrderStatusRequest
 */
export interface RootControllerApiGetOrderStatusRequest {
  /**
   * Bucket ID
   * @type {number}
   * @memberof RootControllerApiGetOrderStatus
   */
  readonly bId: number;
}

/**
 * Request parameters for getRefundItemsByRefund operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetRefundItemsByRefundRequest
 */
export interface RootControllerApiGetRefundItemsByRefundRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetRefundItemsByRefund
   */
  readonly refundId: number;
}

/**
 * Request parameters for getShopById operation in RootControllerApi.
 * @export
 * @interface RootControllerApiGetShopByIdRequest
 */
export interface RootControllerApiGetShopByIdRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiGetShopById
   */
  readonly shopId: number;
}

/**
 * Request parameters for postSettings operation in RootControllerApi.
 * @export
 * @interface RootControllerApiPostSettingsRequest
 */
export interface RootControllerApiPostSettingsRequest {
  /**
   *
   * @type {UserSettingsDto}
   * @memberof RootControllerApiPostSettings
   */
  readonly userSettingsDto: UserSettingsDto;

  /**
   *
   * @type {boolean}
   * @memberof RootControllerApiPostSettings
   */
  readonly sendNow?: boolean;
}

/**
 * Request parameters for resetAttemptForBucketWithRefunds operation in RootControllerApi.
 * @export
 * @interface RootControllerApiResetAttemptForBucketWithRefundsRequest
 */
export interface RootControllerApiResetAttemptForBucketWithRefundsRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiResetAttemptForBucketWithRefunds
   */
  readonly bucketId: number;
}

/**
 * Request parameters for setAdmin operation in RootControllerApi.
 * @export
 * @interface RootControllerApiSetAdminRequest
 */
export interface RootControllerApiSetAdminRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiSetAdmin
   */
  readonly shopId: number;

  /**
   *
   * @type {AdminDto}
   * @memberof RootControllerApiSetAdmin
   */
  readonly adminDto: AdminDto;
}

/**
 * Request parameters for setBarcodeManualConfigGlobalIsEnabled operation in RootControllerApi.
 * @export
 * @interface RootControllerApiSetBarcodeManualConfigGlobalIsEnabledRequest
 */
export interface RootControllerApiSetBarcodeManualConfigGlobalIsEnabledRequest {
  /**
   *
   * @type {boolean}
   * @memberof RootControllerApiSetBarcodeManualConfigGlobalIsEnabled
   */
  readonly isEnabled: boolean;
}

/**
 * Request parameters for setPayMethodConfigGlobalIsEnabled operation in RootControllerApi.
 * @export
 * @interface RootControllerApiSetPayMethodConfigGlobalIsEnabledRequest
 */
export interface RootControllerApiSetPayMethodConfigGlobalIsEnabledRequest {
  /**
   *
   * @type {'POS' | 'SBP'}
   * @memberof RootControllerApiSetPayMethodConfigGlobalIsEnabled
   */
  readonly payMethod: "POS" | "SBP";

  /**
   *
   * @type {boolean}
   * @memberof RootControllerApiSetPayMethodConfigGlobalIsEnabled
   */
  readonly isEnabled: boolean;
}

/**
 * Request parameters for updateShop operation in RootControllerApi.
 * @export
 * @interface RootControllerApiUpdateShopRequest
 */
export interface RootControllerApiUpdateShopRequest {
  /**
   *
   * @type {number}
   * @memberof RootControllerApiUpdateShop
   */
  readonly shopId: number;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiUpdateShop
   */
  readonly name: string;

  /**
   *
   * @type {boolean}
   * @memberof RootControllerApiUpdateShop
   */
  readonly enabled: boolean;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiUpdateShop
   */
  readonly address?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiUpdateShop
   */
  readonly merchant?: string;

  /**
   *
   * @type {string}
   * @memberof RootControllerApiUpdateShop
   */
  readonly cashBoxNumber?: string;

  /**
   *
   * @type {boolean}
   * @memberof RootControllerApiUpdateShop
   */
  readonly checkerEnabled?: boolean;
}

/**
 * RootControllerApi - object-oriented interface
 * @export
 * @class RootControllerApi
 * @extends {BaseAPI}
 */
export class RootControllerApi extends BaseAPI {
  /**
   *
   * @param {RootControllerApiGetAllBucketsWithParametersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getAllBucketsWithParameters(
    requestParameters: RootControllerApiGetAllBucketsWithParametersRequest = {},
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getAllBucketsWithParameters(
        requestParameters.fiscalOrder,
        requestParameters.amountPaid,
        requestParameters.friendCardId,
        requestParameters.timeStart,
        requestParameters.timeEnd,
        requestParameters.shopId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetAllRefundsWithParametersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getAllRefundsWithParameters(
    requestParameters: RootControllerApiGetAllRefundsWithParametersRequest = {},
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getAllRefundsWithParameters(
        requestParameters.fiscalRefund,
        requestParameters.timeStart,
        requestParameters.timeEnd,
        requestParameters.shopId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getAllShops(options?: AxiosRequestConfig) {
    return RootControllerApiFp(this.configuration)
      .getAllShops(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetBucketFullInformationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getBucketFullInformation(
    requestParameters: RootControllerApiGetBucketFullInformationRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getBucketFullInformation(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetBucketFullInformationByFiscalNumberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getBucketFullInformationByFiscalNumber(
    requestParameters: RootControllerApiGetBucketFullInformationByFiscalNumberRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getBucketFullInformationByFiscalNumber(
        requestParameters.fiscalNumber,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getConfigManualBarcodeGlobal(options?: AxiosRequestConfig) {
    return RootControllerApiFp(this.configuration)
      .getConfigManualBarcodeGlobal(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetOrderStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getOrderStatus(
    requestParameters: RootControllerApiGetOrderStatusRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getOrderStatus(requestParameters.bId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getPayMethodConfigGlobal(options?: AxiosRequestConfig) {
    return RootControllerApiFp(this.configuration)
      .getPayMethodConfigGlobal(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetRefundItemsByRefundRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getRefundItemsByRefund(
    requestParameters: RootControllerApiGetRefundItemsByRefundRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getRefundItemsByRefund(requestParameters.refundId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getSettings(options?: AxiosRequestConfig) {
    return RootControllerApiFp(this.configuration)
      .getSettings(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiGetShopByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public getShopById(
    requestParameters: RootControllerApiGetShopByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .getShopById(requestParameters.shopId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public initGlobalConfigsByDefaultFalseParameters(
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .initGlobalConfigsByDefaultFalseParameters(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiPostSettingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public postSettings(
    requestParameters: RootControllerApiPostSettingsRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .postSettings(
        requestParameters.userSettingsDto,
        requestParameters.sendNow,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiResetAttemptForBucketWithRefundsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public resetAttemptForBucketWithRefunds(
    requestParameters: RootControllerApiResetAttemptForBucketWithRefundsRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .resetAttemptForBucketWithRefunds(requestParameters.bucketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiSetAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public setAdmin(
    requestParameters: RootControllerApiSetAdminRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .setAdmin(requestParameters.shopId, requestParameters.adminDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiSetBarcodeManualConfigGlobalIsEnabledRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public setBarcodeManualConfigGlobalIsEnabled(
    requestParameters: RootControllerApiSetBarcodeManualConfigGlobalIsEnabledRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .setBarcodeManualConfigGlobalIsEnabled(
        requestParameters.isEnabled,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiSetPayMethodConfigGlobalIsEnabledRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public setPayMethodConfigGlobalIsEnabled(
    requestParameters: RootControllerApiSetPayMethodConfigGlobalIsEnabledRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .setPayMethodConfigGlobalIsEnabled(
        requestParameters.payMethod,
        requestParameters.isEnabled,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RootControllerApiUpdateShopRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootControllerApi
   */
  public updateShop(
    requestParameters: RootControllerApiUpdateShopRequest,
    options?: AxiosRequestConfig
  ) {
    return RootControllerApiFp(this.configuration)
      .updateShop(
        requestParameters.shopId,
        requestParameters.name,
        requestParameters.enabled,
        requestParameters.address,
        requestParameters.merchant,
        requestParameters.cashBoxNumber,
        requestParameters.checkerEnabled,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ShopControllerApi - axios parameter creator
 * @export
 */
export const ShopControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (
      isPriceChecker?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/shop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (isPriceChecker !== undefined) {
        localVarQueryParameter["isPriceChecker"] = isPriceChecker;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byId: async (
      id: number,
      isPriceChecker?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("byId", "id", id);
      const localVarPath = `/api/v1/shop/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication userId required
      await setApiKeyToObject(localVarQueryParameter, "user_id", configuration);

      // authentication userSession required
      await setApiKeyToObject(
        localVarQueryParameter,
        "session_id",
        configuration
      );

      if (isPriceChecker !== undefined) {
        localVarQueryParameter["isPriceChecker"] = isPriceChecker;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ShopControllerApi - functional programming interface
 * @export
 */
export const ShopControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ShopControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      isPriceChecker?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(
        isPriceChecker,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async byId(
      id: number,
      isPriceChecker?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.byId(
        id,
        isPriceChecker,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ShopControllerApi - factory interface
 * @export
 */
export const ShopControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ShopControllerApiFp(configuration);
  return {
    /**
     *
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(isPriceChecker?: boolean, options?: any): AxiosPromise<Array<ShopDto>> {
      return localVarFp
        .all(isPriceChecker, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [isPriceChecker]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    byId(
      id: number,
      isPriceChecker?: boolean,
      options?: any
    ): AxiosPromise<ShopDto> {
      return localVarFp
        .byId(id, isPriceChecker, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for all operation in ShopControllerApi.
 * @export
 * @interface ShopControllerApiAllRequest
 */
export interface ShopControllerApiAllRequest {
  /**
   *
   * @type {boolean}
   * @memberof ShopControllerApiAll
   */
  readonly isPriceChecker?: boolean;
}

/**
 * Request parameters for byId operation in ShopControllerApi.
 * @export
 * @interface ShopControllerApiByIdRequest
 */
export interface ShopControllerApiByIdRequest {
  /**
   *
   * @type {number}
   * @memberof ShopControllerApiById
   */
  readonly id: number;

  /**
   *
   * @type {boolean}
   * @memberof ShopControllerApiById
   */
  readonly isPriceChecker?: boolean;
}

/**
 * ShopControllerApi - object-oriented interface
 * @export
 * @class ShopControllerApi
 * @extends {BaseAPI}
 */
export class ShopControllerApi extends BaseAPI {
  /**
   *
   * @param {ShopControllerApiAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShopControllerApi
   */
  public all(
    requestParameters: ShopControllerApiAllRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ShopControllerApiFp(this.configuration)
      .all(requestParameters.isPriceChecker, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ShopControllerApiByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShopControllerApi
   */
  public byId(
    requestParameters: ShopControllerApiByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return ShopControllerApiFp(this.configuration)
      .byId(requestParameters.id, requestParameters.isPriceChecker, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
