export const getBlob = (data: any) => {
  if (!data) {
    return;
  }

  let bytes = atob(data.split(",")[1]);
  let array = [];
  for (let i = 0; i < bytes.length; i++) {
    array.push(bytes.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
};
