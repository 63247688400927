import { useMemo, useContext, useEffect } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";
import { ResourceProps } from "ra-core";

import {
  StorefrontOutlined,
  ShoppingCartOutlined,
  KeyboardReturnOutlined,
} from "@mui/icons-material";

import { useApiProvider, useAuthProvider } from "src/providers";
import { RoleContext, UserRole } from "src/providers";
import { i18nProvider } from "src/i18n";
import { ResourceName } from "src/utils/api";

import {
  SettingsPage,
  ShopsPage,
  ShopPage,
  PurchasesPage,
  ReturnsPage,
  CheckPage,
  BucketItemsPage,
  ShopEdit,
} from "src/pages";

import Layout from "src/components/Layout";

import "src/styles/global.scss";

function App() {
  const { isRoot, setUser } = useContext(RoleContext);
  const authProvider = useAuthProvider();
  const apiProvider = useApiProvider();

  const resourcesProps = useMemo<ResourceProps[]>(() => {
    const items: ResourceProps[] = [];

    if (isRoot) {
      items.push({
        name: ResourceName.SHOPS,
        icon: StorefrontOutlined,
        options: { label: "custom.menu.shops_list" },
        list: ShopsPage,
        edit: ShopEdit,
        show: ShopPage,
      });
    }

    items.push(
      {
        name: ResourceName.PURCHASES,
        icon: ShoppingCartOutlined,
        options: { label: "custom.menu.shopping_list" },
        list: PurchasesPage,
        show: CheckPage,
      },
      {
        name: ResourceName.REFUNDS,
        icon: KeyboardReturnOutlined,
        options: { label: "custom.menu.return_history" },
        list: ReturnsPage,
      },
      {
        name: ResourceName.BUCKET_ITEMS,
        icon: KeyboardReturnOutlined,
        options: { label: "custom.menu.list_items_in_cart" },
        list: BucketItemsPage,
      }
    );

    return items;
  }, [isRoot]);

  useEffect(() => {
    setUser({ role: localStorage.getItem("role") as UserRole });
  }, [setUser]);

  return (
    <div className="App">
      <BrowserRouter>
        <Admin
          layout={Layout}
          dataProvider={apiProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
        >
          {resourcesProps.map((props) => (
            <Resource key={props.name} {...props} />
          ))}

          <CustomRoutes>
            {isRoot && <Route path="/settings" element={<SettingsPage />} />}
          </CustomRoutes>
        </Admin>
      </BrowserRouter>
    </div>
  );
}

export default App;
