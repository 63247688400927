import { useState } from "react";
import QrReader from "react-qr-reader";
import { useNotify } from "react-admin";

import CameraSwitchIcon from "@mui/icons-material/Cameraswitch";
import { Button } from "@mui/material";

import { IScanData } from "./types";

const PurchasesScanner = ({ setData, setIsScanOn }: IScanData) => {
  const notify = useNotify();

  const [isFrontCamera, setIsFrontCamera] = useState(false);

  const cameraView = isFrontCamera ? "user" : "environment";

  const onSwitchCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  const onResult = (result: any): void => {
    if (!!result) {
      setData(result);
      setIsScanOn(false);
    }
  };

  const onError = (error: any) => {
    console.log(error);
    notify("custom.notify.try_again", { type: "error" });
  };

  return (
    <>
      <QrReader
        facingMode={cameraView}
        delay={500}
        onScan={onResult}
        onError={onError}
        style={{ width: "40vmax" }}
      />
      <div>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={onSwitchCamera}
        >
          <CameraSwitchIcon />
        </Button>
      </div>
    </>
  );
};

export default PurchasesScanner;
