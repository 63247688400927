import { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, Typography } from "@mui/material";

import { ChooseRefundCheckboxProps } from "./types";

const ChooseRefundCheckbox = ({
  setIds,
  id,
  ids,
}: ChooseRefundCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const onAddRefundId = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsChecked((prev) => !prev);

    if (target.checked) {
      return setIds((prev) => {
        return [...prev, Number(id)];
      });
    }

    return setIds((prev) => prev.filter((el) => el !== id));
  };

  useEffect(() => {
    if (ids.includes(id)) {
      setIsChecked(true);
    }
  }, [id, ids]);

  return (
    <span>
      <Typography>
        <Checkbox id="forLabel" onChange={onAddRefundId} checked={isChecked} />
      </Typography>
    </span>
  );
};

export default ChooseRefundCheckbox;
