import russianMessages from "ra-language-russian";

export const russianCustomMessages = {
  ...russianMessages,
  root: {
    columns: {
      sum: 'Сумма',
      empty_list: "Список еще пуст",
      current_return_amount: "Сумма по текущему возврату",
      purchase_amount: "Сумма покупки",
      check_refund_amount: "Сумма возврата по чеку",
      select_comment_and_product:
        "Введите комментарий и выберите товары для возврата",
      issue_refund: "Оформить возврат на сумму",
      pcs: "шт",
      quantity: "Количество",
      price_sum: "Стоимость",
      kg: "кг",
      show_check: "Смотреть чек",
      payment_method: "Способ оплаты",
      shop_number: "Номер магазина",
      number_check: "Номер чека",
      name: "Название",
      city: "Город",
      unit_of_measurement: "Единица измерения",
      on_sg: "Включение SG",
      off_pch: "Включение PCH",
      in_shop: "В магазин",
      checkout_number: "Номер кассы",
      merchant: "Мерчант",
      date_and_time: "Дата и время",
      card_friend: "Картa Друга №",
      show_name: "Отображаемое имя",
      products: "Товары",
      time_open: "Время открытия",
      time_close: "Время закрытия",
      width: "Широта",
      longitude: "Долгота",
      format: "Формат",
      login_admin: "Логин администратора",
      login_operator: "Логин оператора",
      amount: "Сумма",
      no_result: "Нет результата",
      status: "Статус",
      from: "от",
      to: "до",
      return_receipt_number: "Чек возврата №",
      bank_transaction: "Банковская транзакция №",
      card_number: "Номер карты",
      shop_n: "Магазин №",
      sum_without_sale: "Сумма без скидки",
      sum_with_sale: "Сумма со скидкой",
      select_all: "Выбрать все",
      edit: "Изменить",
      create_time: "Время создания",
      scan_check: "Сканировать чек",
      address_shop: "Адрес магазина",
      written_bonuses: "Списанные бонусы",
      accrued_bonuses: "Начисленные бонусы",
      address: "Адрес",
      sales_receipt: "Чек продажи №",
      sum_receipt: "Сумма продажи",
      code1c: "Код 1C",
      hide_logs: "Скрыть логи",
      show_logs: "Смотреть логи",
      return: "Возврат",
      return_kg: "Вернуть кг",
      cancel: "Отменить",
      input_weight: "Введите вес",
    },
  },
  custom: {
    buttons: {
      in_shop: "В магазин",
      create_admin: "Создать админа",
      show: "Смотреть",
    },
    menu: {
      shops_list: "Список магазинов",
      shopping_list: "Список покупок",
      return_history: "История возвратов",
      list_items_in_cart: "Список товаров в корзине",
      settings_scan_and_go: "Настройки Scan&Go",
    },
    filters: {
      number_shop: "Номер магазина",
      name: "Название",
      city: "Город",
    },
    labels: {
      edit_shop: "Редактирование магазина",
      shop: "Магазин",
      login: "Логин",
      password: "Пароль",
      purchases: "Покупки",
      manual_input_barcode: "Ручной ввода штрих-кода",
      barcode: "Штрих-код",
      returns_are_processed: "Возврат оформляется...",
      return_issued: "Возврат оформлен...",
      name_product: "Название товара",
      gauge: "Мера",
      price: "Цена",
      input_weight: "Введите вес",
      quantity: "Количество",
      return_kg: "Вернуть кг",
      product_id: "Идентификатор товара",
      weight_w: "вес/шт",
      refund_amount: "Сумма возврата",
      photo: "Фото",
      email: "Почта",
      periodicity: "Периодичность",
      day: "день",
      week: "неделя",
      month: "месяц",
      current_settings: "Текущие настройки",
      amount_after_return: "Сумма после возврата",
      comment: "Комментарий",
      check_odf: "Чек ОФД",
      code_auth: "код авторизации",
      date: "Дата",
    },
    notify: {
      photo_save: "Фото сохранено, переходите к оформлению возврата",
      admin_created: "Админ создан",
      count_reset: "Счетчики обнулены",
      no_valid_qr: "Не действительный QR код!",
      check_not_found: "Чек не найден!",
      try_again: "Попробуйте ещё раз",
      check_with_order_not_found: "Чек с данным фискальным ордером не найден",
      return_successfully_processed: "Возврат успешно оформлен",
      error_on_camera: "Ошибка! Пожалуйста включите камеру",
      weight_large:
        "Ошибка! Введенный вес превышает вес по чеку! Введите пожалуйста корректный вес",
      input_valid_weight: "Введите пожалуйста корректный вес!",
    },
    modal: {
      confirm: {
        input_data_dont_save:
          "Введенные данные не будут сохранены. Выйти без сохранения?",
        you_want_reset_count: "Вы действительно хотите обнулить счетчики?",
        you_want_return: "Вы действительно хотите оформить возврат?",
      },
      title: {
        exit_without_confirm: "Выход без сохранения изменений",
        reset_count: "Обнулить счетчики возвратов",
        confirm_reset_count: "Подтверждение обнуления счетчика возвратов",
        confirm_return: "Подтверждение оформления возврата",
      },
    },
  },
};
