import { PaginationPayload } from "ra-core";

export function paginateDataList<T>(
  list: T[],
  pagination?: PaginationPayload
): T[] {
  if (!pagination) {
    return list;
  }

  const startPos = (pagination.page - 1) * pagination.perPage;
  const endPos = pagination.page * pagination.perPage;

  return list.slice(startPos, endPos);
}
