import type { Refund as RefundOriginal } from "../../../api";
import type { ResponseRefund as ResponseRefundOriginal } from "../../../api";
import type { UserSettingsDto as UserSettingsDtoOriginal } from "../../../api";

import { Identifier } from "ra-core";

export enum Period {
  NONE = "",
  DAY = "День",
  WEEK = "Неделя",
  MONTH = "Месяц",
}

type WithTranslatedPeriodType<T> = Omit<T, "periodType"> & {
  periodType: Period;
};

type WithRequiredId<T> = Exclude<T, "id"> & { id: Identifier };

export interface Refund extends WithRequiredId<RefundOriginal> {}

export interface ResponseRefund
  extends WithRequiredId<ResponseRefundOriginal> {}

export type UserSettingsDto = WithTranslatedPeriodType<UserSettingsDtoOriginal>;
