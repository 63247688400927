import * as React from "react";
import { FC, ReactElement } from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  useTranslate,
} from "react-admin";
import { nanoid } from "nanoid";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Typography } from "@mui/material";

import { exporter, transformData } from "src/utils/lib";

import { CustomSimpleList, Empty } from "src/components/shared";

import {
  ScanGoField,
  ScanGoAdaptiveField,
  PriceCheckerField,
  PriceCheckerAdaptiveField,
} from "./components";

import {postFilters} from "./constants";

import "./styles/index.scss";

const ShopsPage: FC = (props): ReactElement => {
  const theme = useTheme();
  const translate = useTranslate();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const namesMap = {
    id: translate("custom.filters.number_shop"),
    name: translate("custom.filters.name"),
    city: translate("custom.filters.city"),
  };


  return (
    <div className="shops-page">
      <List
        {...props}
        sx={{ minHeight: "100%" }}
        empty={<Empty />}
        filters={postFilters}
        exporter={(data) => exporter(transformData(data, namesMap))}
      >
        {isSmall ? (
          <CustomSimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => (
              <ul style={{ listStyle: "none" }}>
                <Typography>
                  <li key={nanoid()}>{`Магазин № ${record.id}`}</li>
                  <li key={nanoid()}>{`Город: ${record.city}`}</li>
                </Typography>
              </ul>
            )}
            checkbox={(record) => (
              <>
                <ScanGoAdaptiveField label="SG" record={record} />
                <PriceCheckerAdaptiveField label="PCH" record={record} />
              </>
            )}
            showButton={(record) => (
              <Button
                variant="text"
                href={`/shops/${record.id}/show`}
                size="large"
              >
                {translate("custom.buttons.in_shop")}
              </Button>
            )}
          />
        ) : (
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="root.columns.shop_number" />
            <TextField source="name" label="root.columns.name" />
            <TextField source="city" label="root.columns.city" />
            <ScanGoField source="enabled" label="root.columns.on_sg" />
            <PriceCheckerField
              source="checkerEnabled"
              label="root.columns.off_pch"
            />
            <ShowButton label="root.columns.in_shop" />
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default ShopsPage;
