import { BucketItem, GetBucketItemsListRes } from "./types";

import { services } from "../base/api-instances";

import { CreateResult, GetListParams } from "react-admin";
import { CreateParams } from "ra-core";
import {
  AdminControllerApiCreateRefundRequest,
  RefundItem,
} from "src/api";

import { ResponseRefund } from "../refunds/types";
import { refundSum } from "../../lib/refundSum";

export * from "./types";

export async function getAdminList(
  payload: GetListParams
): Promise<GetBucketItemsListRes> {
  try {
    const { data } = await services
      .makeAdminControllerRequest()
      .getBucketFullInformation1({
        bucketId: payload.filter.bucketId,
      });

    const items = data.itemDtos || [];

    const getRefundItemsIds = (data: RefundItem[]) =>
      data.filter(({ itemId }) => itemId).map(({ itemId }) => itemId);

    const refundItemsIds = data.refunds
      ?.map(({ refundItems }) => refundItems && getRefundItemsIds(refundItems))
      .flat();

    const bucketItems = items.map((item: any): BucketItem => {
      const isRefunded = refundItemsIds?.includes(item.item?.id!);

      return {
        id: item.item?.id!,
        price: item.item?.soldPrice,
        name: item.productName!,
        unit: item?.unit === "PIECE" ? "шт" : "кг",
        quantity: item.item?.weight || 1,
        isRefunded: isRefunded!,
        bucketId: data.bucket?.id!,
        refundSum: refundSum(data)!,
        photo: item.item?.photo,
        sum: item.item?.summ!,
        sumDiscounted: item.item?.summDiscountedWBonus!,
        productId: item.item?.productId,
        checkSum: data.bucket?.amountPaid!,
      };
    });

    return {
      data: bucketItems,
      total: bucketItems.length,
    };
  } catch (e) {
    return { data: [], total: 0 };
  }
}

export async function create(
  params: CreateParams
): Promise<CreateResult<ResponseRefund>> {
  const { bucketId } = params.data.item;
  const requestParams: AdminControllerApiCreateRefundRequest = {
    bucketId,
    itemForRefundDto: [],
    comment: "",
  };

  const { data } = await services
    .makeAdminControllerRequest()
    .createRefund(requestParams);

  return { data: { ...data, id: bucketId } };
}
