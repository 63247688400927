import { useState } from "react";
import { Confirm, useNotify, useTranslate } from "react-admin";
import { Button } from "@mui/material";

import { RACountProps } from "./types";

const ResetAttemptRefundCountButton = ({
  handleSubmit,
  isButtonDisabled,
}: RACountProps) => {
  const notify = useNotify();
  const translate = useTranslate();

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    notify("custom.notify.count_reset");
    handleSubmit();
    setOpen(false);
  };

  return (
    <>
      <Button
        size="medium"
        variant="contained"
        color="secondary"
        sx={{ marginTop: "5px" }}
        onClick={handleClick}
        disabled={isButtonDisabled}
        style={{ marginLeft: "10px" }}
      >
        <>{translate("custom.modal.title.reset_count")}</>
      </Button>
      <Confirm
        isOpen={open}
        title="custom.modal.title.confirm_reset_count"
        content="custom.modal.confirm.you_want_reset_count"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="ra.boolean.true"
        cancel="ra.boolean.false"
      />
    </>
  );
};

export default ResetAttemptRefundCountButton;
