import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslate } from "react-admin";
import { Checkbox } from "@mui/material";

import { BucketItem } from "src/utils/api/bucket-items";

import { IData } from "./types";

const ChooseAllCheckbox = ({
  data,
  setIds,
  setIsAllCheckboxChecked,
  isAllCheckboxChecked,
}: IData) => {
  const translate = useTranslate();

  const onChooseAllIds = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAllCheckboxChecked((prev) => !prev);
    const allIds = data.reduce((acc: number[], item: BucketItem): number[] => {
      return [...acc, item.id];
    }, []);

    if (!isAllCheckboxChecked) {
      setIds(allIds);
    } else {
      setIds([]);
    }
  };

  return (
    <>
      <Checkbox
        id="chooseAll"
        checked={isAllCheckboxChecked}
        onChange={onChooseAllIds}
      />
      <label htmlFor="chooseAll">{translate("root.columns.select_all")}</label>
    </>
  );
};

export default ChooseAllCheckbox;
