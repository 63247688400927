import { services } from "../base/api-instances";
import {
  AdminControllerFilesApiDelFileRequest,
  AdminControllerFilesApiGetFilesRequest,
  AdminControllerFilesApiPostFileRequest,
} from "../../../api";

export async function setProductPhoto(
  params: AdminControllerFilesApiPostFileRequest
) {
  const { data } = await services
    .makeAdminControllerRequestFiles()
    .postFile(params);

  return data;
}

export async function getProductPhoto(
  params: AdminControllerFilesApiGetFilesRequest
) {
  const { data } = await services
    .makeAdminControllerRequestFiles()
    .getFiles(params);

  return data;
}

export async function deleteProductPhoto(
  params: AdminControllerFilesApiDelFileRequest
) {
  const { data } = await services
    .makeAdminControllerRequestFiles()
    .delFile(params);

  return data;
}
