import { Dispatch, SetStateAction } from "react";

import { services } from "src/utils/api/base/api-instances";

import { RefundItemsDtoTranslated } from "../types";

export async function getItemsByRefund(
  record: Record<string | number, any>,
  func: Dispatch<SetStateAction<RefundItemsDtoTranslated[]>>,
  role: boolean
) {
  role
    ? services
        .makeRootControllerRequest()
        .getRefundItemsByRefund({
          refundId: record.refundId,
        })
        .then(({ data }) => {
          const adaptedData = data.map((item) => {
            return {
              weight: item.refundItem?.weight || 1,
              refundedAmountDiscounted:
                item.refundItem?.refundedAmountDiscounted,
              photoLink: item.refundItem?.photoLink,
              unit:
                item.unit === "PIECE" ? "root.columns.pcs" : "root.columns.kg",
              productId: item.productId,
              productName: item.productName,
            };
          });

          func(adaptedData);
        })
    : services
        .makeAdminControllerRequest()
        .getRefundItemsByRefund1({
          refundId: record.refundId,
        })
        .then(({ data }) => {
          const adaptedData = data.map((item) => {
            return {
              weight: item.refundItem?.weight || 1,
              refundedAmountDiscounted:
                item.refundItem?.refundedAmountDiscounted,
              photoLink: item.refundItem?.photoLink,
              unit:
                item.unit === "PIECE" ? "root.columns.pcs" : "root.columns.kg",
              productId: item.productId,
              productName: item.productName,
            };
          });

          func(adaptedData);
        });
}
