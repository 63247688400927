import {
  useGetOne,
  useRecordContext,
  useTranslate,
  useUpdate,
} from "react-admin";
import { ReactElement, useEffect, useState } from "react";
import { Checkbox, Button } from "@mui/material";

import { Shop } from "src/utils/api/shops/types";

import { FieldData, Params } from "./types";

const PriceCheckerField = (props: FieldData): ReactElement | null => {
  const { id: shopId } = useRecordContext(props);
  const translate = useTranslate();
  const [update] = useUpdate();

  const res = useGetOne("shops", { id: shopId });
  const {
    id,
    address,
    name,
    enabled,
    merchant,
    cashBoxNumber,
    checkerEnabled,
  } = res.data as Shop;

  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [check, setCheck] = useState(checkerEnabled);

  const data: Params = {
    shopId: Number(id),
    address,
    name: name!,
    enabled,
    merchant,
    cashBoxNumber,
    checkerEnabled: check,
  };

  const onCheckerHandler = () => {
    id &&
      update("shops", {
        id,
        data,
        previousData: {},
      }).then(() => {
        setIsButtonDisable(true);
      });
  };

  useEffect(() => {
    setCheck(checkerEnabled);
  }, [checkerEnabled]);

  if (id) {
    return (
      <span>
        <Checkbox
          onChange={(e) => {
            setCheck(e.target.checked);
            setIsButtonDisable(!isButtonDisable);
          }}
          checked={check}
        />
        &nbsp;
        <Button
          onClick={onCheckerHandler}
          disabled={isButtonDisable}
          variant="text"
          size="large"
        >
          {translate("ra.action.save")}
        </Button>
      </span>
    );
  }

  return null;
};

export default PriceCheckerField;
