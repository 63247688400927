import * as Shops from "./shops";
import * as Purchases from "./purchases";
import * as Refunds from "./refunds";
import * as BucketItems from "./bucket-items";

export enum ResourceName {
  SHOPS = "shops",
  PURCHASES = "purchases",
  REFUNDS = "refunds",
  BUCKET_ITEMS = "bucket-items",
}

export const Api = {
  Shops,
  Purchases,
  Refunds,
  BucketItems,
};
