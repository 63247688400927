import * as React from "react";
import { Identifier } from "ra-core";
import {
  FC,
  ReactElement,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

import {
  Datagrid,
  List,
  TextField,
  NumberField,
  WithRecord,
  useTranslate,
} from "react-admin";
import { nanoid } from "nanoid";

import { Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { RefundWithInvoiceDto } from "src/api";
import { RoleContext } from "src/providers";
import { exporter, transformData } from "src/utils/lib";
import { services } from "src/utils/api/base/api-instances";
import { UserSettingsDto } from "src/utils/api/refunds/types";

import {
  CardField,
  CustomSimpleList,
  CustomUrlField,
  Empty,
} from "src/components/shared";

import {
  ResetAttemptRefundCountButton,
  ChooseRefundCheckbox,
  ReportSettingsForm,
  RefundPanel,
  RefundAdaptivePanel,
  FinalAmountPaidField,
  CustomUrlFieldAdaptive,
} from "./components";

import { useGetFormData } from "./lib";

import { postFilters, namesMap } from "./constants";

const ReturnsPage: FC = (): ReactElement => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { isRoot } = useContext(RoleContext);
  const [postDefaultValue, setPostDefaultValue] = useGetFormData();
  const translate = useTranslate();

  const [bucketIds, setBucketIds] = useState<(Identifier | undefined)[]>([]);
  const isButtonDisabled = bucketIds.length === 0;

  const handleSubmit = () => {
    if (isRoot) {
      const responses = bucketIds.map(async (bucketId) => {
        await services
          .makeRootControllerRequest()
          .resetAttemptForBucketWithRefunds({
            bucketId: Number(bucketId),
          });
      });

      Promise.all(responses);
    } else {
      const responses = bucketIds.map(async (bucketId) => {
        await services
          .makeAdminControllerRequest()
          .resetAttemptForBucketWithRefunds1({
            bucketId: Number(bucketId),
          });
      });

      Promise.all(responses);
    }
  };

  const postRowStyle = (record: RefundWithInvoiceDto) => {
    return {
      backgroundColor: record.isExceedingAttempt ? "pink" : "white",
    };
  };

  const element = (data: string) => (
    <iframe style={{minWidth: 400, minHeight: "100%"}} src={data} width="100vmin" height="100vmin" title="ofd" />
  );

  return (
    <div>
      <List
        sx={{ marginTop: "20px" }}
        sort={{ field: "refundDate", order: "DESC" }}
        filters={postFilters}
        exporter={(data) => exporter(transformData(data, namesMap))}
        empty={<Empty />}
      >
        {isSmall ? (
          <CustomSimpleList
            primaryText={(record) => record.shopAddress}
            secondaryText={(record) => (
              <ul style={{ listStyle: "none" }}>
                <Typography>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.return_receipt_number"
                  )}: ${record.fiscalRefund || ""}`}</li>
                  <li>{`${translate("root.columns.bank_transaction")}: ${
                    record.referenceNumber || ""
                  }`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.card_number"
                  )}: ${
                    (record.cardNumber && `**${record.cardNumber.slice(-4)}`) ||
                    ""
                  }`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.card_friend"
                  )}: ${
                    (record.friendCardId && record.friendCardId) || ""
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.merchan")}: ${
                    record.merchant || ""
                  }`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.sales_receipt"
                  )}: ${record.fiscalOrder || ""}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "root.columns.sum_receipt"
                  )}: ${record.amountPaid}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "custom.labels.refund_amount"
                  )}: ${record.amountRefunded}`}</li>
                  <li key={nanoid()}>{`${translate(
                    "custom.labels.amount_after_return"
                  )}: ${
                    record.status === "WAIT"
                      ? translate("root.columns.return_issued")
                      : record.finalAmountPaid
                  }`}</li>
                  <li key={nanoid()}>{`${translate("custom.labels.comment")}: ${
                    record.comment
                  }`}</li>
                  <li key={nanoid()}>{`${translate("root.columns.status")}: ${
                    record.status
                  }`}</li>
                  <li key={nanoid()}>
                    {translate("root.columns.check_odf")}:{" "}
                    {(
                      <CustomUrlFieldAdaptive
                        source={record?.fiscalRefundUrl}
                        element={element}
                      />
                    ) || ""}
                  </li>
                </Typography>
              </ul>
            )}
            tertiaryText={(record) => record.refundDate}
            checkbox={(record) => (
              <ChooseRefundCheckbox
                id={record.bucketId}
                setIds={setBucketIds}
                ids={bucketIds}
              />
            )}
            expand={(record) => <RefundAdaptivePanel record={record} />}
          />
        ) : (
          <Datagrid
            rowClick="edit"
            bulkActionButtons={false}
            expand={RefundPanel}
            rowStyle={postRowStyle}
          >
            <TextField
              source="fiscalRefund"
              label="root.columns.return_receipt_number"
            />
            <TextField
              source="referenceNumber"
              label="Банковская транзакция №"
            />
            <CardField source="cardNumber" label="root.columns.card_number" />
            <NumberField
              source="friendCardId"
              label="root.columns.card_friend"
            />
            <TextField source="refundDate" label="root.columns.date_and_time" />
            <TextField source="merchant" label="root.columns.merchant" />
            <TextField source="shopAddress" label="root.columns.address_shop" />
            <TextField
              source="fiscalOrder"
              label="root.columns.sales_receipt"
            />
            <TextField source="amountPaid" label="root.columns.sum_receipt" />
            <TextField
              source="amountRefunded"
              label="custom.labels.refund_amount"
            />
            <FinalAmountPaidField label="custom.labels.amount_after_return" />
            <TextField source="comment" label="custom.labels.comment" />
            <TextField source="status" label="root.columns.status" />
            <CustomUrlField
              label="чек ОФД"
              source="fiscalRefundUrl"
              element={element}
              height={600}
            />
            <WithRecord
              label="Выбор возврата"
              render={(record) => (
                <ChooseRefundCheckbox
                  setIds={setBucketIds}
                  ids={bucketIds}
                  id={record.bucketId}
                />
              )}
            />
          </Datagrid>
        )}
      </List>
      <Typography sx={{ marginLeft: "10px" }}>Блок настроек отчета</Typography>
      <ResetAttemptRefundCountButton
        handleSubmit={handleSubmit}
        isButtonDisabled={isButtonDisabled}
      />
      <ReportSettingsForm
        postDefaultValue={postDefaultValue as UserSettingsDto}
        setPostDefaultValue={
          setPostDefaultValue as Dispatch<SetStateAction<UserSettingsDto>>
        }
      />
    </div>
  );
};
export default ReturnsPage;
