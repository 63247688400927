import React from "react";

import { createRoot } from "react-dom/client";

import App from "src/App";

import RoleProvider from "./providers/RoleProvider";

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <RoleProvider>
      <App />
    </RoleProvider>
  </React.StrictMode>
);
