import { useRecordContext } from "react-admin";
import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";

const CustomUrlField = ({
  label,
  source,
  element,
  height,
}: {
  label: string;
  source: string;
  element: (data: string) => JSX.Element;
  height?: string | number
}) => {
  const record = useRecordContext();

  const [isFrameOn, setIsFrameOn] = useState(false);

  const frameOpen = () => {
    setIsFrameOn(true);
  };

  const frameClose = () => setIsFrameOn(false);

  return (
    <>
      <Typography>
        <span
          onClick={() => {
            frameOpen();
          }}
          style={{ color: "#43a6f5" }}
        >
          {record[source] && "Смотреть"}
        </span>
      </Typography>
      <Modal
        open={isFrameOn}
        onClose={frameClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Box height={height}>{element(record[source])}</Box>
      </Modal>
    </>
  );
};

export default CustomUrlField;
